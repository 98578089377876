import React, {useEffect} from 'react';
import LoanHintBox from "pages/activeLoan/components/LoanHintBox";
import LoanAction from "stores/loan/LoanAction";
import {useTypedSelector} from "helpers/reducers";
import Loader from "components/loader/Loader";
import {useDispatch} from "useDispatch";
import {Col} from "react-bootstrap";
import SessionAction from "stores/user/SessionAction";
import ActiveLoanLogicWrap from "pages/activeLoan/components/ActiveLoanLogicWrap";
import ActiveLoanDisplay from "pages/activeLoan/components/ActiveLoanDisplay";
import {LoanStatus} from "types/enums/LoanStatus";
import {toastInfo} from "helpers/toasts";
import {useFetchActiveLoanInterval} from "helpers/hooks/useFetchActiveLoanInterval";
import {useFetchDataInterval} from "helpers/hooks/useFetchDataInterval";
import {useLocation} from "react-router-dom";
import {throwAlertOnPaymentStatus} from "helpers/utility";

interface ActiveLoanProps {
    hasLimitedOffer?: boolean;
    hasActiveLoan?: boolean;
}

const ActiveLoan = (props: ActiveLoanProps) => {
    const loan = useTypedSelector(state => state.loan);
    const user = useTypedSelector(state => state.session.user);
    const dispatch = useDispatch();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search).get("payment_status");

    useFetchActiveLoanInterval(loan, 3 * 1000);
    useFetchDataInterval(() => {
        if (user && !user?.papers_uploaded) {
            toastInfo("Prosím nahrajte 2 doklady v sekci Osobní dokumenty -> Doklady nebo kliknete na ikonku identity!");
        }
    }, 20 * 1000);

    useEffect(() => {
        dispatch(SessionAction.getUser());
        dispatch(SessionAction.fetchCustomerPapers());
        dispatch(LoanAction.fetchActiveLoan());
        dispatch(LoanAction.fetchAllContractsToSign());

        if (loan.activeLoan && loan.activeLoan.status < LoanStatus.SentToBankAccount && loan.contractsToSign.length === 0) {
            dispatch(LoanAction.fetchAllContractsToSign());
        }
    }, []);

    useEffect(() => {
        queryParams && throwAlertOnPaymentStatus(queryParams);
    }, [queryParams])


    if (!loan.bonuses || !loan.contracts || !loan.documents) {
        return <Loader/>
    }

    return (
       <ActiveLoanLogicWrap>
            <Col lg={8} xl={6} className="d-block loan__wrap-mobile">
                <ActiveLoanDisplay/>
            </Col>
            <Col lg={6}>
                <LoanHintBox/>
            </Col>
       </ActiveLoanLogicWrap>
    );
};

export default ActiveLoan;