import React from 'react';

interface TableProps {
    children: React.ReactNode | React.ReactNode[]
}

const Table = (props: TableProps) => {
    return (
        <table className="Table table responsiveTable">
            {props.children}
        </table>
    );
};

export default Table;