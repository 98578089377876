import ajax from "helpers/ajax";
import {createAction} from "helpers/createAction";
import {LoginData} from "types/entities/LoginData";
import {MeResponse} from "types/Responses";
import {toast} from "react-toastify";
import {clearCache, clearLocalStorage} from "helpers/store";
import {CustomerPaper} from "types/entities/CustomerPapers";
import {processErrorResponse, processStatusError, processToastErrorFromResponse} from "helpers/utility";
import LoanAction from "stores/loan/LoanAction";
import {closeModal} from "stores/action";
import {toastError, toastSuccess} from "helpers/toasts";
import LogicAction from "stores/logic/LogicAction";

class SessionAction {
    static readonly SET_USER_AUTH = "SessionAction.SetUserAuth";
    static readonly SET_USER = "SessionAction.SetUser";
    static readonly SET_CLEAR_SESSION = "SessionActon.SetClearAction";
    static readonly SET_CUSTOMER_PAPERS = "SessionAction.SetCustomerPapers";
    static readonly SET_CUSTOMER_PAPERS_ROW = "SessionAction.SetCustomerPapersRow";
    static readonly RESET_SESSION_STATE = "SessionAction.ResetSessionState";

    static resetSessionState = () => {
        return createAction(SessionAction.RESET_SESSION_STATE);
    }

    static authLogin = (auth: LoginData) => {
        return dispatch => {
            return ajax.post("/api/auth/login/", auth).then(r => {
                dispatch(SessionAction.setAuth(r.data.token));
                return r;
            }).catch(err => {
                dispatch(SessionAction.verifyUserEmail(auth.email, err));
                // const attribute = Object.keys(err.response?.data)[0].toString();
                // console.log(err.response.data[attribute])
                // toastError(err.response.data[attribute][0]);
                processToastErrorFromResponse(err);
                return err;
            });
        }
    };

    static verifyUserEmail = (email: string, error: any) => {
        return dispatch => {
            return ajax.get('/api/user-exists/?email=' + email).then(r => {
                if (r.data.exists) {
                    return r;
                } else {
                    toastError("Nemáte vytvořený klientský účet");
                }
                return r;
            }).catch(err => {
            })
        }
    }

    static authLogout = () => {
        return dispatch => {
            return ajax.post("/api/auth/logout/").then(r => {
                dispatch(SessionAction.resetSessionState());
                dispatch(LoanAction.resetLoanState());
                dispatch(LogicAction.resetLogicState());
                return r;
            }).catch(err => {
                dispatch(SessionAction.resetSessionState());
                dispatch(LoanAction.resetLoanState());
                clearLocalStorage();
                clearCache();
                // @ts-ignore
                window.location.reload(true);
            });
        }
    }

    static authResetPassword = (email: string) => {
        return dispatch => {
            return ajax.post('/api/auth/password-reset/', email);
        }
    }

    static getUser = () => {
        return dispatch => {
            return ajax.get("/api/customers/me/").then(r => {
                dispatch(SessionAction.setUser(r.data));
                return r;
            }).catch(err => {
                processStatusError(err.response.status);
            })
        }
    };

    static updatePersonalData = (data) => {
        return dispatch => {
            return ajax.patch("/api/customers/me", data).then(r => {
                dispatch(SessionAction.setUser(r.data))
                toastSuccess(r.status);
                return r;
            }).catch(err => {
                toastError(err.message)
            });
        }
    };

    static fetchCustomerPapers = () => {
        return dispatch => {
            return ajax.get("/api/customer-papers/").then(r => {
                dispatch(SessionAction.setCustomerPapers(r.data));
                return r.data;
            }).catch(err => {
                processStatusError(err.response.status);
            })
        }
    }

    static uploadCustomerPapers = (formData, type) => {
        return dispatch => {
            return ajax.post("/api/customer-papers/", formData).then(r => {
                console.log(r);
                dispatch(SessionAction.setCustomerPapersRow(r.data));
                toastSuccess("Soubor byl úspěšně nahrán!");
                return r;
            }).catch(err => {
                processErrorResponse(err);
                console.log(err);
            })
        }
    }

    static setCustomerPapers = (customerPapers: CustomerPaper[]) => {
        return createAction(SessionAction.SET_CUSTOMER_PAPERS, customerPapers);
    }

    static setCustomerPapersRow = (customerPaper: CustomerPaper) => {
        return createAction(SessionAction.SET_CUSTOMER_PAPERS_ROW, customerPaper);
    }

    static setAuth = (auth: string) => {
        return createAction(SessionAction.SET_USER_AUTH, auth)
    };

    static setUser = (user: MeResponse) => {
        return createAction(SessionAction.SET_USER, user);
    }

    static clearSession = () => {
        return createAction(SessionAction.SET_CLEAR_SESSION);
    }
}

export default SessionAction;