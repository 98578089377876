import React, {useEffect} from 'react';
import SessionAction from "stores/user/SessionAction";
import {useDispatch} from "useDispatch";
import {RouteComponentProps, withRouter} from "react-router";
import {clearCache, clearLocalStorage} from "helpers/store";
import Loader from "components/loader/Loader";
import {toastSuccess} from "helpers/toasts";

interface Params {}

interface Props extends RouteComponentProps<Params>{}

const Logout = (props: Props) => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(SessionAction.authLogout()).then(r => {
            clearLocalStorage();
            clearCache();
        });
        toastSuccess("Úspěšně odhlášen!");
    }, []);

    return <Loader/>;
};

export default withRouter(Logout);