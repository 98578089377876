import {createAction} from "helpers/createAction";
import ajax from "helpers/ajax";
import {Bonuses, Contracts} from "types/Responses";
import {GetActiveLoanResponse} from "types/Responses/getActiveLoanResponse";
import {ContractsType} from "types/enums/ContractsType";
import {Loan} from "types/entities/Loan";
import {ThunkAction} from "model/store/thunk";
import {PaymentToPay} from "types/Responses/PaymentsToPayResponse";
import {LoanSelection} from "types/entities/LoanSelection";
import {PriceListItem} from "types/entities/PriceListItem";
import {LoanPayment} from "types/entities/LoanPayment";
import {toast} from "react-toastify";
import {processErrorResponse, processStatusError} from "helpers/utility";
import {DeferralOrder} from "types/entities/DeferralOrder";
import {toastError, toastSuccess} from "helpers/toasts";
import {ROOT_URL} from "helpers/config";
import {ContractSignObject} from "components/contract/SignContract";

class LoanAction {
    static readonly SET_LOAN_PRICE_LIST = "LoanAction.SetLoanPriceList";
    static readonly SET_USER_BONUSES = "LoanAction.SetUserBonuses";
    static readonly SET_USER_CONTRACTS = "LoanAction.SetUserContracts";
    static readonly SET_USER_DEFERRALS = "LoanAction.SetUserDeferrals";
    static readonly SET_SYSTEM_DOCUMENTS = "LoanAction.SetSystemDocuments";
    static readonly SET_SYSTEM_DOCUMENT_DETAIL = "LoanAction.SetSystemDocumentDetail";
    static readonly SET_USER_LOANS = "LoanAction.SetUserLoans";
    static readonly SET_USER_LOAN = "LoanAction.SetUserLoan";
    static readonly SET_USER_ACTIVE_LOAN = "LoanAction.SetUserActiveLoan";
    static readonly SET_USER_LOAN_PAYMENTS = "LoanAction.SetUserLoanPayments";
    static readonly SET_LOAN_PAYMENTS_TO_PAY = "LoanAction.SetLoanPaymentsToPay";
    static readonly SET_LOAN_SELECTION = "LoanAction.SetLoanSelection";
    static readonly SET_DEFERRED_ORDERS_LIST = "LoanAction.SetDeferredOrdersList";
    static readonly SET_PENDING_DEFERRED_ORDER = "LoanAction.SetPendingDeferredOrder";
    static readonly SET_CONTRACTS_TO_SIGN = "LoanAction.SetContractsToSign";
    static readonly SET_SIGN_CONTRACT_FLAG = "LoanAction.SetSignContract";

    static readonly RESET_LOAN_STATE = "SessionAction.ResetLoanState";

    static resetLoanState = () => {
        return createAction(LoanAction.RESET_LOAN_STATE);
    }


    // /api/orders
    static createDeferralOrder = (days: number) => {
        return dispatch => {
            return ajax.post('/api/orders/deferral/', {days}).then(r => {
                // dispatch(LoanAction.setPendingDeferredOrder(r.data));
                return r.data;
            }).catch(err => {
                processErrorResponse(err);
            });
        }
    }

    static cancelDeferralOrder = (id: number) => {
        return dispatch => {
            return ajax.post('/api/orders/deferral/' + id + '/cancel/').then(r => {
                dispatch(LoanAction.setPendingDeferredOrder(null))
                return r;
            });
        }
    }

    static fetchDeferralOrder = (loan_id: number) => {
        return dispatch => {
            return ajax.get('/api/orders/deferral/?loan_id=' + loan_id).then((r: {data: DeferralOrder}) => {
                dispatch(LoanAction.setPendingDeferredOrder(r.data))
                return r.data;
            }).catch(err => {
                dispatch(LoanAction.setPendingDeferredOrder(null));
                return null;
            });
        }
    }

    static payOrder = (data) => {
        console.log(ROOT_URL)
        return dispatch => {
            return ajax.post("/api/orders/payment/", {
                ...data,
                next_url: ROOT_URL
            }).then(r => {
                toast.success("Tak to se povedlo !")
                return r.data;
            }).catch(err => {
                processErrorResponse(err);
            })
        }
    }

    static fetchLoanToPayList = (id: number) => {
        return dispatch => {
            return ajax.get('/api/orders/to-pay/?loan_id=' + id).then(r => {
                dispatch(LoanAction.setLoanPaymentsToPay(r.data.results));
                return r;
            }).catch(err => {
                processErrorResponse(err);
            })
        }
    }

    // /api/loans
    static fetchActiveLoan = (): ThunkAction<Promise<any>, any> => {
        return dispatch => {
            return ajax.get("/api/loans/active/").then(r => {
                    dispatch(LoanAction.setUserActiveLoan(r.data));
                return r.data;
            }).catch(err => {
                dispatch(LoanAction.setUserActiveLoan(null));
                dispatch(LoanAction.setContractsToSign(null))

                // processErrorResponse(err);
            });
        }
    }

    static createLoan = (type: number, period: number | number[], amount: number | number[]) => {
        return dispatch => {
            return ajax.post('/api/loans/', {type, period, amount}).then(r => {
                dispatch(LoanAction.setUserActiveLoan(r.data))
                // toast.success("Žádost o úvěr byla úspešně podána.");
                return {redirect: true, data: r.data, err: null};
            }).catch(err => {
                processErrorResponse(err);
                return {redirect: false, data: null, error: err};
            });
        }
    }

    static fetchLoanList = () => {
        return dispatch => {
            return ajax.get("/api/loans/").then(r => {
                dispatch(LoanAction.setUserLoans(r.data.results));
                return r;
            }).catch(err => {
                processErrorResponse(err);
            });
        }
    }

    static fetchLoanDetail = (id: number) => {
        return dispatch => {
            return ajax.get("/api/loans/" + id + '/').then(r => {
                dispatch(LoanAction.setUserLoan(r.data));
                dispatch(LoanAction.fetchLoanPayments(id));
                dispatch(LoanAction.fetchLoanToPayList(id));
                return r.data;
            }).catch(err => {
                console.log(err)
                processErrorResponse(err, true);
            })
        }
    }

    // /api/price-list
    static fetchPriceList = (type: "fix" | "rent") => {
        return dispatch => {
            return ajax.get("/api/price-list/" + type).then(r => {
                console.log(r)
                dispatch(LoanAction.setLoanPriceList(type, r.data))
                return r.data
            }).catch(err => {
                processErrorResponse(err);
            });
        }
    }

    // /api/payments
    static activateRentService = () => {
        return dispatch => {
            return ajax.post('/api/payments/rent-activate/').then(r => {
                toastSuccess(r.status);
                return r.data;
            }).catch(err => {
                processErrorResponse(err);
            });
        }
    }

    static fetchLoanPayments = (loanId: number): ThunkAction<Promise<any>, any> => {
        return dispatch => {
            return ajax.get('/api/payments/?loan_id=' + loanId).then(r => {
                dispatch(LoanAction.setUserLoanPayments(r.data.results));
                return r;
            })
        }
    }

    // /api/contracts
    static fetchAllContractsToSign = () => {
        return dispatch => {
            return ajax.get("/api/contracts/?to_sign=1").then(r => {
                dispatch(LoanAction.setContractsToSign(r.data.results));
                console.log(r);
                return r.data;
            }).catch(err => {

            })
        }
    }

    static fetchContractsListByType = (type: ContractsType) => {
        return dispatch => {
            return ajax.get("/api/contracts/?contract_type=" + type).then(r => {
                switch (type) {
                    case ContractsType.Contracts:
                        dispatch(LoanAction.setUserContracts(r.data.results));
                        break;
                    case ContractsType.Deferral:
                        dispatch(LoanAction.setUserDeferrals(r.data.results));
                        break;
                    case ContractsType.Personal:
                        dispatch(LoanAction.setDocumentsList(r.data.results));
                        break;
                    default:
                        dispatch(LoanAction.setUserContracts(r.data.results));
                        break;
                }
                return r;
            }).catch(err => {
                processErrorResponse(err);
            });
        }
    }

    // API GET REQUESTS
    static fetchAll = (priceList?: boolean) => {
        return dispatch => {
            dispatch(LoanAction.fetchUserBonuses());
            dispatch(LoanAction.fetchLoanList());
            dispatch(LoanAction.fetchContractsListByType(ContractsType.Personal));
            dispatch(LoanAction.fetchContractsListByType(ContractsType.Contracts));
            dispatch(LoanAction.fetchContractsListByType(ContractsType.Deferral));
        }
    }

    static fetchDocumentDetail = (id: number) => {
        return dispatch => {
            return ajax.get('/api/documents/' + id).then(r => {
                return r;
            }).catch(err => {
                processErrorResponse(err);
            });
        }
    }

    static fetchUserBonuses = () =>{
        return dispatch => {
            return ajax.get("/api/points/").then(r => {
                dispatch(LoanAction.setUserBonuses(r.data.results));
                return r;
            }).catch(err => {
                processErrorResponse(err);
            });
        }
    }

    static sendSignSms = (type: number) => {
        console.log(type)
        return dispatch => {
            return ajax.post("/api/send-sign-sms/", {type: type}).then(r => {
                console.log(r);
            }).catch(err => {
                console.log(err)
            })
        }
    }

    static signContract = (data: {contract_type: number, code: string}) => {
        return dispatch => {
            return ajax.post("/api/sign-contract/", data).then(r => {
                console.log(r);
            }).catch(err => {
                console.log(err)
            });
        }
    }




    // REDUX SETTING METHODS

    /**
     * @function - store fetched user bonuses into redux store
     * @param bonuses - all bonuses are placed in results: [bonuses]
     */
    static setUserBonuses = (bonuses: Bonuses) => {
        return createAction(LoanAction.SET_USER_BONUSES, bonuses);
    }

    /**
     * @function - store fetched user contracts into redux store
     * @param contracts - all contracts are placed in results: [contracts]
     */
    static setUserContracts = (contracts: Contracts) => {
        return createAction(LoanAction.SET_USER_CONTRACTS, contracts)
    }

    /**
     * @function - store fetched user loans into redux store
     * @param loans - all loans are placed in results: [loans]
     */
    static setUserLoans = (loans: Loan[]) => {
        return createAction(LoanAction.SET_USER_LOANS, loans)
    }

    /**
     * @function - update loan in user loan list (update loan in loan history list)
     *
     * @param loan - loan history result entity
     */
    static setUserLoan = (loan: Loan) => {
        return createAction(LoanAction.SET_USER_LOAN, loan);
    }

    /**
     * @function - store list of documents into redux store
     *
     * @param documents - documents result entity
     */
    static setDocumentsList = (documents: Document) => {
        return createAction(LoanAction.SET_SYSTEM_DOCUMENTS, documents);
    }

    /**
     * @function setUserActiveLoan - set user active loan into redux store from fetch
     *
     * @param activeLoan - active loan entity
     */
    static setUserActiveLoan = (activeLoan: GetActiveLoanResponse | null) => {
        return createAction(LoanAction.SET_USER_ACTIVE_LOAN, activeLoan);
    }

    static setDeferredOrdersList = (deferredOrdersList) => {
        return createAction(LoanAction.SET_DEFERRED_ORDERS_LIST, deferredOrdersList);
    }


    // @todo add types
    static setUserDeferrals = (deferrals) => {
        return createAction(LoanAction.SET_USER_DEFERRALS, deferrals)
    }

    // @todo add types
    static setUserLoanPayments = (payments: LoanPayment[]) => {
        return createAction(LoanAction.SET_USER_LOAN_PAYMENTS, payments);
    }

    //@todo add types
    static setLoanPaymentsToPay = (payments: PaymentToPay[]) => {
        return createAction(LoanAction.SET_LOAN_PAYMENTS_TO_PAY, payments);
    }

    //@todo add types
    static setLoanPriceList = (type, priceList: PriceListItem[]) => {
        return createAction(LoanAction.SET_LOAN_PRICE_LIST, {type, priceList})
    }


    static setLoanSelection = (selection: LoanSelection) => {
        return createAction(LoanAction.SET_LOAN_SELECTION, selection)
    }

    static setPendingDeferredOrder = (order: DeferralOrder | null) => {
        return createAction(LoanAction.SET_PENDING_DEFERRED_ORDER, order);
    }

    static setContractsToSign = (contracts: ContractSignObject | null) => {
        return createAction(LoanAction.SET_CONTRACTS_TO_SIGN, contracts);
    }

    static setSignContractFlag = (contract) => {
        return createAction(LoanAction.SET_SIGN_CONTRACT_FLAG, contract)
    }
}

export default LoanAction;