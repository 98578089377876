import React, {useEffect, useState} from 'react';
import SignContractStep from "components/contract/components/SignContractStep";
import PurpleButton from "components/buttons/PurpleButton";
import RedButton from "components/buttons/RedButton";
import ContractPDF from "components/contract/components/ContractPDF";
import {useDispatch} from "useDispatch";
import LoanAction from "stores/loan/LoanAction";
import {Col, Row} from "react-bootstrap";
import SignForm from "components/contract/components/SignForm";
import {closeModal} from "stores/action";
import {RouteComponentProps, withRouter} from "react-router";
import {useTypedSelector} from "helpers/reducers";
import clsx from "clsx";
import LogicAction from "stores/logic/LogicAction";
import ContractText from "components/contract/components/ContractText";

interface Params {}

interface Props extends RouteComponentProps<Params>{
    contracts: ContractSignObject[]
}

export interface ContractSignObject {
    id: number,
    number: number | null,
    type: number,
    created: string,
    signed: boolean | null,
    url: string,
    type_text: string,
}

const SignContract = (props: Props) => {
    const dispatch = useDispatch();
    const contractsToSign = useTypedSelector(state => state.loan.contractsToSign);
    const [step, setStep] = useState(contractsToSign?.findIndex(contract => !contract?.signed) || 0);
    const [isReadyForSign, setIsReadyForSign] = useState(false);
    const isAllSigned = contractsToSign?.filter(contract => contract?.signed !== true) || [];

    useEffect(() => {
        if (contractsToSign[step] && contractsToSign[step].type === 1) {
            setIsReadyForSign(false);
        }
    }, [step])

    const signContract = (data) => {
        if (step !== 10) {
            dispatch(LoanAction.signContract(data));
            dispatch(LoanAction.setSignContractFlag(contractsToSign[step]));
        }

        if (step + 1 <= contractsToSign?.length) {
            setStep(step + 1)
            return;
        }

        setStep(10);
    }

    const contractActionHandler = () => {
        if (!isReadyForSign) {
            setIsReadyForSign(true);
            dispatch(LoanAction.sendSignSms(contractsToSign[step].type));
            return;
        }

    }

    const finishSignContracts = () => {
        dispatch(LogicAction.setSignLaterFlag(false));
        dispatch(closeModal());
    }

    const declineHandler = () => {
        dispatch(closeModal());
        props.history.push("/odhlaseni");
    }

    return (
        <div className="SignContract  mt-4">
            <Row className="justify-content-around contractSteps">
                {contractsToSign?.map((contract, index) => (
                    <Col key={contract?.id} xs={4}>
                        <SignContractStep number={index + 1} text={contract?.type_text} contract={contract} contracts={contractsToSign} step={step} />
                    </Col>
                ))}
            </Row>

                <div className={clsx(isAllSigned.length !== 0 ? "ModalContractDetail--body" : "my-4")}>
                       <p className={clsx("contract-paragraph mt-4", isAllSigned.length === 0 && "contract-body-wrap-allSigned")}>
                           <ContractText type={contractsToSign[step]?.type}/>
                       </p>
                       <div className={clsx(isAllSigned.length === 0 && "d-none")}>
                           {isAllSigned.length !== 0 && contractsToSign[step] && <ContractPDF contract={contractsToSign[step]}/>}
                       </div>
                </div>

            <div className={clsx("d-flex mt-4", isAllSigned.length === 0 ?  "justify-content-end" : "justify-content-between")}>
                {isAllSigned.length === 0
                    ? <PurpleButton action={finishSignContracts}>Pokračovat</PurpleButton>
                    : (
                        <>
                            <RedButton action={declineHandler}>Nesouhlasím</RedButton>
                            {isReadyForSign
                                ? <SignForm contract={contractsToSign[step]} signContract={signContract}/>
                                : <PurpleButton action={contractActionHandler}>Souhlasím - vygenerovat kód</PurpleButton>
                            }
                        </>
                    )
                }
            </div>
        </div>
    );
};

export default withRouter(SignContract);