import {useEffect, useState} from "react";
import {Hint} from "intro.js-react";
import {IntroJSStep} from "layouts/components/Preset";
import {useHistory} from "react-router-dom";

export const useReactIntroJS = (steps: IntroJSStep[], hints: Hint[]) => {
    const history = useHistory();
    const [tour, setTour] = useState({
        stepsEnabled: false,
        initialStep: 0,
        steps: steps,
        hintsEnabled: true,
        hints: hints
    });

    const onExit = (): void => {
        setTour({
            ...tour,
            stepsEnabled: false
        });
    };

    const toggleSteps = (): void => {
        history.push("/aktivni-uver");

        setTimeout(() => {
            setTour({
                ...tour,
                stepsEnabled: !tour.stepsEnabled
            })
        }, 300);
        clearTimeout();
    };

    const addStep = (step: IntroJSStep): void => {
        setTour({
            ...tour,
            steps: [...tour.steps, step]
        })
    };

    const toggleHints = (): void => {
        setTour({
            ...tour,
            hintsEnabled: !tour.hintsEnabled
        })
    };

    const addHint = (hint: Hint): void => {
        setTour({
            ...tour,
            hints: [...tour.hints, hint]
        })
    };

    useEffect(() => {}, []);

    return {
        tour,
        onExit,
        addStep,
        addHint,
        toggleSteps,
        toggleHints
    }
};
