import React from 'react';
import MobileTableItemContracts from "pages/documents/components/MobileTableItemContracts";
import {Contract} from "types/entities";

interface Props {
    contracts: Contract[]
}

const MobileTableContracts = (props: Props) => {
    return (
        <div className="LoanHistoryTableMobile scrollBar-hidden">

            <div className={"mobile-table"}>
                {props.contracts?.map(contract => (
                    <MobileTableItemContracts contract={contract}/>
                ))}
            </div>
        </div>
    );
};

export default MobileTableContracts;