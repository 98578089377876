import React, {useEffect, useState} from 'react';
import Backdrop from "helpers/Backdrop";
import CloseModal from "assets/icons/close.svg";
import {useDispatch} from "useDispatch";
import {closeModal} from "stores/action";
import {useTypedSelector} from "helpers/reducers";
import clsx from "clsx";

interface ModalProps {}

const Modal = (props: ModalProps) => {
    const modal = useTypedSelector(state => state.modal);
    const loanState = useTypedSelector(state => state.loan);
    const dispatch = useDispatch();
    const [height, setHeight] = useState(0);
    const [disableClose, setDisableClose] = useState(false);
    let style;
    let translation;
    const [reload, setReload] = useState(performance.navigation.type);
    const [modalHeight, setModalHeigt] = useState({height: ""});

    useEffect(() => {
        setReload(0);
    }, [performance.navigation.type]);


    useEffect(() => {
        if (modal.props?.disableClose) {
            setDisableClose(true);
        }
    }, []);

    useEffect(() => {
       if (modal?.props?.isSignContract && loanState.contractsToSign?.filter(contract => contract.signed !== true).length === 0){
           setModalHeigt({height: "400px"})
       } else {
           setModalHeigt({height: ""});
       }

    }, [loanState.contractsToSign]);

    // alert(modal.component?.props);

    useEffect(() => {
        const documentHeight = document.documentElement.offsetHeight;
        setHeight(documentHeight);
    }, []);

    const closeModalHandler = () => {
        if (modal?.props?.onCloseCallback) {
            modal.props.onCloseCallback();
        }

        dispatch(closeModal());
    }

    if (document.documentElement.offsetWidth < 585) {
        // let heightOptions = (height / 2) * 0.9;
        style = 'translate(0%, 0%)';
        // style = 'translate(-50%, -50%)';
        translation = 'translate(0%,-250vh)';

    } else {
        style = 'translate(-50%, -50%)';
        translation = 'translate(-50%,-250vh)'
    }

    if (window.performance) {
        if (reload === 1) {
            dispatch(closeModal());
        }
    }


    return (
        <>
            <Backdrop show={modal.isOpen}/>
            <div
                className={clsx("Modal", modal.props?.className, modal.isOpen && 'showModal')}
                style={{
                    // transform: modal.isOpen ? style : translation,
                    opacity: modal.isOpen ? '1' : '0',
                    ...modalHeight
                }}
            >
                <div className="summary-login summary-forgotPassword summary-addDays">
                    {!disableClose && (
                        <div className="modal__header" style={{zIndex: 100, position: 'relative'}}>
                            <img src={CloseModal} className="closeModal" onClick={closeModalHandler} alt=""/>
                        </div>
                    )}
                    <div className="modal__body">
                        {modal.component}
                    </div>
                </div>
            </div>
        </>
    );
};

export default Modal;