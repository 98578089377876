import {useEffect} from "react";
import LoanAction from "stores/loan/LoanAction";
import {LoanStatus} from "types/enums/LoanStatus";
import {useDispatch} from "useDispatch";
import {LoanState} from "stores/loan/LoanReducer";
import {useTypedSelector} from "helpers/reducers";
import LogicAction from "stores/logic/LogicAction";

export const useFetchActiveLoanInterval = (loan: LoanState, INTERVAL: number) => {
    const dispatch = useDispatch();
    const prePendingDeferralOrder = useTypedSelector(state => state.logic.prePendingDeferralOrder);


    useEffect(() => {
        const refreshIntreval = setInterval(() => {
            if (!loan.activeLoan) {
                return clearInterval(refreshIntreval);
            }

            dispatch(LoanAction.fetchActiveLoan()).then(r => {
                if (prePendingDeferralOrder) {
                    dispatch(LoanAction.createDeferralOrder(prePendingDeferralOrder.days)).then(r => {
                        dispatch(LoanAction.fetchDeferralOrder(r.id));
                        dispatch(LogicAction.setPrePendingDeferralOrder(null));
                    });
                    return;
                }

                if (r && r?.id) {
                    dispatch(LoanAction.fetchDeferralOrder(r.id))
                }
            });

            if (loan.activeLoan.status !== LoanStatus.SentToBankAccount && loan.contractsToSign.length === 0) {
                dispatch(LoanAction.fetchAllContractsToSign());
            }

        }, INTERVAL)

        return () => clearInterval(refreshIntreval);
    }, [loan.activeLoan, loan.contractsToSign, loan.pendingDeferredOrder]);
}