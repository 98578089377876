import React, {useEffect} from 'react';
import PurpleButton from "components/buttons/PurpleButton";
import {useDispatch} from "useDispatch";
import {closeModal} from "stores/action";
import Loader from "components/loader/Loader";

interface Props {
    error: any,
    setActiveTab:  React.Dispatch<React.SetStateAction<string>>
}

const FormError = (props: Props) => {
    const dispatch = useDispatch();


    const setActiveTabHandler = () => {
        props.setActiveTab('forgotten-password');
        dispatch(closeModal());
    }

    if (!props.error.type || !props.error.message) {
        return <Loader/>
    }

    return (
        <div className="FormError">
            <h2 className={"page__section--title mb-4"}>{props.error.type}</h2>
            <p className={"mb-4"}>{props.error.message}. Pokud jste zapomněli svoje heslo, můžete si jej nechat zaslat:
                <span className={"purple-text cursor-pointer"} onClick={setActiveTabHandler}> zde </span></p>
            <PurpleButton class={"d-block mx-auto"}>Skusit se přihlásit znovu</PurpleButton>
        </div>
    );
};

export default FormError;