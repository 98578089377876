import React from 'react';

interface TableRowProps {
    children: React.ReactNode | React.ReactNode[]
}

const TableRow = (props: TableRowProps) => {
    return (
        <tr>
            {props.children}
        </tr>
    );
};

export default TableRow;