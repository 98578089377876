import React from 'react';
import TableHead from "components/Table/components/TableHead";
import TableRow from "components/Table/components/TableRow";
import TableHeadItem from "components/Table/components/TableHeadItem";
import TableBody from "components/Table/components/TableBody";
import TableItem from "components/Table/components/TableItem";
import {dateParse, getDateTime} from "helpers/utility";
import Download from "assets/icons/download.svg";
import Table from "components/Table/Table";
import Loader from "components/loader/Loader";
import {Contract} from "types/entities";


interface Props {
    contracts: Contract[]
}

const ContractsTable = (props: Props) => {
    if (!props.contracts) {
        return <Loader/>
    }

    return (
        <Table>
            <TableHead>
                <TableRow>
                    <TableHeadItem>SMLOUVA</TableHeadItem>
                    <TableHeadItem>PODEPSÁNO</TableHeadItem>
                    <TableHeadItem>DATUM AKCE</TableHeadItem>
                    <TableHeadItem className={"pdf-tablehead-tour"}>PDF</TableHeadItem>
                </TableRow>
            </TableHead>
            <TableBody>
                {props.contracts.map(result => (
                    <TableRow key={result.id}>
                        <TableItem>{result.type_text}{result.number ? " - " + result.number : ""}</TableItem>
                        <TableItem>{getDateTime(result.signed)}</TableItem>
                        <TableItem>{dateParse(result.created)}</TableItem>
                        <TableItem>
                            <div className="d-flex cursor-pointer">
                                <a rel={"no-referrer"} href={result.url} target={"_blank"} download>
                                    <img src={Download} alt="download icon"/>
                                    <span className={"url"}>PDF</span>
                                </a>
                            </div>
                        </TableItem>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    );
};

export default ContractsTable;