import LogicAction from "stores/logic/LogicAction";
import update from "immutability-helper";


interface LogicReducerSetSignLaterFlag {
    payload: boolean,
    type: typeof LogicAction.SET_SIGN_LATER_FLAG
}
interface LogicResetLogicState {
    payload: null,
    type: typeof LogicAction.RESET_LOGIC_STATE
}
interface LogicSetPrePendingDeferralOrder {
    payload: number | null,
    type: typeof LogicAction.SET_PREPENDING_DEFERRAL_ORDER
}
interface LogicSetUserPaperNotification {
    payload: boolean,
    type: typeof LogicAction.SET_USER_PAPER_NOTIFICATION
}

type LogicReducerAction =
    LogicReducerSetSignLaterFlag
    | LogicResetLogicState
    | LogicSetPrePendingDeferralOrder
    | LogicSetUserPaperNotification;

interface LogicState {
    flags: {
        signLater: boolean
    },
    personalDocuments: {
        server_value: boolean | null,
        current_value: boolean | null,
    },
    prePendingDeferralOrder: {days: number} | null,
    showUserPaperNotification: boolean
}

const defaultState = {
    flags: {
        signLater: false
    },
    personalDocuments: {
        server_value: null,
        current_value: null,
    },
    prePendingDeferralOrder: null,
    showUserPaperNotification: true,
};

class LogicReducer {
    initialState: LogicState = defaultState

    reducer = (state: LogicState = this.initialState, action: LogicReducerAction): LogicState => {
        switch (action.type) {
            case LogicAction.SET_SIGN_LATER_FLAG:
                return update(state, {
                    flags: {
                        signLater: {
                            $set: action.payload
                        }
                    }
                });
            case LogicAction.RESET_LOGIC_STATE:
                return {
                    ...defaultState
                };
            case LogicAction.SET_PREPENDING_DEFERRAL_ORDER:
                console.log(action.payload)
                if (!action.payload) {
                    return update(state, {
                        prePendingDeferralOrder: {
                            $set: null
                        }
                    });
                }

                return update(state, {
                    prePendingDeferralOrder: {
                        $set: {
                            days: action.payload
                        }
                    }
                })
            case LogicAction.SET_USER_PAPER_NOTIFICATION:
                return update(state, {
                   showUserPaperNotification: {
                       $set: action.payload
                   }
                });

            default:
                return state;
        }
    }
}

export default LogicReducer