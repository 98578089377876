import React from 'react';
import {Link} from "react-router-dom";
import PurpleButton from "components/buttons/PurpleButton";
import LoanCardContainer from "pages/activeLoan/components/LoanCardContainer";

interface Props {}

const ActiveLoanNone = (props: Props) => {
    return (
        <LoanCardContainer>
            <div className="d-flex flex-wrap justify-content-between align-items-center">
                <span className={""}>Momentálně nemáte žádný aktivní úvěr.</span>
                <Link to={"/zadost-o-uver"} className={"center-button mt-3 mt-md-0"}>
                    <PurpleButton class="btn btn-purple">
                        Chci úvěr
                    </PurpleButton>
                </Link>
            </div>

        </LoanCardContainer>
    );
};

export default ActiveLoanNone;