import React, {useEffect, useState} from 'react';
import {useTypedSelector} from "helpers/reducers";
import PageHeading from "components/global/PageHeading";
import {getNumberOfItemsPerPage, MOBILE_BREAKPOINT, sortItemsByDateTime} from "helpers/utility";
import {useDispatch} from "useDispatch";
import LoanAction from "stores/loan/LoanAction";
import Loader from "components/loader/Loader";
import Pagination from "components/pagination/Pagination";
import ContractsTable from "pages/documents/components/ContractsTable";
import useWindowDimensions from "helpers/hooks/useWindowDimensions";
import MobileTableContracts from "pages/documents/components/MobileTableContracts";
import {Contract} from "types/entities";
import {ContractsType} from "types/enums/ContractsType";

const Contracts = () => {
    const dispatch = useDispatch();
    const contracts = useTypedSelector(state => state.loan.contracts);
    const {height, width} = useWindowDimensions();
    const mobile = width < MOBILE_BREAKPOINT;
    const perPage = mobile ? contracts.length : getNumberOfItemsPerPage(width);
    const initList = contracts
        .filter(contract => contract.signed !== null)
        .filter((item, index) => index < perPage);
    const [list, setList] = useState<Contract[]>(sortItemsByDateTime(initList));

    useEffect(() => {
        dispatch(LoanAction.fetchContractsListByType(ContractsType.Contracts));
    }, []);

    if (!contracts) {
        return <Loader/>
    }

    return (
        <div className="Contracts prepare-sticky-content">
            <PageHeading title={"Smlouvy o úvěru"}/>
            {mobile
                ? <MobileTableContracts contracts={sortItemsByDateTime(list)}/>
                : (
                    <React.Fragment>
                        <ContractsTable contracts={sortItemsByDateTime(list)}/>
                        {(perPage > 4 && contracts.length > 4)
                        && <Pagination
                            setNewState={setList}
                            perPage={7}
                            data={contracts}
                            numberOfItems={contracts.length}/>

                        }
                    </React.Fragment>
                )}
        </div>
    );
};

export default Contracts;