import React, {useEffect} from 'react';
import {Col, Container, Row} from 'react-bootstrap';
import DrawerToggler from "components/drawerToggler/DrawerToggler";
import ClientStatus from "components/clientStatus/ClientStatus";
import PurpleButton from "components/buttons/PurpleButton";
import NavItems from "components/navbar/navItems/NavItems";
import Logo from "components/logo/Logo";
import GopayStatus from "components/gopayStatus/GopayStatus";
import Servis from "components/navbar/components/Servis";
import {useTypedSelector} from "helpers/reducers";
import {RouteComponentProps, withRouter} from "react-router";
import {Link} from 'react-router-dom';
import clsx from "clsx";
import Loader from "components/loader/Loader";
import ClientIdentityStatus, {statusType} from "components/status/ClientIdentityStatus";
import SessionAction from "stores/user/SessionAction";
import {useDispatch} from "useDispatch";

interface Params {}

interface NavbarProps extends RouteComponentProps<Params> {
    // session: Session;
    setOpenNav: any;
    openNav: boolean;
    className?: string
}

const Navbar = (props: NavbarProps) => {
    const dispatch = useDispatch();
    const session = useTypedSelector(state => state.session);
    const hasActiveLoan = useTypedSelector(state => state.loan.activeLoan);
    const disabled = hasActiveLoan !== null


    const identityHandler = () => {
        if (session.customerPapers.length === 0) {
            props.history.push("/osobni-udaje/doklady")
        }
    }

    // if (!hasActiveLoan?.id) {
    //     return <Loader/>;
    // }

    return (
        <header className={clsx("header", props.className)}>
            <Container fluid className="p-3 p-lg-3">
                <Row className="justify-content-between">
                    <Col xs="6" md="auto" className="d-flex pt-sm-2">
                        {!props.openNav && <Logo/>}
                        <div className="border ml-3 DesktopOnly"> </div>
                        {session.token ? (
                            <div className="gopay-status DesktopOnly" onClick={identityHandler}>
                                {/*<GopayStatus/>*/}
                                <ClientIdentityStatus status={statusType.AUTH_FAILED} user={session.user}/>
                            </div>
                        ) : <Servis/>}
                    </Col>
                    <Col xs="6" md="auto" className="d-flex justify-content-end">
                        <nav className="DesktopOnly d-flex align-items-center">
                            <NavItems/>
                            {session.token && <ClientStatus/>}
                            {(session.token && !hasActiveLoan) && (
                                <Link to={"/zadost-o-uver"}>
                                    <PurpleButton
                                        disabled={disabled}>
                                        CHCI ÚVĚR
                                    </PurpleButton>
                                </Link>
                                )}
                        </nav>
                        <DrawerToggler setOpenNav={props.setOpenNav} openNav={props.openNav}/>
                    </Col>
                </Row>
            </Container>
        </header>
    );
};

export default withRouter(Navbar);