import React, {useEffect, useState} from 'react';
import PurpleButton from "components/buttons/PurpleButton";
import {useDispatch} from "useDispatch";
import LoanAction from "stores/loan/LoanAction";
import {GetActiveLoanResponse} from "types/Responses";
import {useTypedSelector} from "helpers/reducers";
import Loader from "components/loader/Loader";
import {useForm} from "react-hook-form";
import {closeModal} from "stores/action";
import Radio from "components/inputElement/Radio";
import PaymentMethodForm from "pages/activeLoan/components/PaymentMethodForm";
import {DeferralOrder} from "types/entities/DeferralOrder";


interface Props {
    loan: GetActiveLoanResponse,
    pendingDeferralOrder?: any,
}

const LoanDeferredPaymentModal = (props: Props) => {
    const dispatch = useDispatch();
    const deferredOrdersList = useTypedSelector(state => state.loan.deferredOrderList);
    const [activeValue, setActiveValue] = useState<any>(7);
    const [activeAmount, setActiveAmount] = useState<any>(null);
    const [goToSelectPaymentMethod, setGoToSelectPaymentMethod] = useState<boolean>(!!props.pendingDeferralOrder);
    const [order, setOrder] = useState<any>(null);

    const form = useForm();

    const setGoToSelectPaymentMethodHandler = (): void => {
        const days = form.getValues('days');
        dispatch(LoanAction.createDeferralOrder(+days)).then(r => {
            dispatch(LoanAction.fetchDeferralOrder(props.loan.id));
            setOrder(r)
        })
        setGoToSelectPaymentMethod(true);
    }

    const sendViaEmail = () => {}

    const payByQR = () => {}
    const payByTransfer = () => {}

    const payByCard = (data): void => {
        dispatch(LoanAction.fetchDeferralOrder(props.loan.id)).then(r => {
            const orderId = props.pendingDeferralOrder?.order_id || r?.order_id;
            const amount = props.pendingDeferralOrder ? props.pendingDeferralOrder.deferral_amount : activeAmount;

            dispatch(LoanAction.payOrder({order_id: orderId, amount: amount})).then(r => {
                dispatch(closeModal());
                window.location.href = r.redirect_url;
            })
        });
    }

    if (!deferredOrdersList) {
        return <Loader/>;
    }

    return (
        <div className="LoanDeferredPaymentModal">
            <h3 className={"page__section-title"}>Deferred Payment</h3>
            <form className={"Radio d-flex flex-column justify-content-start"}>
                {goToSelectPaymentMethod
                    ? (
                        <PaymentMethodForm
                            payByCard={form.handleSubmit(payByCard)}
                            payByQR={payByQR}
                            payByTransfer={payByTransfer}
                            sendViaEmail={sendViaEmail}/>
                    ) : (
                        <div className="Purpose-purposeButtons">
                            {props.loan.deferral_price_list.map(deferral => (
                                <Radio key={deferral.days} deferral={deferral} register={form.register} activeValue={activeValue} setActiveValue={setActiveValue} setFormValue={setActiveAmount}/>
                            ))}
                        </div>
                        )}
            </form>
            <PurpleButton action={setGoToSelectPaymentMethodHandler}>Zaplatit odklad</PurpleButton>
        </div>
    );
};

export default LoanDeferredPaymentModal;