import React from 'react';

interface Props {
    type: string
}

const ScanStatus = (props: Props) => {

    const getActiveClass = (): {class: string, type_text: string} => {
        switch (props.type) {
            case "SUCCESS":
                return {class: "id-status-success", type_text: "Potvrzeno operátorem"};
            case "PENDING":
                return {class: "id-status-waiting", type_text: "Čeká na ověření"};
            case "FAILED":
                return {class: "", type_text: ""};
            default:
                return {class: "id-status-waiting", type_text: "Čeká na ověření"};
        }
    }
    const status = getActiveClass();


    return (
        <div className={status.class}>{status.type_text}</div>
    );
};

export default ScanStatus;