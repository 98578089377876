import React from 'react';
import Banner from "pages/activeLoan/components/Banner";
import {useHistory} from "react-router-dom";
import UserAuthFailed from "assets/icons/UserIcons/UserAuthFailed.svg";

interface Props {}

const PapersBanner = (props: Props) => {
    const history = useHistory();

    const uploadPapers = () => {
        history.push("/osobni-udaje/doklady");
    };

    return (
        <Banner
            icon={UserAuthFailed}
            text={<>Prosím nahrajte 2 doklady v sekci Osobní dokumenty - Doklady nebo kliknete na ikonku identity!</>}
            buttonText={"Nahrát doklady"}
            callback={uploadPapers}/>
    );
};

export default PapersBanner;