import React from 'react';
import {Document} from "types/entities";
import {getDateTime} from "helpers/utility";
import Download from "assets/icons/download.svg";

interface Props {
    document: Document
}

const MobileTableItemDocuments = (props: Props) => {
    return (
        <div className={"LoanHistoryTableItemMobile d-flex justify-content-between"}>
            <div className={"cursor-pointer max-width-70"}>
                <div><span className={"cursor-link"}>{props.document.type_text}</span> - {props.document.id}</div>
                <div>{getDateTime(props.document.created)}</div>
            </div>
            <div className="d-flex align-items-center url-mobile">
                <a rel={"noreferrer"} href={props.document.url} target={"_blank"} download>
                    <img src={Download} alt="download icon"/>
                    <span className={"url"}>PDF</span>
                </a>
            </div>
        </div>
    );
};

export default MobileTableItemDocuments;