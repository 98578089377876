import React from 'react';
import Button from "components/buttons/Button";
import clsx from "clsx";

interface whiteButton {
    children: React.ReactNode | React.ReactNode[],
    action?: any,
    class?: string
    disabled?: boolean
}

const WhiteButton = (props: whiteButton) => {
    return (
        <div>
            <Button action={props.action} type={"button"} className={clsx("btn btn-main", props.class)} disabled={props.disabled}>{props.children}</Button>
        </div>
    );
};

export default WhiteButton;