import React from 'react';
import LimitedBg from "assets/icons/limitedBg.svg";
import Timer from "assets/icons/timer.svg";
import PurpleButton from "components/buttons/PurpleButton";
import {useDispatch} from "useDispatch";
import {openModal} from "stores/action";
import SignContract, {ContractSignObject} from "components/contract/SignContract";
import {ActiveLoan, Loan} from "types/entities";
import LogicAction from "stores/logic/LogicAction";
import Banner from "pages/activeLoan/components/Banner";

interface Props {
    contractsToSign: ContractSignObject[]
}

const SignContractBanner = (props: Props) => {
    const dispatch = useDispatch();
    const signContract = () => dispatch(openModal(<SignContract contracts={props.contractsToSign}/>, {className: "ModalLoanDetail ModalContractDetail ModalLoanDetailShrink", isSignContract: true, onCloseCallback: closeCallback}))

    const closeCallback = () => {
        dispatch(LogicAction.setSignLaterFlag(true));
    }

    return (
        <Banner
            text={<>Dokumenty jsou <b>připravené a čekají na Váš podpis.</b></>}
            buttonText={"Podepsat smlouvu"}
            callback={signContract}/>
    );
};

export default SignContractBanner;