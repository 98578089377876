import Lazy from "yup/lib/Lazy";
import * as yup from "yup";
import React, {useEffect, useRef} from "react";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";

const useUpdatePersonData = (defaultValues: object, formSchema: Lazy<any, any> | yup.AnyObjectSchema, type, isDisabled: boolean, setIsDisabled: { (value: React.SetStateAction<boolean>): void; (arg0: boolean): void; }) => {
    type TType = ReturnType<typeof type>
    const form = useForm<TType>({
        defaultValues,
        resolver: yupResolver(formSchema)
    });

    const formValues = form.watch();

    const firstUpdate = useRef(true)
    useEffect(() => {
        if (firstUpdate.current) {
            firstUpdate.current = false;
            return;
        }

        // setIsDisabled(false);
    }, [formValues]);

    return form;
};

export default useUpdatePersonData;