import React from 'react';
import clsx from "clsx";
import Table from "components/Table/Table";
import TableHead from "components/Table/components/TableHead";
import TableRow from "components/Table/components/TableRow";
import TableHeadItem from "components/Table/components/TableHeadItem";
import TableBody from "components/Table/components/TableBody";
import TableItem from "components/Table/components/TableItem";
import {Loan} from "types/entities";
import {dateParse} from "helpers/utility";
import {ROOT_API} from "helpers/config";
import Download from "assets/icons/download.svg"

interface Props {
    loanDetail: Loan
    isMobile: boolean
}

const LoanDetailPaymentsTab = (props: Props) => {
    return (
        <div className={"text-left mt-3"}>
            <h5>Přehled položek ke splacení:</h5>
            {props.loanDetail.payments?.length === 0 ? (<div className={"mt-3"}><h5>...prozatím jste nevyužil odložení splatnosti úvěru.</h5></div>) : (
                <React.Fragment>
                    <div className={clsx((props.loanDetail.payments?.length > 6 && !props.isMobile) && "tableBodyRestriction")}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableHeadItem>ID ÚVĚRU</TableHeadItem>
                                    <TableHeadItem className={"text-left"}>TYP</TableHeadItem>
                                    <TableHeadItem className={"text-center"}>DATUM</TableHeadItem>
                                    <TableHeadItem className={"text-center"}>ČÁSTKA</TableHeadItem>
                                    <TableHeadItem className={"text-center"}>ZDROJ</TableHeadItem>
                                    <TableHeadItem className={"text-center"}>PDF</TableHeadItem>
                                </TableRow>
                            </TableHead>
                            <TableBody className={"tableBodyRestriction"}>
                                {props.loanDetail.payments?.map(payment => (
                                    <TableRow>
                                        <TableItem>{props.loanDetail.contract_number}</TableItem>
                                        <TableItem className={"text-left"}>{payment.payment_type}</TableItem>
                                        <TableItem className={"text-center"}>{dateParse(payment.date)}</TableItem>
                                        <TableItem className={"text-center"}>{payment.amount} Kč</TableItem>
                                        <TableItem className={"text-center"}>{payment.source}</TableItem>
                                        <TableItem className={"text-center"}>
                                            <div className="d-flex justify-content-center align-items-center">
                                                <a rel={"noreferrer"} href={ROOT_API + payment.doc_url} target={"_blank"} download>
                                                    <img src={Download} alt="download icon"/>
                                                    <span className={"url"}>PDF</span>
                                                </a>
                                            </div>
                                        </TableItem>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </div>
                </React.Fragment>
                )}
        </div>
    );
};

export default LoanDetailPaymentsTab;