import React from 'react';
import clsx from "clsx";

interface SideNavItemContainerProps {
    children: React.ReactNode | React.ReactNode[];
    open: any;
    id?: string;
    className?: string,
}

const SideNavItemContainer = (props: SideNavItemContainerProps) => {
    return (
        <li className={clsx("SideNavigation__list-item", props.open.action && props.open.id === props.id && "open", props.className)}>
            {props.children}
        </li>
    );
};

export default SideNavItemContainer;