import React, {Suspense} from 'react';
import {Redirect} from "react-router-dom";
import ClientRoute from "helpers/Route";
import {useTypedSelector} from "helpers/reducers";
import Loader from "components/loader/Loader";

interface privateRoute {
    path: string;
    location?: string;
    auth?: object;
    children: React.ReactNode | React.ReactNode[]
}

const PrivateRoute = (props: privateRoute) => {
    const auth = useTypedSelector(state => state.session.token);

    return (
        <ClientRoute path={props.path}>
            {auth
                ? props.children
                : <Redirect to={{pathname: '/prihlaseni', state: { from: props.location }}}/>
            }
        </ClientRoute>
    );
};

export default PrivateRoute;