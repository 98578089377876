import React from 'react';
import clsx from "clsx";

interface Props {
    children: React.ReactNode | React.ReactNode[],
    className?: string
}

const FormRow = (props: Props) => {
    return (
        <div className={clsx("row-inputs", props.className)}>
            {props.children}
        </div>
    );
};

export default FormRow;