import React, {useEffect} from "react";

import "assets/styles/components/Contact.scss"
import PhoneStanding from "assets/icons/phoneStanding.svg";
import Phone from "assets/icons/phone.svg";
import PhoneBody from 'assets/icons/phoneBody.png';
import {useDispatch} from "useDispatch";
import {useForm} from "react-hook-form";
import ContactForm from "pages/contact/components/ContactForm";
import {RouteComponentProps, withRouter} from "react-router";
import ContactFormMessage from "pages/contact/components/ContactFormMessage";
import clsx from "clsx";
import PurpleButton from "components/buttons/PurpleButton";
import {Col, Container, Row} from "react-bootstrap";
import TheoKeys from "assets/icons/theo/keys.svg";
import LoginForm from "pages/user/components/LoginForm";
import ForgotPasswordForm from "pages/user/components/ForgotPasswordForm";
import PageHeading from "components/global/PageHeading";
import Select from "components/inputElement/Select";

interface Params {}

interface Props extends RouteComponentProps<Params>{

}

const Contact = (props: Props) => {
    const dispatch = useDispatch();
    const { register, handleSubmit,setValue, watch, setError, formState: { errors } } = useForm();

    // useEffect(() => {
    //         dispatch(AppControllerAction.setAppStatus(AppStatus.Started));
    //         dispatch(AppControllerAction.setAppProgressStatus(null));
    //         dispatch(AppControllerAction.setCurrentStep(AppCurrentStep.Start));
    // }, [])


    const sendEmailHandler = data => {
        // dispatch(AppControllerAction.sendEmail(data));
    };

    const redirectToHomepage = () => {
        // dispatch(AppControllerAction.setEmailResponse(null));
        // props.history.push(LinkTo.home());
    }

    return (
        <div className={"w-50"}>
            <PageHeading title={"Kontakt"}/>
            <form onSubmit={handleSubmit(sendEmailHandler)}>
                <Select className={"mb-2"}/>
                <div className="form-group mb-2">
                    <textarea rows={5} name="message" className={clsx("form-control", errors.email?.message && 'error-input')} ref={register} placeholder="Message:" id="message"/>
                    {errors.email?.message && <label className={"error-label"}>{errors.email?.message}</label>}
                </div>
                <PurpleButton type="submit" class={"d-block mx-0"}>Odeslat</PurpleButton>
            </form>

        </div>
    );
};


export default withRouter(Contact);