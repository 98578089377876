import React from 'react';

interface Props {
    type?: number
}

const ContractText = (props: Props) => {

    switch (props.type) {
        case 2:
            return (<React.Fragment>
                    <b>Rámcovou smlouvu podepíšete pouze jednou.</b> Na základě této smlouvy se naše společnost zavazuje, že v případě poctivého splácení,
                    Vám budeme poskytovat další úvěry a s tím spojené další slevy a produkty. Také jsou v ní jasně uvedeny sankce v případě nesplacení.
                    <b>Nic netajíme ani neschováváme, vše je v Rámcové smlouvě jasně a čitelně uvedeno!</b>
                    Důkladně si prosím dokument přečtěte a pokud s ním souhlasíte, klikněte na tlačítko
                    <b>„ SOUHLASÍM A CHCI VYGENEROVAT PODPISOVÝ KÓD “</b> a  vyčkejte na doručení podpisového kódu na váš telefon.
                </React.Fragment>)
        case 0:
           return  (<React.Fragment>
                        Tato dohoda jasně říká, že v případě nesplacení úvěru bude rozhodnuto <b>podle českého práva</b> v rozhodčím řízení.
                        Svůj podpis a tím i souhlas s Dohodou potvrzujete vložením podpisového kódu.
                        Dohodu o řešení sporu <b>prosím podepište stejným podpisovým kódem</b>, kterým jste podepsal(a) Rámcovou smlouvu.
                    </React.Fragment>)
        case 1:
               return (<React.Fragment>
                        <b>Nyní prosím podepište Smlouvu o úvěru</b>, ve které je uvedeno vše, na čem jsme se společně dohodli. Překontrolujte si prosím všechny údaje, především doby splatností a celkové částky k úhradě.
                        Pokud je vše v pořádku a s podmínkami smlouvy souhlasíte – použijte tlačítko: <b>„ SOUHLASÍM A CHCI VYGENEROVAT PODPISOVÝ KÓD “</b> a vyčkejte na doručení podpisového kódu na váš telefon.
                    </React.Fragment>)
        default:
            return (<React.Fragment>
                        Děkujeme za podpis dokumentu a uzavření obchodu. Věříme, že dodržíte podmínky naší Dohody.
                        Po stisknutí tlačítka – POKRAČOVAT Vám <b>budou odeslány finance na Váš účet</b>.
                        Děkujeme, že využíváte naše finanční služby a doufáme, že se na nás v budoucnu opět obrátíte.
                    </React.Fragment>)
    }
};

export default ContractText;