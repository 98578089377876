import React, {useEffect, useState} from 'react';
import PageHeading from "components/global/PageHeading";
import PurpleButton from "components/buttons/PurpleButton";
import {useForm} from "react-hook-form";
import FormPart from "pages/PersonalData/components/FormPart";
import FormRow from "pages/PersonalData/components/FormRow";
import FormColumn from "pages/PersonalData/components/FormColumn";
import FormInput from "pages/PersonalData/components/FormInput";
import CheckBox from "components/inputElement/CheckBox";
import FormWrap from "pages/PersonalData/components/FormWrap";
import {useTypedSelector} from "helpers/reducers";
import Loader from "components/loader/Loader";
import useUpdatePersonData from "helpers/hooks/useUpdatePersonalData";
import {toast} from "react-toastify";
import SessionAction from "stores/user/SessionAction";
import * as yup from "yup";
import {useDispatch} from "useDispatch";
import {toastInfo} from "helpers/toasts";

interface Props {}

const Residence = (props: Props) => {
    const user = useTypedSelector(state => state.session.user);

    const dispatch = useDispatch();
    // const disabled = user.addresses[1].is_active;
    const hasActiveLoan = useTypedSelector(state => state.loan.activeLoan?.id) !== null;
    const [isDisabled, setIsDisabled] = useState<boolean>(hasActiveLoan);

    const defaultValues = user ? {
        ...user.business.address,
        c_street: user.address?.street,
        c_city: user.address?.city,
        c_house_number: user.address?.house_number,
        c_postal_code: user.address?.postal_code
    } : {}


    const personalDataSchema = yup.object().shape({
        street: yup.string(),
        city: yup.string(),
        house_number: yup.string(),
        postal_code: yup.string(),
        c_street: yup.string(),
        c_city: yup.string(),
        c_house_number: yup.string(),
        c_postal_code: yup.string(),
    });

    const form = useUpdatePersonData(defaultValues, personalDataSchema, defaultValues, isDisabled, setIsDisabled)

    const onSubmitHandler = data => {
        console.log(data);
        if (hasActiveLoan) {
            setIsDisabled(true);
            toastInfo("Už máte aktivní úvěr, sorry jako..");
            return;
        }

        dispatch(SessionAction.updatePersonalData(data));
    };

    if (!user) {
        return <Loader/>
    }

    return (
        <div className="Residence">
            <PageHeading title={"Sídlo"}/>

            <FormWrap onSubmit={form.handleSubmit(onSubmitHandler)}>
                <FormPart title={"Adresa sídla"}>
                    <FormRow>
                        <FormColumn>
                            <FormInput title={"Ulice"} register={form.register} name={"street"} disabled/>
                            <FormInput title={"Město"} register={form.register} name={"city"} disabled/>
                        </FormColumn>
                        <FormColumn>
                            <FormInput title={"Číslo popisné"} register={form.register} name={"house_number"} disabled/>
                            <FormInput title={"PSČ"} register={form.register} name={"postal_code"} disabled/>
                        </FormColumn>
                    </FormRow>
                </FormPart>
                <FormPart title={"Doručovací adresa"} className={"mt-4"}>
                    <FormRow>
                        <FormColumn>
                            <FormInput title={"Ulice"} register={form.register} name={"c_street"} disabled/>
                            <FormInput title={"Město"} register={form.register} name={"c_city"} disabled/>
                        </FormColumn>
                        <FormColumn>
                            <FormInput title={"Číslo popisné"} register={form.register} name={"c_house_number"} disabled/>
                            <FormInput title={"PSČ"} register={form.register} name={"c_postal_code"} disabled/>
                        </FormColumn>
                    </FormRow>
                </FormPart>

                {/*<PurpleButton action={form.handleSubmit(onSubmitHandler)} class="btn btn-purple" disabled={isDisabled}>*/}
                {/*    Uložit změny*/}
                {/*</PurpleButton>*/}
            </FormWrap>
        </div>
    );
};

export default Residence;