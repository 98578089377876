import React, {useState} from 'react';
import Button from "components/buttons/Button";
import {useTypedSelector} from "helpers/reducers";
import clsx from 'clsx';
import Loader from "components/loader/Loader";

interface purpleButton {
    children: React.ReactNode | React.ReactNode[];
    disabled?: boolean;
    action?: any;
    type?: string;
    class?: string;
    loadingButton?: boolean,
    style?: any
}

const PurpleButton = (props: purpleButton) => {
    const session = useTypedSelector(state => state.session);
    const type = props.type !== undefined ? props.type : "button";
    const [showLoader, setShowLoader] = useState(false);

    const actionHandler = () => {
        if (props.loadingButton) {
            setShowLoader(true);

            setTimeout(() => {
                setShowLoader(false);
                props.action();

            }, 1500);
            clearTimeout();
        } else {
            props.action && props.action();
        }

    }

    return (
        <>
            <Button
                type={type}
                className={clsx("btn btn-purple", !session.token && "top-0", props.class)}
                style={props.style}
                disabled={props.disabled}
                action={actionHandler}>
                {showLoader
                    ? (<>
                        <span className="spinner-border spinner-border-sm" style={{position: "relative", left: "-2px"}} role="status" aria-hidden="true"></span>
                        <span> {" "} Už to bude...</span>
                    </>)
                    : props.children}
            </Button>
        </>
    );
};

export default PurpleButton;