import React from 'react';
import {NavLink} from "react-router-dom";

const NavItem = (props: any) => {
    return (
        <li className="nav-link">
            <NavLink to={props.link}>{props.children}</NavLink>
        </li>
    );
};

export default NavItem;