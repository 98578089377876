import React from 'react';
import {useForm} from "react-hook-form";
import PurpleButton from "components/buttons/PurpleButton";
import {closeModal} from "stores/action";
import {useDispatch} from "useDispatch";
import Loader from "components/loader/Loader";

interface Props {
    signContract: (data) => void,
    contract: any
}


const SignForm = (props: Props) => {
    const form = useForm();

    const submitHandler = data => {
        props.signContract({contract_type: +props.contract.type, code: data.code});
    }

    if (!props.contract) {
        return <></>
    }

    return (
        <div className="SignForm">
            <form onSubmit={form.handleSubmit(submitHandler)}>
                <div className="d-flex">
                    <div>
                        <input type="hidden" name={"contract_type"} ref={form.register} value={props.contract.type}/>
                        <input type="text" name={"code"} ref={form.register}/>
                    </div>
                    <PurpleButton action={form.handleSubmit(submitHandler)}>Podepsat</PurpleButton>
                </div>
            </form>
        </div>
    );
};

export default SignForm;