import React from 'react';
import SideNav from "components/navbar/sidebarNav/SideNav";
import clsx from "clsx";
import {useTypedSelector} from "helpers/reducers";
import ClientStatus from "components/clientStatus/ClientStatus";
import ClientHeader from "components/clientHeader/ClientHeader";

interface SideDrawerProps {
    openNav: boolean;
    setOpenNav: any,
}

const SideDrawer = (props: SideDrawerProps) => {
    const session = useTypedSelector(state => state.session);

    return (
        <div className={clsx(!session.token ? "unLogged" : "SideDrawer", props.openNav ? "Open" : "Close")}>
            <nav className="mt-0">
                {session.token ? (
                    <>
                        <div className="d-flex">
                            <ClientHeader/>
                            {/*<Status/>*/}
                        </div>
                        <SideNav setOpenNav={props.setOpenNav}/>
                    </>
                ) : (
                    <div className="d-flex">Unlogged</div>
                )}
                {/*<ContactUnderNav/>*/}
            </nav>
        </div>
    );
};

export default SideDrawer;