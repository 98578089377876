import {LoanStatus} from "types/enums/LoanStatus";
import {LoanStatusColors} from "types/enums/LoanStatusColors";
import moment from 'moment';
import {User} from "types/entities";
import UserAuthSuccess from "assets/icons/UserIcons/UserAuthSuccess.svg";
import UserAuthProcessing from "assets/icons/UserIcons/UserAuthProcessing.svg";
import UserAuthFailed from "assets/icons/UserIcons/UserAuthFailed.svg";
import {toastError, toastInfo, toastSuccess} from "helpers/toasts";
import {ContractSignObject} from "components/contract/SignContract";
import {PaymentStatus} from "types/enums/PaymentStatus";
import LogicAction from "stores/logic/LogicAction";

export const MOBILE_BREAKPOINT = 585;
export const TABLET_BREAKPOINT = 768;
export const LAPTOP_BREAKPOINT = 1440;

/**
 * @function format ISO to time to dateTime with dot separator and 24 hours time format
 *
 * @param date - ISO time
 */
export const getDateTime = (date: number | string | Date | any) => {
    const dateTime = new Date(date);
    const year = dateTime.getFullYear();
    const month = dateTime.getMonth()+1;
    const hours = dateTime.getHours();
    const minutes = dateTime.getMinutes();

    let dt = dateTime.getDate();

    return dt + '.' + month + "." + year + ", " + hours + ":" + minutes;
}

/**
 * @function format ISO time to date with dot separator
 *
 * @param date - ISO time
 */
export const getDate = (date: number | string | Date) => {
    const dateTime = new Date(date);
    const year = dateTime.getFullYear();
    const month = dateTime.getMonth()+1;
    let dt = dateTime.getDate();

    return dt + '.' + month + "." + year
}

export const formatDate = (date: string) => {
    const newDate = date.split("-");
    return newDate[2] + '.' + newDate[1] + '.' + newDate[0];
}


/**
 * @function format price as europe currency - simply add dot between thousands
 *
 * @param inputValue
 */

export const formatCurrency = (inputValue: any) => {
    const currency = Intl.NumberFormat('de-DE')

    // if (inputValue?.id) {
    //     if (inputValue?.sum) {
    //         return currency.format(inputValue.sum);
    //     }
    //
    //     return currency.format(inputValue.amount + inputValue.fee);
    // }

    return currency.format(inputValue);
}


/**
 * @function returns loan status color: green | orange | red
 *
 * @param status - number between 1 - 26
 */
export const getLoanStatusColor = (status: number) => {
  switch (status) {
      case LoanStatus.Approved:
          return LoanStatusColors.Green;
      case LoanStatus.SentToBankAccount:
          return LoanStatusColors.Blue;
      case LoanStatus.PaidBack:
          return LoanStatusColors.Green;

      case LoanStatus.Denied:
          return LoanStatusColors.Red;
      case LoanStatus.WriteOff:
          return LoanStatusColors.Red;
      case LoanStatus.InkasoEOS:
          return LoanStatusColors.Red;
      case LoanStatus.Counsel:
          return LoanStatusColors.Red;
      case LoanStatus.SaleOfReceivable:
          return LoanStatusColors.Red;
      case LoanStatus.Storno:
          return LoanStatusColors.Gray;
      case LoanStatus.Police:
          return LoanStatusColors.Red;
      case LoanStatus.Insolvency:
          return LoanStatusColors.Red;
      case LoanStatus.DeniedByRobot:
          return LoanStatusColors.Red;
      case LoanStatus.InkasoMaxifine:
          return LoanStatusColors.Red;


      case LoanStatus.PreApproved:
          return LoanStatusColors.Orange;
      case LoanStatus.Processed:
          return LoanStatusColors.Orange;
      case LoanStatus.Overdue:
          return LoanStatusColors.Orange;
      case LoanStatus.RejectedCounterproposal:
          return LoanStatusColors.Orange;
      case LoanStatus.BeforeMaturity:
          return LoanStatusColors.Orange;
      case LoanStatus.BeforeInsolvency:
          return LoanStatusColors.Orange;
      case LoanStatus.BeforeComplaint:
          return LoanStatusColors.Orange;
      case LoanStatus.BeforeCollection:
          return LoanStatusColors.Red;

      default:
          return LoanStatusColors.White;
  }
};

export const dateParse = (date) => {
    const parsedDate = new Date(date).toLocaleDateString("cs-CZ",{ year: 'numeric', month: '2-digit', day: '2-digit' })
    return parsedDate.replace(/\s+/g, '');
}

export const getMonthFromDate = (date) => {
    const splited = date.split('.');
    return splited[1];
}

export const getNumberOfItemsPerPage = (viewPortSize) => {
    switch (viewPortSize) {
        case viewPortSize > LAPTOP_BREAKPOINT:
            return 8

        default:
            return 6; // default is laptop breakpoint
    }
}

export const getBirthDateFromBirthNumber = (birthNumber: string) => {
    const birthDate = getBirthDateObject(birthNumber);
    const date = moment(birthDate.day + '.' + birthDate.month + '.' + birthDate.year, 'DD.MM.YYYY').format('L');
    const result = date.split('/');
    return result[1] + '.' + result[0] + '.' + result[2];

}

export const processErrorResponse = (error, exception?: boolean) => {
    if (!error.response) {
        toastError("Error: processErrorResponse() can't convert to object");
        return;
    }

    if (exception) {
        processStatusError(error.response.status, exception);
    }

    if (!exception) {
        const attribute = Object.keys(error.response?.data)[0].toString();
        // console.log(error.response.data[attribute])
        return toastError(error.response.data[attribute]);
    }
}

export const getStatusObject = (user: User) => {
    if (user?.papers_uploaded && user?.papers_checked) {
        return {
            icon: UserAuthSuccess,
            text:"Byla ověřena identita klienta"
        }
    } else if (user?.papers_uploaded && !user?.papers_checked) {
        return {
            icon: UserAuthProcessing,
            text:"Probíhá ověření identity klienta"
        }
    } else {
        return {
            icon: UserAuthFailed,
            text:"Nebyla ověřena identita klienta"
        }
    }
}

export const processStatusError = (status, exception?: boolean) => {
    // potreba zatvorit modal ak je dispatchnuty
    if (exception) {
        return;
    }

    if (status === 403) {
        window.location.href = "/odhlaseni";
    }
}

export const ifHasActiveLoanPrevention = (activeLoan, user) => {
    if (activeLoan && activeLoan.status === LoanStatus.SentToBankAccount) {
        return toastInfo("Máte již aktívni úvěr, data nebude lze měniť až do jeho splacení.");
    } else if (activeLoan && user.papers_checked) {
        return toastInfo("Vaše doklady již byly schváleny operátorem, další změnu bude možné provést až po splacení úvěru.");
    }
}

export const sortContractsToSign = (contracts: ContractSignObject[] | null): ContractSignObject[] => {
    if (!contracts) {
        return [];
    }

    const pattern: number[] = [2, 0, 1];
    const sortedContracts: ContractSignObject[] = [];

    pattern.map((p, index) => {
        contracts.forEach(item => {
            if (item.type === p) {
                sortedContracts.push(item);
            }
        })
    });

    return sortedContracts;
}

export const sortItemsByDateTime = (data) => {
    return data.sort(function (a, b) {
        return new Date(a.created).getTime().toString().localeCompare(new Date(b.created).getTime().toString());
    }).reverse();
}


export const getSignContractText = (contractType) => {
    switch (contractType) {
        case 2:
            return (`<div>
                    <b>Rámcovou smlouvu podepíšete pouze jednou.</b> Na základě této smlouvy se naše společnost zavazuje, že v případě poctivého splácení, 
                    Vám budeme poskytovat další úvěry a s tím spojené další slevy a produkty. Také jsou v ní jasně uvedeny sankce v případě nesplacení. 
                    <b>Nic netajíme ani neschováváme, vše je v Rámcové smlouvě jasně a čitelně uvedeno!</b>
                    Důkladně si prosím dokument přečtěte a pokud s ním souhlasíte, klikněte na tlačítko 
                    <b>„ SOUHLASÍM A CHCI VYGENEROVAT PODPISOVÝ KÓD “</b> a  vyčkejte na doručení podpisového kódu na váš telefon.
                <div/>`)
        case 0:
            return (`<div>
                        Tato dohoda jasně říká, že v případě nesplacení úvěru bude rozhodnuto <b>podle českého práva</b> v rozhodčím řízení.                 
                        Svůj podpis a tím i souhlas s Dohodou potvrzujete vložením podpisového kódu.
                        Dohodu o řešení sporu <b>prosím podepište stejným podpisovým kódem</b>, kterým jste podepsal(a) Rámcovou smlouvu.
                    </div>`)
        case 1:
            return (`<div>
                        <b>Nyní prosím podepište Smlouvu o úvěru</b>, ve které je uvedeno vše, na čem jsme se společně dohodli. Překontrolujte si prosím všechny údaje, především doby splatností a celkové částky k úhradě.
                        Pokud je vše v pořádku a s podmínkami smlouvy souhlasíte – použijte tlačítko: <b>„ SOUHLASÍM A CHCI VYGENEROVAT PODPISOVÝ KÓD “</b> a vyčkejte na doručení podpisového kódu na váš telefon.
                    </div`)
        default:
            return (`<div>
                        Děkujeme za podpis dokumentu a uzavření obchodu. Věříme, že dodržíte podmínky naší Dohody. 
                        Po stisknutí tlačítka – POKRAČOVAT Vám <b>budou odeslány finance na Váš účet</b>.
                        Děkujeme, že využíváte naše finanční služby a doufáme, že se na nás v budoucnu opět obrátíte.
                    </div>`)
    }
}

export const excerpt = (data: string) => {
    return data.length > 10 ? data.substring(0, 9) : data
}

export const throwAlertOnPaymentStatus = (paymentStatus: string): void => {
   const paymentStatusAlerts = {
       pending: () => toastInfo("Vaše platba čeká na spracování!"),
       paid: () => toastSuccess("Vaše platba proběhla úspěšně!"),
       cancelled: () =>  toastError("Vaše platba sa nezdařila!")
   }

   return paymentStatusAlerts[paymentStatus.toLowerCase()]?.() ?? toastInfo("Vaše platba je mimo naši galaxii, opakujte znovu!");
}


export const getBirthDateObject = (birthNumber: string) => {
    const birthDate = {
       year: parseInt(birthNumber.substr(0, 2), 10),
       month: parseInt(birthNumber.substr(2, 2), 10),
       day: parseInt( birthNumber.substr(4, 2), 10)
    }


    // druhe dve pozicie znacia mesiac - u zien nutne odratat 50
    if (birthDate.month > 12) {
        return {
            ...birthDate,
            month: birthDate.month - 50
        }
    }

    return birthDate;
};

export const processToastErrorFromResponse = (err) => {
    const attribute = Object.keys(err.response?.data)[0].toString();
        toastError(err.response.data[attribute][0]);
}

export const processNotification = (showUserPaperNotification, hasActiveLoan, user, dispatch) => {
    if (showUserPaperNotification) {
        ifHasActiveLoanPrevention(hasActiveLoan, user);
        dispatch(LogicAction.setUserPaperNotification(!showUserPaperNotification));
    }
}