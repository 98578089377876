import React, {useEffect, useRef, useState} from 'react';
import PageHeading from "components/global/PageHeading";
import FormWrap from "pages/PersonalData/components/FormWrap";
import {useForm} from "react-hook-form";
import FormPart from "pages/PersonalData/components/FormPart";
import FormInput from "pages/PersonalData/components/FormInput";
import PurpleButton from "components/buttons/PurpleButton";
import FormRow from "pages/PersonalData/components/FormRow";
import FormColumn from "pages/PersonalData/components/FormColumn";
import CheckBox from "components/inputElement/CheckBox";
import {useTypedSelector} from "helpers/reducers";
import Loader from "components/loader/Loader";
import SessionAction from "stores/user/SessionAction";
import {useDispatch} from "useDispatch";
import * as yup from "yup";
import {getBirthDateFromBirthNumber, ifHasActiveLoanPrevention, processNotification} from "helpers/utility";
import {toastInfo} from "helpers/toasts";
import useUpdatePersonData from "helpers/hooks/useUpdatePersonalData";

interface Props {}

const PersonalData = (props: Props) => {
    const dispatch = useDispatch();
    const user = useTypedSelector(state => state.session.user);
    const showUserPaperNotification = useTypedSelector(state => state.logic.showUserPaperNotification)
    const hasActiveLoan = useTypedSelector(state => state.loan.activeLoan) !== null;
    const [isDisabled, setIsDisabled] = useState<boolean>(user.papers_checked);

    const defaultValues = user ? {
        ...user,
        company_name: user.business.name,
        birth_date: getBirthDateFromBirthNumber(user.birth_number),
        birth_number: user.birth_number,
        // gdpr: user.agreement_personal_data
    } : {}

    const personalDataSchema = yup.object().shape({
        company_name: yup.string(),
        first_name: yup.string(),
        last_name: yup.string(),
        birth_number: yup.string(),
        birth_date: yup.string(),
        id_number: yup.string(),
        email: yup.string().required("Toto pole je povinné!"),
        bank_account: yup.string(),
        phone: yup.string().required("Toto pole je povinné!"),
        // gdpr: yup.boolean()
    });

    const form = useUpdatePersonData(defaultValues, personalDataSchema, defaultValues, isDisabled, setIsDisabled);

    useEffect(() => {
        processNotification(showUserPaperNotification, hasActiveLoan, user, dispatch);
    }, []);

    const onSubmitHandler = data => {
        // can edit only if new paper was uploaded which hadn't been checked
        if (isDisabled) {
            // setIsDisabled(true);
            // toastInfo("Už máte aktivní úvěr, sorry jako...");
            return;
        }

        dispatch(SessionAction.updatePersonalData(data));
    };

    if (!user) {
        return <Loader/>
    }

    return (
        <div className="PersonalData pb-5">
            <PageHeading title={"Osobní údaje"}/>
            <FormWrap onSubmit={form.handleSubmit(onSubmitHandler)}>
                <FormPart title={"Osobní údaje"}>
                    <FormInput error={form.errors.company_name} title={"Obchodní jméno"} register={form.register} name={"company_name"} disabled/>
                    <FormRow>
                        <FormColumn>
                            <FormInput error={form.errors.first_name} title={"Křestní jméno"} register={form.register} name={"first_name"} disabled/>
                            <FormInput error={form.errors.birth_number} title={"Rodné číslo"} register={form.register} name={"birth_number"} disabled/>
                            <FormInput error={form.errors.id_number} title={"Číslo ob. průkazu"} register={form.register} name={"id_number"} disabled/>
                        </FormColumn>
                        <FormColumn>
                            <FormInput error={form.errors.last_name} title={"Příjmení"} register={form.register} name={"last_name"} disabled/>
                            <FormInput error={form.errors.birth_date} title={"Datum narození"} register={form.register} name={"birth_date"} disabled/>
                        </FormColumn>
                    </FormRow>
                </FormPart>
                <FormPart title={"Kontakt"}>
                    <FormRow>
                        <FormColumn>
                            <FormInput error={form.errors.email} title={"E-mail"} register={form.register} name={"email"} disabled={isDisabled}/>
                        </FormColumn>
                        <FormColumn>
                            <FormInput error={form.errors.phone} title={"Telefon"} register={form.register} name={"phone"} disabled={isDisabled}/>
                        </FormColumn>
                    </FormRow>
                    <FormRow>
                        <div className="loanSummary-info address-info d-flex mt-1 w-100">
                            <img src={""} className="notification-icon" alt=""/>
                            <p className="notification-text mb-3">
                                Pokud si nyní změníte Vaši e-mailovou adresu,
                                změníte si tím i své přihlašovací jméno do Klientského účtu,
                                Heslo Vám zůstane stejné !
                            </p>
                        </div>
                    </FormRow>
                    <FormRow>
                        <FormColumn>
                            <FormInput error={form.errors.bank_account} title={"Číslo bank. účtu"} register={form.register} name={"bank_account"} disabled/>
                        </FormColumn>
                    </FormRow>
                    <FormRow>
                        <div className="GDPR d-flex">
                            <div className="d-flex">
                                <CheckBox name={"agreement_personal_data"} register={form.register} disabled={user.agreement_personal_data}/>
                                <p className="ml-3">GDPR - Souhlas se zpracováním mých osobních údajů pro marketingové účely. Nesouhlasím s tím,
                                    aby společnost VistaCredit s.r.o. poskytla mé osobní údaje jiné společnosti za účelem reklamních
                                    sdělení a obchodních nabídek. Souhlasím s tím, aby mi byly zasílány emailem a SMS zprávou informace
                                    o chystaných akcích např. ÚVĚR ZDARMA apod:</p>
                            </div>
                        </div>
                    </FormRow>
                </FormPart>

                <PurpleButton type={"submit"}
                              class="btn btn-purple center-button"
                              disabled={hasActiveLoan}>
                    Uložit změny
                </PurpleButton>
            </FormWrap>
        </div>
    );
};

export default PersonalData;