import React from 'react';
import {GetActiveLoanResponse} from "types/Responses/getActiveLoanResponse";
import {formatCurrency, getDate, getDateTime} from "helpers/utility";
import {useDispatch} from "useDispatch";
import {closeModal, openModal} from "stores/action";
import LoanDetail from "pages/loanHistory/components/LoanDetail";
import LoanAction from "stores/loan/LoanAction";
import LoanCard from "pages/activeLoan/components/LoanCard";
import {toastInfo} from "helpers/toasts";

interface Props {
    loan: GetActiveLoanResponse
}

const LoanCardMobile = (props: Props) => {
    const dispatch = useDispatch();
    const className = "ModalLoanDetail";

    const openLoanDetail = () => {
        dispatch(LoanAction.fetchLoanDetail(props.loan.id)).then(r => {
            if (!r) {
                dispatch(closeModal());
                toastInfo("Je nám líto, ale momentálně nelze načíst detail úvěru. Skuste to prosím později.");
                return;
            }

            dispatch(openModal(<LoanDetail loan={props.loan}/>, {className}));
        });
    }

    if (!props.loan) {
        return <div>neni loan</div>
    }

    return (
        <div className="LoanCardMobile">
            <table className="table">
                <tbody>
                    <tr>
                        <td className={"pl-0"}>
                           <div>
                               <span className="card__label">Jistina</span>
                               <span className="card__numbers">{formatCurrency(props.loan.amount)} Kč</span>
                           </div>
                        </td>
                        <td className={"text-right pr-0"}>
                            <div>
                                <span className="card__label">Poplatek</span>
                                <span className="card__numbers">{formatCurrency(props.loan.fee)} Kč</span>
                            </div>
                            <div className={"mt-2"}>
                                <span className="card__label">Ke splacení</span>
                                <span className="card__numbers blue-text cursor-pointer text-underline" onClick={openLoanDetail}><b>{formatCurrency(props.loan.sum)} Kč</b></span>
                            </div>

                        </td>

                    </tr>
                    <tr>
                        <td className={"pl-0"}>
                            <span className="card__label">Po splatnosti</span>
                            <span className="card__numbers card__paymentDate-status">{props.loan.overdue} dnů</span>
                        </td>
                        <td className={"text-right pr-0"}>
                            <span className="card__label">Doba poskytnutí</span>
                            <span className="card__numbers">{props.loan.period} dnů</span>
                        </td>
                    </tr>
                    <tr>
                        <td className={"pl-0"}>
                            <span className="card__label">Datum žádosti</span>
                            <span className="card__numbers">{getDateTime(props.loan.created)}</span>
                        </td>
                        <td className={"text-right pr-0"}>
                            <span className="card__label">Splatnost úvěru</span>
                            <span className="card__numbers"><b>{getDate(props.loan.due)}</b></span>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
};

export default LoanCardMobile;