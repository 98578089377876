import React, {useEffect, useState} from 'react';
import clsx from "clsx";
import WhiteCheck from "assets/icons/whiteCheck.svg";

interface Props {
    number: number,
    text: string,
    contract: any,
    contracts: any[],
    step: number,
    className?: string,
}

const SignContractStep = (props: Props) => {
    const isActive = props.contracts[props.step] === props.contract;
    const [isSigned, setIsSigned] = useState(props.contract?.signed)

    useEffect(() => {
        setIsSigned(props.contract?.signed);
    }, [props.contract, props.step]);

    return (
        <div className={clsx("SignContractStep",
            props.contract?.signed === null && isActive && "SignContract__active",
            isSigned && "SignContract__signed",
            props.className)}>
            <span className={"SignContract--number d-block mx-auto"}>{
               isSigned ? <img src={WhiteCheck} alt=""/> : props.number
            }</span>
            <p className={"SignContract--text"}>{props.text}</p>
        </div>
    );
};

export default SignContractStep;