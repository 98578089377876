import React, {useEffect, useState} from 'react';
import UploadCheck from "assets/icons/uploadCheck.svg";
// import UploadDelete from "assets/icons/uploadDelete.svg";
import Delete from "assets/icons/delete.svg";
import UploadDirectory from "assets/icons/uploadDirectory.svg";
import {excerpt} from "helpers/utility";
import PurpleButton from "components/buttons/PurpleButton";
import useWindowDimensions from "helpers/hooks/useWindowDimensions";

interface Props {
    file: any,
    upload: () => void,
    discard: () => void
}

const QueueFile = (props: Props) => {
    const {height, width} = useWindowDimensions();
    const [showMobileLayout, setShowMobileLayout] = useState(false);

    useEffect(() => {
        setShowMobileLayout(width < 900);
    }, [width])

    return (
        <div className="upload-wrap d-flex">
            {!showMobileLayout ? (
                <React.Fragment>
                    <div className="uploaded-file-wrap">
                        <div className="uploaded-file-name">
                            <p className="uploaded-label">Nahraný soubor</p>
                            <p className="uploaded-file-name">{excerpt(props.file.name)}</p>
                        </div>
                        <PurpleButton style={{textTransform: "unset", fontWeight: "bold"}} action={props.upload}>Uložit soubor</PurpleButton>
                    </div>
                    <img src={Delete} className="uploaded-icon-delete" onClick={props.discard} alt=""/>
                </React.Fragment>
            ) : (
                <div className={"d-flex flex-column w-100"}>
                    <div className="uploaded-file-wrap w-100" style={{maxWidth: "100%"}}>
                        <div className="uploaded-file-name">
                            <p className="uploaded-label">Nahraný soubor</p>
                            <p className="uploaded-file-name">{excerpt(props.file.name)}</p>
                        </div>
                    </div>
                    <div className={"d-flex justify-content-between mt-3 w-100"}>
                        <PurpleButton style={{textTransform: "unset", fontWeight: "bold", left: "0", top: "0"}} action={props.upload}>Uložit soubor</PurpleButton>
                        <img src={Delete} style={{top: "10px"}} className="uploaded-icon-delete" onClick={props.discard} alt=""/>
                    </div>
                </div>
            )}
        </div>
    );
};

export default QueueFile;