import React, {useEffect, useState} from 'react';
import { Viewer, Worker } from '@react-pdf-viewer/core';

import '@react-pdf-viewer/core/lib/styles/index.css';
import useWindowDimensions from "helpers/hooks/useWindowDimensions";

interface Props {
    contract: any
}

const ContractPDF = (props: Props) => {
    const {height, width} = useWindowDimensions();
    const [scale, setScale] = useState(1);

    useEffect(() => {
        setScale(1.2)
    }, [width]);

    return (
        <div className={"document-pdf"}>
            <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.js">
                <div className={'height'} style={{ height: '450px' }}>
                    <Viewer
                        fileUrl={props.contract?.url.replace("http", "https")}
                        defaultScale={scale}
                    />
                </div>
            </Worker>
            {/*<PDFObject id="framework_agreement" url={props.contract?.url.replace("http", "https")} width="100%" height="600"/>*/}
        </div>
    );
};

export default ContractPDF;