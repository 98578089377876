import React from 'react';
import {Line} from "react-chartjs-2";
import {dateParse, getMonthFromDate} from "helpers/utility";
import {Bonus} from "types/entities";

interface Props {
    list: Bonus[]
}

const BonusesChart = (props: Props) => {
    const labels = props.list.map(bonus => dateParse(bonus.created));
    let bonusData = props.list.map(bonus => bonus.amount);
    let sum;
    bonusData = bonusData.map(elem => sum = (sum || 0) + elem);

    const data = {
        labels: labels.reverse(),
        datasets: [{
            label: 'Počet bodů',
            data: bonusData,
            fill: false,
            borderColor: '#B800F5',
            backgroundColor: '#fff',
            tension: 0.1
        }],
    };

    return (
        <div className="BonusesChart mb-5">
            <Line data={data}/>
        </div>
    );
};

export default BonusesChart;