import React, {useEffect, useState} from 'react';
import Background from "assets/icons/background.svg";
import {Col, Container, Row} from 'react-bootstrap';
import Navbar from "components/navbar/Navbar";
import {useTypedSelector} from "helpers/reducers";
import Loader from "components/loader/Loader";
import {RouteComponentProps, withRouter } from 'react-router';
import SideNav from "components/navbar/sidebarNav/SideNav";
import SideDrawer from "components/navbar/sidebarNav/SideDrawer";
import Modal from "helpers/Modal";
import {useDispatch} from "useDispatch";
import clsx from "clsx";
import Home from 'assets/icons/mobileNav/home.svg';
import Finance from 'assets/icons/mobileNav/finance.svg';
import User from 'assets/icons/mobileNav/user.svg';
import MobileNav from "components/navbar/mobile/MobileNav";
import MobileNavItem from "components/navbar/mobile/components/MobileNavItem";
import NewLoanItem from "components/navbar/mobile/components/NewLoanItem";
import Logo from "components/logo/Logo";
import DrawerToggler from "components/drawerToggler/DrawerToggler";
import Logout from 'assets/icons/logout.svg';
import Info from 'assets/icons/info.svg';
import useWindowDimensions from "helpers/hooks/useWindowDimensions";
import {MOBILE_BREAKPOINT} from "helpers/utility";
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

import "intro.js/introjs.css";
import ReactIntroJS from "layouts/components/ReactIntroJS";
import {useReactIntroJS} from "helpers/hooks/useReactIntroJS";
import {STEPS} from "layouts/components/Preset";

interface Params {}

interface ClientLayoutProps extends RouteComponentProps<Params> {
    children: React.ReactNode | React.ReactNode[]
}

const ClientLayout = (props: ClientLayoutProps) => {
    const session = useTypedSelector(state => state.session);
    const loan = useTypedSelector(state => state.loan);
    const [openNav, setOpenNav] = useState(false);
    const {height, width} = useWindowDimensions();
    const mobile = width < MOBILE_BREAKPOINT;

    const introJS = useReactIntroJS(STEPS, []);


    const logout = () => {
        props.history.replace('/odhlaseni');
    }

    if (!loan.bonuses || !loan.contracts || !loan.documents) {
        return <Loader/>
    }

    return (
        <div className={clsx("ClientLayout", session.token ? "bg-logged" : "bg-unlogged")}>


            <ReactIntroJS introJS={introJS}/>

            <img src={Background} className="ClientLayout__background-pattern" alt=""/>
            <Container fluid className="ClientContent mobileSettings">
                {<SideDrawer openNav={openNav} setOpenNav={setOpenNav}/>}
                {session.token ? (
                    <React.Fragment>
                        {mobile ? (
                          <div className={"mobile-wrapp"}>
                              <Container fluid className="mobile-header d-flex justify-content-between p-0 py-4">
                                  <img src={Info} alt=""/>
                                  <Logo/>
                                  <img src={Logout} alt="" onClick={logout} className={"cursor-pointer"}/>
                              </Container>
                              <div className="SideNav DesktopOnly">
                                  <SideNav setOpenNav={setOpenNav} joyRideStart={introJS.toggleSteps} />
                              </div>
                              <main className="main-content pr-lg-4">
                                  {props.children}
                              </main>
                              <MobileNav>
                                  <MobileNavItem route={"/"} col={2} image={Home} tabTitle={"Domů"}/>
                                  <MobileNavItem route={"/historie-uveru"} col={2} image={Finance} tabTitle={"Finance"}/>
                                  <NewLoanItem route={"/zadost-o-uver"} col={3} tabTitle={"Nový Úvěr"}/>
                                  <MobileNavItem route={"/osobni-udaje"} col={2} image={User} tabTitle={"Užívatel"}/>
                                  <Col xs={2}>
                                    <DrawerToggler setOpenNav={setOpenNav} openNav={openNav} className={"drawerToggler-modified "}/>
                                      <p className={"tabTitle"} style={{marginTop: '-10px'}}>MENU</p>
                                  </Col>
                              </MobileNav>
                          </div>
                        ) : (
                            <React.Fragment>
                                <Navbar setOpenNav={setOpenNav} openNav={openNav}/>
                                <div className="d-flex height-80">
                                    <div className="SideNav  DesktopOnly">
                                        <SideNav setOpenNav={setOpenNav} joyRideStart={introJS.toggleSteps} className={"SideNavDesktopTour"}/>
                                    </div>
                                    <main className="main-content pr-lg-4">
                                        {props.children}
                                    </main>
                                </div>
                            </React.Fragment>
                        )}

                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <Navbar setOpenNav={setOpenNav} openNav={openNav} className={"mt-3"}/>
                        {props.children}
                    </React.Fragment>
                )}
            </Container>
            <ToastContainer theme={"light"}/>
            <Modal/>
        </div>
    );
};

export default withRouter(ClientLayout);