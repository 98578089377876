import React, {useEffect, useRef, useState} from 'react';
import clsx from "clsx";
import {useDispatch} from "useDispatch";
import {PricesItem} from "types/entities/PricesItem";


interface PurposeItemProps {
    deferral: PricesItem,
    register: any,
    activeValue: any
    setActiveValue:  React.Dispatch<any>,
    setFormValue: (value: number) => void
}

const PurposeItem = (props: PurposeItemProps) => {
    const [checked, setChecked] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        +props.activeValue === +props.deferral.days
            ? setChecked(true)
            : setChecked(false);
        console.log('effect')
    },  [props.deferral.days, props.activeValue]);


    const onCheckedHandler = (e) => {
        props.setActiveValue(e.target.value);
        props.setFormValue(props.deferral.amount);
    }

    const onChangeRadioHandler = () => {
        console.log('change')
    }

    return (
        <label
            className={clsx('Purpose-btn', checked && "Purpose-btn__active")}>
            <input
                type="radio"
                name={"days"}
                ref={props.register}
                value={props.deferral.days}
                defaultChecked={props.deferral.days === 7}
                checked={checked}
                onChange={onChangeRadioHandler}
                onClick={onCheckedHandler}
            />
            <input type="hidden" ref={props.register} name={"amount"} value={props.deferral.amount}/>
            <div className="purpose-btn-wrap d-flex justify-content-between w-100">
                <span className="labelText text-left">o {props.deferral.days} dnů, splatnost nastane 21.10.2020</span>
                <span className="labelText text-right flex-shrink-1">za {props.deferral.amount} Kč.</span>
            </div>
        </label>
    );
};

export default PurposeItem;