import {useEffect} from "react";
import LoanAction from "stores/loan/LoanAction";
import SessionAction from "stores/user/SessionAction";
import {useDispatch} from "useDispatch";

export const useFetchGeneralDataInterval = (token: string | null, INTERVAL: number) => {
    const dispatch = useDispatch();

    const fetchGeneralData = () => {
        if (!token) {
            return;
        }

        dispatch(LoanAction.fetchAll());
        dispatch(SessionAction.fetchCustomerPapers());
        dispatch(SessionAction.getUser());
    }

    useEffect(() => {
        fetchGeneralData();
    }, [])

    useEffect(() => {
        const refreshInterval = setInterval(fetchGeneralData, INTERVAL);

        return () => clearInterval(refreshInterval);
    }, []);
};
