import React, {useEffect} from 'react';
import FormWrap from "pages/PersonalData/components/FormWrap";
import FormPart from "pages/PersonalData/components/FormPart";
import FormInput from "pages/PersonalData/components/FormInput";
import WhiteButton from "components/buttons/WhiteButton";
import clsx from "clsx";
import PurpleButton from "components/buttons/PurpleButton";
import {GetActiveLoanResponse} from "types/Responses";
import {watch} from "fs";
import {FieldName, FieldValues, SetFieldValue, SetValueConfig} from "react-hook-form";

interface Props {
    setGoToSelectPaymentMethod: (value: boolean) => void,
    selectQuickAmount: (value: number) => void,
    amountRef: React.MutableRefObject<number>,
    register: any,
    activeLoan: GetActiveLoanResponse,
    setValue: (name, value) => void
    setAmount: (amount) => void
}

const PaymentForm = (props: Props) => {
    const regexp = /^[0-9]*$/;

    const onChangeHandler = (e) => {
        const value = e.target.value;
        if (value.length === 0) {
            props.setValue('amount', 500);
            props.setAmount(500);
        }

        if (!regexp.test(value)) {
            props.setValue('amount', value.replace(/[^\d.]/g, ''))
            props.setAmount(+value.replace(/[^\d.]/g, ''));
        }

        props.setAmount(+value);
    }

    return (
        <div className="PaymentForm">
            <div className={'d-flex'}>
                <h3 className="page__section-title font-size-20 mb-3 text-left">Ke splacení: {props.activeLoan.sum} Kč</h3>
            </div>
            <FormWrap onSubmit={() => {}} className={'FormWrap w-100 max-width-100'}>
                <FormPart className={"d-flex flex-column w-100 justify-content-center align-items-center"}>
                    <FormInput title={"Částka ke splacení v Kč"} register={props.register} name={"amount"} className={"w-100"} onChange={onChangeHandler}/>
                </FormPart>
                <h3 className="page__section-title mt-mob font-size-20 mb-3 text-left">Rychlá volba</h3>
                <div className="FormWrap-buttons__group d-flex justify-content-between">
                    <div className="d-flex">
                        <WhiteButton class={clsx('ml-0', props.amountRef.current === 25 && 'selected-amount')} action={() => props.selectQuickAmount(25)}>25%</WhiteButton>
                        <WhiteButton class={clsx( props.amountRef.current === 50 && 'selected-amount')} action={() => props.selectQuickAmount(50)}>50%</WhiteButton>
                        <WhiteButton class={clsx( props.amountRef.current === 100 && 'selected-amount')} action={() => props.selectQuickAmount(100)}>100%</WhiteButton>
                    </div>
                    <PurpleButton action={() => props.setGoToSelectPaymentMethod(true)} class={"mr-1"}>Zaplatit</PurpleButton>
                </div>
            </FormWrap>
        </div>
    );
};

export default PaymentForm;