import React from 'react';
import clsx from "clsx";

interface Props {
    name: string,
    register: any,
    checked?: boolean
    disabled?: boolean
}

const CheckBox = (props: Props) => {
    return (
        <div>
            <input
                type="checkbox"
                name={props.name}
                ref={props.register}
                disabled={props.disabled}
                // onChange={checkHandler}
                // checked={props.checked}
                id="cbx"/>
            <label className={clsx("cbx",  props.disabled && "opacity-50")} htmlFor={"cbx"}>
                <div className="flip">
                    <div className="front"></div>
                    <div className="back">
                        <svg width="16" height="14" viewBox="0 0 16 14">
                            <path d="M2 8.5L6 12.5L14 1.5"></path>
                        </svg>
                    </div>
                </div>
            </label>
        </div>
    );
};

export default CheckBox;