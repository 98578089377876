import React from 'react';
import PurpleButton from "components/buttons/PurpleButton";
import clsx from "clsx";
import LoginEnvelope from "assets/icons/loginEnvelope.svg";
import LoginLock from "assets/icons/loginLock.svg";
import CheckBox from "components/inputElement/CheckBox";

interface Props {
    onSubmit: () => void,
    register: any,
    error?: any
    onClick: () => void
}

const LoginForm = (props: Props) => {
    return (
        <form onSubmit={props.onSubmit}>
            <div className="form-group customInput-group">
                <img src={LoginEnvelope} className={"customInput-icon"} alt=""/>
                <input type="email" name="email" className={clsx("form-control customInput", props.error.email?.message && 'error-input')} ref={props.register} placeholder="Přihlašovací e-mail:" id="email"/>
                {props.error.email?.message && <label className={"error-label"}>{props.error.email?.message}</label>}
            </div>
            <div className="form-group customInput-group">
                <img src={LoginLock} className={"customInput-icon"} alt=""/>
                <input type="password" name="password" className={clsx("form-control customInput", props.error.password?.message && 'error-input')} ref={props.register} placeholder="Heslo:" id="pwd"/>
                {props.error.password?.message && <label className={"error-label"}>{props.error.password?.message}</label>}

            </div>
            <div onClick={props.onClick} className={clsx("tab tab__forgot_password text-center")}>Zapomněli jste heslo?</div>
            <div className={"d-flex mt-3 mb-3 justify-content-center align-items-center"}>
                <CheckBox name={'remember'} register={props.register}/>
                <span className={"ml-2 mt-2 remember-box"}>Pamatuj si mě</span>
            </div>
            <PurpleButton type="submit" class={"d-block mx-auto"}>Přihlásit se k účtu</PurpleButton>
        </form>
    );
};

export default LoginForm;