import React, {useEffect, useState} from 'react';
import LoanDetailHeader from "pages/loanHistory/components/LoanDetailHeader";
import LoanDetailDeferredTab from "pages/loanHistory/components/LoanDetailDeferredTab";
import clsx from "clsx";
import {Loan} from "types/entities";
import {useTypedSelector} from "helpers/reducers";
import useWindowDimensions from "helpers/hooks/useWindowDimensions";
import {MOBILE_BREAKPOINT} from "helpers/utility";
import {useDispatch} from "useDispatch";
import LoanAction from "stores/loan/LoanAction";
import LoanHistoryInfoTab from "pages/loanHistory/components/LoanHistoryInfoTab";
import LoanDetailToPayTab from "pages/loanHistory/components/LoanDetailToPayTab";
import LoanDetailPaymentsTab from "pages/activeLoan/components/LoanDetailPaymentsTab";

interface Props {
    loan?: Loan
}

export const chartColors = [
    "#FFCC66",
    "#3399FF",
    "#EF6F6C",
    "#9999FF",
    "#ff7c43",
];

const LoanDetail = (props: Props) => {
    const dispatch = useDispatch();
    const [activeTab, setActiveTab] = useState("info");
    const [showNav, setShowNav] = useState(true);
    const loanDetail = useTypedSelector(state => state.loan.loanDetail);
    const [detail, setDetail] = useState(null);
    const {height, width} = useWindowDimensions();
    const isMobile = width < MOBILE_BREAKPOINT;

    useEffect(() => {
        dispatch(LoanAction.fetchLoanDetail(props.loan?.id)).then(r => {
            setDetail(r);
        });
    }, []);

    const setShowNavHandler = () => {
        setShowNav(!showNav);
    }

    return (
        <div className={clsx("LoanDetail", !isMobile && "mt-5")}>
            <LoanDetailHeader
                isMobile={isMobile}
                loan={loanDetail}
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                showNav={showNav}
                setShowNav={setShowNavHandler}/>
            {activeTab === "info" && <LoanHistoryInfoTab loan={detail}/>}
            {activeTab === "toPay" && <LoanDetailToPayTab loanDetail={detail} isMobile={isMobile}/>}
            {activeTab === "payments" && <LoanDetailPaymentsTab loanDetail={detail} isMobile={isMobile}/>}
            {activeTab === "deferred" && <LoanDetailDeferredTab loanDetail={detail} isMobile={isMobile}/>}
        </div>
    );
};

export default LoanDetail;