import React from 'react';
import Loader from "components/loader/Loader";
import {Col, Row} from "react-bootstrap";
import Table from "components/Table/Table";
import TableHead from "components/Table/components/TableHead";
import TableRow from "components/Table/components/TableRow";
import TableHeadItem from "components/Table/components/TableHeadItem";
import TableBody from "components/Table/components/TableBody";
import TableItem from "components/Table/components/TableItem";
import {formatCurrency, getDate, getDateTime} from "helpers/utility";
import {Loan} from "types/entities";
import Chart from "pages/loanHistory/components/Chart";

interface Props {
    loan: Loan
}

const LoanHistoryInfoTab = (props: Props) => {
    if (!props.loan) {
        return <Loader/>;
    }

    return (
        <Row className={"tabContent mt-4"}>
            <Col lg={4}>
                <Chart loan={props.loan} />
            </Col>
            <Col lg={8} className="text-left">
                <h5>Informace o mém úvěru:</h5>
                <div>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableHeadItem>JISTINA</TableHeadItem>
                                <TableHeadItem>POPLATEK</TableHeadItem>
                                <TableHeadItem>DOBA POSKYTNUTÍ</TableHeadItem>
                                <TableHeadItem>BONUS</TableHeadItem>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableItem>{formatCurrency(props.loan.amount)} Kč</TableItem>
                                <TableItem>{formatCurrency(props.loan.fee)} Kč</TableItem>
                                <TableItem>{props.loan.period} dnů</TableItem>
                                <TableItem>{props.loan.points} bodů</TableItem>
                            </TableRow>
                        </TableBody>
                    </Table>
                </div>
                <div className={"mt-5"}>
                    <h5>Od kdy - do kdy jsem si půjčil:</h5>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableHeadItem>DATUM ŽÁDOSTI</TableHeadItem>
                                <TableHeadItem>SPLATNOST ÚVĚRU</TableHeadItem>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <td>{getDateTime(props.loan.created)}</td>
                                <td>{getDate(props.loan.due)}</td>
                            </TableRow>
                        </TableBody>
                    </Table>
                </div>
            </Col>
        </Row>
    );
};

export default LoanHistoryInfoTab;