import React, {useState} from 'react';
import {useForm} from "react-hook-form";
import SessionAction from "stores/user/SessionAction";
import {withRouter} from 'react-router-dom';
import { useDispatch } from 'useDispatch';
import {Container} from "react-bootstrap";
import LoginForm from "pages/user/components/LoginForm";
import LoginMaskot from "assets/icons/loginMaskot.svg";
import ForgotPasswordForm from "pages/user/components/ForgotPasswordForm";
import {openModal} from "stores/action";
import FormError from "pages/user/components/FormError";
import * as yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import LoanAction from "stores/loan/LoanAction";
import {toast} from "react-toastify";
import {toastError, toastSuccess} from "helpers/toasts";

type LoginFormSchema = {
    email: string,
    password: string
}

const Login = (props: any) => {
    const formSchema = yup.object().shape({
        email: yup.string().required("Prosím vyplňte svůj e-mail."),
        password: yup.string().required("Prosím vyplňte svojo heslo.")
    })

    const {register, handleSubmit, watch, setValue, reset, errors} = useForm<LoginFormSchema>({
        resolver: yupResolver(formSchema)
    });

    const dispatch = useDispatch();
    const [isActiveTab, setIsActiveTab] = useState<string>('login');
    const disableClose = false;
    const [error, setError] = useState<{type: string, message: string}>({
        type: "Přihlášení se nezdařilo",
        message: "Špatné přihlašovací údaje.",
    });

    const errorModal = () => dispatch(openModal(<FormError error={error} setActiveTab={setIsActiveTab}/>, {disableClose}))

    const onSubmitLoginHandler = (data: any) => {
        dispatch(SessionAction.authLogin(data)).then(r => {
            if (r.message) {
                return;
            }

            dispatch(SessionAction.getUser()).then(r => {
                dispatch(LoanAction.fetchAll(true));

                dispatch(LoanAction.fetchActiveLoan()).then(r => {
                    if (r) {
                        dispatch(LoanAction.fetchDeferralOrder(r.id));
                        dispatch(LoanAction.fetchAllContractsToSign());
                    }
                });

                dispatch(SessionAction.fetchCustomerPapers());
                toastSuccess("Přihlášeno, Jupííííí!");
            });

        }).catch(error => {
            toastError(error.message);
            errorModal();
        });
    };

    const onChangeActiveTab = (activeValue: string) => {
        setIsActiveTab(activeValue);
    };

    return (
        <Container className="Login-root">
            <div className="login__wrapper drop-shadow">
                <img src={LoginMaskot} alt="Theo keys" className={'d-block mx-auto mb-4 drop-shadow theoKeys'}/>
                <h3 className="loginHeading">Přihlášení do účtu klienta</h3>
                {isActiveTab === 'login'
                    ? (
                        <LoginForm onSubmit={handleSubmit(onSubmitLoginHandler)} error={errors} register={register} onClick={() => onChangeActiveTab('forgotten-password')}/>
                    ) : (
                        <ForgotPasswordForm setActiveTab={setIsActiveTab} onClick={() => onChangeActiveTab('forgotten-password')}/>
                    )}

            </div>
        </Container>
    );
};

export default withRouter(Login);