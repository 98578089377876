import React from 'react';
import MobileTableItemDocuments from "pages/documents/components/MobileTableItemDocuments";
import Loader from "components/loader/Loader";

interface Props {
    list: any[]
}

const MobileTablePostponements = (props: Props) => {
    if (!props.list) {
        return <Loader/>
    }

    return (
        <div className="LoanHistoryTableMobile scrollBar-hidden">
            <div className={"mobile-table"}>
                {props.list.map(document =>
                    <MobileTableItemDocuments key={document.id} document={document} />
                )}
            </div>
        </div>
    );
};

export default MobileTablePostponements;