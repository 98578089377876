import React from 'react';
import clsx from "clsx";

interface drawerToggler {
    setOpenNav: any,
    openNav: boolean,
    className?: string,
}

const DrawerrToggler = (props: drawerToggler) => {

    const toggleDrawerToggleHandler = () => {
        props.setOpenNav(!props.openNav);
    };

    return (
        <div className={clsx("DrawerToggler", props.openNav ? "open scrollBar-hidden" : "", props.className)} onClick={toggleDrawerToggleHandler}>
            <div></div>
            <div></div>
            <div></div>
        </div>
    );
};

export default DrawerrToggler;