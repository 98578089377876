import React, {useEffect, useState} from 'react';
import NavItem from "components/navbar/navItems/NavItem";

const NavItems = (props: any) => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [collapse, setCollapse] = useState('');
    const [show, setShow] = useState(false);

    const updateWidthAndHeight = () => {
        setWindowWidth(window.innerWidth);
    };
    useEffect(() => {
        window.addEventListener("resize", updateWidthAndHeight);
        setShow(windowWidth <= 992);
    }, [windowWidth]);

    const showHandler = () => {
       return show ? setCollapse('showCollapse') : null;
    };
    useEffect(() => {
        if (props.onClose) {
            setCollapse('');
        }

    }, [props.onClose]);

    return (
        <ul className="nav d-flex justify-content-end">
            <div onClick={props.onClose}>
                <NavItem link="/kontakt">Kontakt</NavItem>
            </div>
            <li className={"nav-link GDPR  mr-4 " + collapse} onClick={showHandler}>
                GDPR
                <div className="submenu">
                    <ul className="gdpr-documents">
                        <li onClick={props.onClose}>
                            <a href="/static/web/pdf/informace_ke_zpracovani_osobnich_dat.pdf" target="_blank" rel="noopener noreferrer">Informace ke zpracování osobních dat</a>
                        </li>
                        <li onClick={props.onClose}>
                            <a href="/static/web/pdf/zadost_o_prenos_osobnich_udaju.pdf" target="_blank" rel="noopener noreferrer">Žádost o přenos osobních údajů</a>
                        </li>
                        <li onClick={props.onClose}>
                            <a href="/static/web/pdf/vymaz_osobnich_udaju.pdf" target="_blank" rel="noopener noreferrer">Výmaz osobních údajů</a>
                        </li>
                    </ul>
                </div>
            </li>
        </ul>
    );
};

export default NavItems;