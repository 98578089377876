import React from 'react';
import {formatCurrency, getDateTime} from "helpers/utility";
import {Loan} from "types/entities";

interface Props {
    loan: Loan,
    onClick: (detail: Loan) => void
}

const LoanHistoryTableItemMobile = (props: Props) => {
    return (
        <div className={"LoanHistoryTableItemMobile d-flex justify-content-between"} onClick={() => props.onClick(props.loan)}>
            <div className={"cursor-pointer"} onClick={() => props.onClick(props.loan)}>
                <div><span className={"cursor-link"}>{props.loan.contract_number}</span> - {formatCurrency(props.loan.amount)} Kč</div>
                <div>{getDateTime(props.loan.created)}</div>
            </div>
            <div>
                <div className={"loanType-marketing"}>{props.loan.type_text}</div>
            </div>
        </div>
    );
};

export default LoanHistoryTableItemMobile;