import React from 'react';
import TableHead from "components/Table/components/TableHead";
import TableRow from "components/Table/components/TableRow";
import TableHeadItem from "components/Table/components/TableHeadItem";
import TableBody from "components/Table/components/TableBody";
import TableItem from "components/Table/components/TableItem";
import Table from "components/Table/Table";
import {Loan} from "types/entities";
import {dateParse} from "helpers/utility";
import {ROOT_API} from "helpers/config";
import Download from "assets/icons/download.svg";
import clsx from "clsx";

interface Props {
    loanDetail: Loan
    isMobile: boolean
}

const LoanDetailDeferredTab = (props: Props) => {
    return (
        <div className={"text-left mt-3"}>
            <h5>Přehled odkladú, o které jsem zažádal a zaplatil:</h5>
            {props.loanDetail.deferral_payments.length === 0 ? (<div className={"mt-3"}><h5>...prozatím jste nevyužil odložení splatnosti úvěru.</h5></div>) : (
                <div className={clsx((props.loanDetail.payments?.length > 6 && !props.isMobile) && "tableBodyRestriction")}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableHeadItem>ID ÚVĚRU</TableHeadItem>
                                <TableHeadItem className={"text-center"}>TYP</TableHeadItem>
                                <TableHeadItem className={"text-center"}>DATUM ŽÁDOSTI</TableHeadItem>
                                <TableHeadItem className={"text-center"}>JISTINA</TableHeadItem>
                                <TableHeadItem className={"text-center"}>ZDROJ</TableHeadItem>
                                <TableHeadItem className={"text-center"}>PDF</TableHeadItem>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {props.loanDetail.deferral_payments?.map(payment => (
                                <TableRow>
                                    <TableItem>{props.loanDetail.id}</TableItem>
                                    <TableItem className={"text-center"}>{payment.payment_type}</TableItem>
                                    <TableItem className={"text-center"}>{dateParse(payment.date)}</TableItem>
                                    <TableItem className={"text-center"}>{payment.amount}</TableItem>
                                    <TableItem className={"text-center"}>{payment.source}</TableItem>
                                    <TableItem className={"text-center"}>
                                        <div className="d-flex justify-content-center align-items-center">
                                            <a rel={"noreferrer"} href={ROOT_API + payment.doc_url} target={"_blank"} download>
                                                <img src={Download} alt="download icon"/>
                                                <span className={"url"}>PDF</span>
                                            </a>
                                        </div>
                                    </TableItem>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </div>
            )}
        </div>

    );
};

export default LoanDetailDeferredTab;