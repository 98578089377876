import React from 'react';

const ErrorPage = (props: any) => {
    return (
        <div className="404">
            404 not found
        </div>
    );
};

export default ErrorPage;