import React from 'react';
import MobileTableItemBonuses from "pages/bonuses/components/MobileTableItemBonuses";
import {Bonus} from "types/entities";

interface Props {
    list: Bonus[]
}

const MobileTableBonuses = (props: Props) => {
    return (
        <div className="LoanHistoryTableMobile scrollBar-hidden">
            <div className={"mobile-table"}>
                {props.list.map(bonus =>
                    <MobileTableItemBonuses key={bonus.order} bonus={bonus}/>
                )}
            </div>
        </div>
    );
};

export default MobileTableBonuses;