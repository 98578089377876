import React from 'react';
import { RouteComponentProps, withRouter} from 'react-router';
import arrow from "assets/icons/arrow-drop.svg"
import {GetActiveLoanResponse} from "types/Responses/getActiveLoanResponse";
import clsx from "clsx";
import {useLocation} from "react-router-dom";

interface Params {}

interface SideNavItemProps extends RouteComponentProps<Params>{
    children: React.ReactNode | React.ReactNode[],
    className?: string,
    path?: any;
    img?: boolean,
    redirect?: boolean;
    status?: boolean,
    open?: any,
    setOpen?: any,
    setOpenNav: any,
    id?: string,
    loanType?: string | null,
}

const SideNavItem = (props: SideNavItemProps) => {
    const location = useLocation();

    const redirectHandler = () => {
        if (props.redirect) {
            props.setOpenNav(false);
            props.history.push(props.path);

            if (props.open.id !== "") {
                props.setOpen({
                    ...props.open,
                    action: false,
                    id: ""
                });
            }
        } else {
            props.setOpen({
                ...props.open,
                action: true,
                id: props.id
            });
        }
    };

    return (
        <div className={clsx("d-flex justify-content-between", props.path === location.pathname && "blue")}>
            <div
                onClick={redirectHandler}
                className={props.className}>
                {props.children}
            </div>
            {props.img && <img src={arrow} className="arrowdrop" alt=""/>}
            {props.status && <span className="loanStatus">{props.loanType}</span>}
        </div>

    );
};

export default withRouter(SideNavItem);