import React from 'react';
import PaymentCard from "assets/icons/paymentOptions/payment-card.svg";
import PaymentCardWhite from "assets/icons/paymentOptions/payment-card-white.svg";
import PaymentGate from "assets/icons/paymentOptions/payment-gate.svg";
import PaymentGateWhite from "assets/icons/paymentOptions/payment-gate-white.svg";
import PaymentQR from "assets/icons/paymentOptions/payment-qr.svg";
import PaymentQRWhite from "assets/icons/paymentOptions/payment-qr-white.svg";
import Separator from "assets/icons/separator.svg";
import PaymentOther from "assets/icons/paymentOptions/payment-other.svg";
import PaymentOtherWhite from "assets/icons/paymentOptions/payment-other-white.svg";
import PaymentMethod from "pages/activeLoan/components/PaymentMethod";

interface Props {
    payByCard: () => void,
    payByTransfer: () => void,
    payByQR: () => void,
    sendViaEmail: () => void
}

const PaymentMethodForm = (props: Props) => {
    return (
        <div className="PaymentMethodForm PaymentOptions-root">
            <div className="payment-wrap d-flex flex-wrap justify-content-center">
                <PaymentMethod imgBlue={PaymentCard} imgWhite={PaymentCardWhite} title={<>Platba kartou</>} onClick={props.payByCard}/>
                <PaymentMethod imgBlue={PaymentGate} imgWhite={PaymentGateWhite} title={<>Platba převodem</>} onClick={props.payByTransfer}/>
                <PaymentMethod imgBlue={PaymentQR} imgWhite={PaymentQRWhite} title={<>QR platba</>} onClick={props.payByQR}/>
                <div className="separator">
                    <img src={Separator} alt=""/>
                </div>
                <PaymentMethod imgBlue={PaymentOther} imgWhite={PaymentOtherWhite} title={<>Odeslat informace <br/> o platbě na email</>} onClick={props.sendViaEmail}/>
            </div>
        </div>
    );
};

export default PaymentMethodForm;