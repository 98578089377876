import React from 'react';
import WhiteButton from "components/buttons/WhiteButton";
import PurpleButton from "components/buttons/PurpleButton";
import {useDispatch} from "useDispatch";
import LoanAction from "stores/loan/LoanAction";
import {closeModal} from "stores/action";
import {toastSuccess} from "helpers/toasts";

interface Props {
    order_id: number
}

const CancelDeferralOrderModal = (props: Props) => {
    const dispatch = useDispatch();

    const cancelDeferralOrder = () => {
        dispatch(LoanAction.cancelDeferralOrder(props.order_id));
        toastSuccess("Odklad byl úspěšně zrušen.");
        dispatch(closeModal());
    }


    return (
        <div className="CancelDeferralOrderModal">
            <div className="LoanPaymentModal ">
                <h3 className="page__section-title mb-3" style={{position: 'relative', top: '-20px'}}>Opravdu si přejete stornovat odklad splatnosti?</h3>
                <div className={"d-flex justify-content-center"}>
                    <WhiteButton action={() => dispatch(closeModal())}>Ne, odklad chci</WhiteButton>
                    <PurpleButton
                        action={cancelDeferralOrder}>
                        Stornovat odklad
                    </PurpleButton>
                </div>
            </div>
        </div>
    );
};

export default CancelDeferralOrderModal;