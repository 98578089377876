import React, {useEffect, useState} from 'react';
import PageHeading from "components/global/PageHeading";
import PurpleButton from "components/buttons/PurpleButton";
import BlueArrow from "assets/icons/blueArrow.svg";
import {useForm} from "react-hook-form";
import FormWrap from "pages/PersonalData/components/FormWrap";
import FormInput from "pages/PersonalData/components/FormInput";
import FormPart from "pages/PersonalData/components/FormPart";
import FormColumn from "pages/PersonalData/components/FormColumn";
import FormRow from "pages/PersonalData/components/FormRow";
import CheckBox from "components/inputElement/CheckBox";
import {Col, Row} from "react-bootstrap";
import {useTypedSelector} from "helpers/reducers";
import Loader from "components/loader/Loader";
import {formatDate, ifHasActiveLoanPrevention, processNotification} from "helpers/utility";
import {toastInfo} from "helpers/toasts";
import SessionAction from "stores/user/SessionAction";
import {useDispatch} from "useDispatch";

interface Props {}

const Activity = (props: Props) => {
    const dispatch = useDispatch();
    const user = useTypedSelector(state => state.session.user);
    const showUserPaperNotification = useTypedSelector(state => state.logic.showUserPaperNotification)
    const hasActiveLoan = useTypedSelector(state => state.loan.activeLoan) !== null;
    const [isDisabled, setIsDisabled] = useState<boolean>(user.papers_checked);


    const defaultValues = user ? {
        ...user,
        company_name: user.business.name,
        free_money: user.monthly_income - user.monthly_payments
    } : {}

    const {register, handleSubmit, watch, setValue, reset, errors} = useForm({
        defaultValues: defaultValues
    });

    useEffect(() => {
        processNotification(showUserPaperNotification, hasActiveLoan, user, dispatch);
    }, []);

    const onSubmitHandler = data => {
        // can edit only if new paper was uploaded which hadn't been checked
        if (isDisabled) {
            // setIsDisabled(true);
            // toastInfo("Už máte aktivní úvěr, sorry jako...");
            return;
        }

        dispatch(SessionAction.updatePersonalData(data));
    };

    if (!user) {
        return <Loader/>
    }

    return (
        <div className="Activity">
            <PageHeading title={"Činnost"}/>
            <Row>
                <Col lg={6}>
                    <FormWrap onSubmit={handleSubmit(onSubmitHandler)} className={"max-width-100"}>
                        <FormPart title={"Finance"}>
                            <FormRow>
                                <FormColumn>
                                    <FormInput title={"Měsíční příjem z činnosti"} register={register} name={"monthly_income"} disabled={isDisabled}/>
                                    <div>
                                        <FormInput title={"Měsíční výdaje v rámci činnosti"} register={register} name={"monthly_payments"} disabled={isDisabled}/>
                                        <div className={"arrow-blue-input"}>
                                            <FormInput title={"Z toho splátky činí"} register={register} name={"monthly_loans"} disabled={isDisabled}/>
                                        </div>
                                    </div>
                                    <FormInput title={"Jiné splátky"} register={register} name={"other_payments"} disabled={isDisabled}/>
                                </FormColumn>
                                <FormColumn>
                                    <FormInput title={"Volné prostředky pro splácení úvěru"} register={register} name={"free_money"} disabled={isDisabled}/>
                                    <FormInput title={"Celková výše všech pohl."} register={register} name={"amount_of_bills"} disabled={isDisabled}/>
                                </FormColumn>
                            </FormRow>
                        </FormPart>
                        <div className="GDPR d-flex">
                            <div className="d-flex">
                                <CheckBox name={"has_other_income"} register={register} checked={user.has_other_income} disabled={isDisabled}/>
                                <p className="ml-3">Jiný příjem</p>
                            </div>
                        </div>
                        <PurpleButton type={"submit"} class="btn btn-purple" disabled={isDisabled}>
                            Uložit změny
                        </PurpleButton>
                    </FormWrap>
                </Col>
                <Col lg={6}>
                    <div className="card">
                        <h3 className="page__section-title mb-3">Údaje z živnostenského rejstříku</h3>
                        <table className={"border-spacing"}>
                            <tbody>
                                <tr>
                                    <td className="t-heading">Typ podnikatele:</td>
                                    <td className={"pl-3"}>{user.business.type}</td>
                                </tr>
                                <tr>
                                    <td className="t-heading">Role osoby:</td>
                                    <td className={"pl-3"}>{user.business.role}</td>
                                </tr>
                                <tr>
                                    <td className="t-heading">Vznik oprávnění:</td>
                                    <td className={"pl-3"}>{formatDate(user.business.start_day)}</td>
                                </tr>
                                <tr>
                                    <td className="t-heading">Druh živnosti:</td>
                                    <td className={"pl-3"}>{user.business.spec_type}</td>
                                </tr>
                                <tr>
                                    <td className="t-heading">Předmět podnikání:</td>
                                    <td className={"pl-3"}>{user.business.subject}</td>
                                </tr>
                                <tr>
                                    <td className="t-heading">Obory činnosti:</td>
                                    <td className={"pl-3"}>{user.business.specializations}</td>
                                </tr>
                                <tr>
                                    <td className="t-heading">Doba platnosti oprávnění:</td>
                                    <td className={"pl-3"}>{user.business.expiration}</td>
                                </tr>
                                <tr>
                                    <td className="t-heading">Oprávnění vydal:</td>
                                    <td className={"pl-3"}>{user.business.authority}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </Col>


            </Row>
        </div>
    );
};

export default Activity;