import React from 'react';

interface TableHeadItemProps {
    children: React.ReactNode | React.ReactNode[],
    scope?: string,
    className?: string
}

const TableHeadItem = (props: TableHeadItemProps) => {
    return (
        <th scope={props.scope || ""} className={props.className}>
            {props.children}
        </th>
    );
};

export default TableHeadItem;