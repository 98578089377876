import React from 'react';

interface Props {
    title?: string,
    children: React.ReactNode | React.ReactNode[],
    className?: string
}

const FormPart = (props: Props) => {
    return (
        <div className={props.className}>
            {props.title && <h3 className="page__section-title mb-3">{props.title}</h3>}
            <div className="inputs-wrap">
                {props.children}
            </div>
        </div>
    );
};

export default FormPart;