import React, {useEffect} from 'react';
import {formatCurrency, getDate, getDateTime} from "helpers/utility";
import {DeferralOrder} from "types/entities/DeferralOrder";
import Loader from "components/loader/Loader";

interface Props {
    pendingDeferredOrder: DeferralOrder,
    openPayment: () => void
}

const DeferralOrderCardBody = (props: Props) => {

    if (!props.pendingDeferredOrder) {
        return <Loader/>;
    }

    return (
        <div className="card__body">
            <table className="table">
                <tbody>
                <tr>
                    <td className={"pl-0"}>
                        <span className="card__label">Délka odložení</span>
                        <span className="card__numbers">{props.pendingDeferredOrder.days} dnů</span>
                    </td>
                    <td className={"pl-0"}>
                        <span className="card__label">Cena odložení</span>
                        <span className="card__numbers">{formatCurrency(props.pendingDeferredOrder.deferral_amount)} Kč</span>
                    </td>
                    <td className={"text-right pr-0"}>
                        <span className="card__label">Celkem</span>
                        <span className="card__numbers text-underline blue-text cursor-pointer text-underline" onClick={props.openPayment}><b>{formatCurrency(props.pendingDeferredOrder.new_order_amount)} Kč</b></span>
                    </td>
                </tr>
                <tr>
                    <td className={"pl-0"}>
                        <span className="card__label">Datum žádosti</span>
                        <span className="card__numbers">{getDateTime(props.pendingDeferredOrder.created)}</span>
                    </td>
                    <td className={"pl-0"}>
                        <span className="card__label">Nová splatnost úvěru</span>
                        <span className="card__numbers">{getDate(props.pendingDeferredOrder.new_due_date)}</span>
                    </td>
                    <td className={"text-right pr-0"}>
                        <span className="card__label">Odklad uhradit do</span>
                        <span className="card__numbers"><b>{getDate(props.pendingDeferredOrder.pay_due_date)}</b></span>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    );
};

export default DeferralOrderCardBody;