import React from 'react';

interface TableBodyProps {
    children: React.ReactNode | React.ReactNode[],
    className?: string
}

const TableBody = (props: TableBodyProps) => {
    return (
        <tbody className={props.className}>
            {props.children}
        </tbody>
    );
};

export default TableBody;