import React from 'react';
import ReactPaginate from 'react-paginate';


interface Props {
    setNewState: (newState) => void,
    data: any[]
    perPage: number,
    numberOfItems: number
    marginPagesDisplayed?: number,
    pageRangeDisplayed?: number
}

const Pagination = (props: Props) => {

    const handlePageClick = (page) => {
        const newData = [];
        for (let i = page.selected * props.perPage; i < page.selected * props.perPage + props.perPage; i++) {
            //@ts-ignore
            newData.push(props.data[i]);
        }
        props.setNewState(newData.filter(item => item !== undefined));
    }

    return (
        <div className="Pagination sticky-content">
            <ReactPaginate
                previousLabel={'<'}
                nextLabel={'>'}
                breakLabel={'/'}
                breakClassName={'break-me'}
                pageCount={props.numberOfItems / props.perPage}
                marginPagesDisplayed={1}
                pageRangeDisplayed={3}
                onPageChange={handlePageClick}
                containerClassName={'pagination'}
                activeClassName={'active'}
            />
        </div>
    );
};

export default Pagination;