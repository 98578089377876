import React, {useEffect, useMemo, useState} from 'react';
import CalculationSlider from "./components/CalculationSlider";
import clsx from "clsx";
import {useDispatch} from "useDispatch";
import {Col, Row} from "react-bootstrap";
import PurpleButton from "components/buttons/PurpleButton";
import {useTypedSelector} from "helpers/reducers";
import LoanAction from "stores/loan/LoanAction";
import {DAY_MARKS, PRICE_MARKS, PRICE_MARKS_RENT} from "helpers/config";
import {LoanSelection} from "types/entities/LoanSelection";
import useCalculateLoan from "helpers/hooks/useCalculateLoan";
import {RouteComponentProps, withRouter} from "react-router";
import Loader from "components/loader/Loader";
import {openModal} from "stores/action";
import CalculationVerifyModal from "pages/newLoan/components/CalculationVerifyModal";

type LoanPrices = {
    amount: number | undefined,
    loan_price: number | undefined,
    deferral_prices: number | undefined,
    total_price: number | undefined,
}

interface Params {}

interface Props extends RouteComponentProps<Params>{
    type: "fix" | "rent",
}

const Calculation = (props: Props) => {
    const LOAN_STEP = 1000;
    const preset = props.type === "fix" ? 35 : 14;
    const priceList = useTypedSelector(state => props.type === "fix" ? state.loan.priceList.fix : state.loan.priceList.rent);
    const loan: LoanSelection = useTypedSelector(state => state.loan.loan);
    const dispatch = useDispatch();
    const [rangePrice, setRangePrice] = useState<number | number[]>(3000);
    const [rangeDays, setRangeDays] = useState<number | number[]>(preset);
    const rangeDaysMemoized = useMemo(() => rangeDays, [rangeDays]);
    const rangePriceMemoized = useMemo(() => rangePrice, [rangePrice]);

    const {calc_rmpsn, resetPrices, amount, loanPrices, deferralPrice, totalPrice} = useCalculateLoan(rangePrice, rangeDays, priceList, preset);

    useEffect(() => {
        if (priceList !== []) {
            dispatch(LoanAction.fetchPriceList(props.type))
        }
        resetPrices(props.type);
    }, [props.type]);

    useEffect(() => {
        dispatch(LoanAction.setLoanSelection(
            {
                selected_price: rangePrice,
                selected_days: rangeDays,
                loan_price: loanPrices,
                deferral_fee: deferralPrice,
                total_loan_price: totalPrice,
            }
        ));
    }, [loanPrices, rangePrice, rangeDays]);


    const requestLoanHandler = () => {
        dispatch(openModal(<CalculationVerifyModal type={props.type} loan={loan}/>, {className: "ModalFull"}));
    }

    if (!priceList) {
        return <Loader />
    }

    return (
        <Row className={"justify-content-around"}>
            <Col lg={7} className={'d-flex flex-column justify-content-center pl-2 pl-lg-4'} >
                <div className={"choosePrice mb-4"}>
                    <div className="chooseValue-headingBox d-flex flex-column flex-md-row justify-content-between mb-2 mb-md-5 ">
                        <h3 className={"page__section-title"}>Jakou <b>částku</b> od nás <b>požadujete?</b></h3>
                        <h3 className={"page__section-title"}><b>{rangePrice},-</b></h3>
                    </div>
                    <CalculationSlider
                        defaultValue={rangePrice}
                        marks={PRICE_MARKS_RENT}
                        max={8000}
                        min={3000}
                        step={LOAN_STEP}
                        setSliderValue={setRangePrice}/>
                        {/*<Notification imageUrl={notification} show={rangePrice > 8000}>*/}
                        {/*    Pro tuto výši úvěru s klientem sjednáváme individuální podmínky*/}
                        {/*</Notification>*/}
                </div>
                <div className={clsx("chooseDays", rangePrice > 8000 ? "pushDown" : "pushUp")}>
                    <div className="chooseValue-headingBox d-flex flex-column flex-md-row justify-content-between mb-2 mb-md-5">
                        <h3 className={"page__section-title"}>Jakou požadujete <b>dobu splatnosti?</b></h3>
                        <h3 className={"page__section-title"}><b>{rangeDays} dní</b></h3>
                    </div>
                    <CalculationSlider
                        defaultValue={preset}
                        marks={DAY_MARKS}
                        max={70}
                        min={14}
                        step={7}
                        setSliderValue={setRangeDays}/>
                    {/*<img src={MoreDays} alt="" className="moreDays-img"/>*/}
                </div>
            </Col>

            <Col lg="5" className={"mt-4 mt-lg-0 mb-0 mb-md-5 mb-lg-0 px-0 px-sm-2 "}>
                {/*<img src={ThumbDown} className={"loanOptions-thumbDown DesktopOnly "} alt="ThumbDown"/>*/}
                <div className="calcSummary">
                    <h3 className="calcSummary-blue">Zvolili jste variantu:</h3>
                    <ul className="calcSummary-list">
                        <li className="calcSummary-listItem d-flex justify-content-between">
                            <span className="text-normal">Výše úvěru:</span>
                            <span className={"text-bold "}>{rangePriceMemoized},-</span>
                        </li>
                        <li className="calcSummary-listItem d-flex justify-content-between">
                            <span className="text-normal">Doba splatnosti:</span>
                            <span className="text-bold">{rangeDaysMemoized} dní</span>
                        </li>
                        <li className="calcSummary-listItem d-flex justify-content-between">
                            <span className="text-normal">Poplatek za poskytnutí úvěru:</span>
                            <span className="text-bold">{
                                rangePrice > 8000
                                    ? ("bude sjednán individuálně")
                                    : loanPrices + deferralPrice + ',-'

                            }</span>
                            {/*<span>{memoPrice}</span>*/}
                        </li>
                        <li className="calcSummary-listItem">
                            <div className="d-flex justify-content-between">
                                <span className="text-normal">Celkem zaplatíte:</span>
                                <span className="text-bold">{
                                    rangePrice > 8000
                                        ? ("bude sjednán individuálně")
                                        : totalPrice + ',-'

                                }</span>
                            </div>
                            <div>
                                {/*<span className="text-small">za {rangeDays} dní (do {getPaymentDate(null, rangeDays)})</span>*/}
                            </div>
                        </li>
                        <li className="calcSummary-listItem">
                            <div className="d-flex justify-content-between">
                                <span className="text-small">rpsn: (roční úroková sazba)</span>
                                <span className="text-small">{
                                    rangePrice > 8000
                                    ? "bude sjednána individuálně"
                                    : (calc_rmpsn("rpsn", loan) || 0) + '%'
                                }</span>
                            </div>
                            <div className="d-flex justify-content-between">
                                <span className="text-small">mpsn: (měsíční úroková sazba)</span>
                                <span className="text-small">{
                                    rangePrice > 8000
                                        ? "bude sjednána individuálně"
                                        : (calc_rmpsn("mpsn", loan) || 0) + '%'
                                }</span>
                            </div>
                        </li>
                    </ul>
                </div>
                <div className={"d-flex justify-content-end mt-4"}>
                    <PurpleButton class={"reset-button-top mx-auto mx-lg-0"} action={requestLoanHandler}>
                        Získat úvěr
                    </PurpleButton>
                </div>
            </Col>
        </Row>

    );
};

export default withRouter(Calculation);