import {MeResponse} from "types/Responses";
import SessionAction from "stores/user/SessionAction";
import {CustomerPaper} from "types/entities/CustomerPapers";
import update from "immutability-helper";

interface SessionReducerResetSessionStateAction {
    payload: null,
    type: typeof SessionAction.RESET_SESSION_STATE
}

interface SessionReducerSetAuthAction {
    payload: string,
    type: typeof SessionAction.SET_USER_AUTH
}

interface SessionReducerSetUserAction {
    payload: MeResponse,
    type: typeof SessionAction.SET_USER
}

interface SessionReducerSetClearSessionAction {
    payload: null,
    type: typeof SessionAction.SET_CLEAR_SESSION
}

interface SessionReducerSetCustomerPaperRowAction {
    payload: CustomerPaper,
    type: typeof SessionAction.SET_CUSTOMER_PAPERS_ROW
}

interface SessionReducerSetCustomerPapersAction {
    payload: CustomerPaper[],
    type: typeof SessionAction.SET_CUSTOMER_PAPERS
}

type SessionReducerAction = SessionReducerSetAuthAction
    | SessionReducerSetUserAction
    | SessionReducerSetClearSessionAction
    | SessionReducerSetCustomerPapersAction
    | SessionReducerSetCustomerPaperRowAction
    | SessionReducerResetSessionStateAction;

interface SessionState {
    token: string | null,
    user: MeResponse,
    customerPapers: CustomerPaper[]
}

const defaultState = {
    token: null,
    user: null,
    customerPapers: []
};

class SessionReducer {
    initialState: SessionState = defaultState

    reducer = (state: SessionState = this.initialState, action: SessionReducerAction): SessionState => {
        switch (action.type) {
            case "SessionAction.ResetSessionState":
                return {
                    ...defaultState
                }
            case "SessionAction.SetUserAuth":
                // return update(state, {
                //     token: {
                //         $set: action.payload
                //     }
                // })
                return {
                    ...state,
                    token: action.payload
                };
            case "SessionAction.SetUser":
                // return update(state, {
                //     user: {
                //         $set: action.payload
                //     }
                // })
                return {
                    ...state,
                    user: action.payload
                };

            case "SessionAction.SetCustomerPapers":
                return {
                    ...state,
                    customerPapers: action.payload
                }
            case "SessionAction.SetCustomerPapersRow":
                return update(state, {
                    customerPapers: {
                        $push: [action.payload]
                    }
                })

            // case SessionAction.SET_CLEAR_SESSION:
            //     return {
            //         ...state,
            //         token: null,
            //         user: null
            //     }

            default: return state;
        }
    }

}

export default SessionReducer;