import React from 'react';

interface LoanCardContainerProps {
    children: React.ReactNode | React.ReactNode[];
}

const LoanCardContainer = (props: LoanCardContainerProps) => {
    return (
        <div className="card active-loan-card-tour">
            {props.children}
        </div>
    );
};

export default LoanCardContainer;