import React from 'react';
import PageHeading from "components/global/PageHeading";
import {Col, Row} from "react-bootstrap";
import LoanHintBox from "pages/activeLoan/components/LoanHintBox";

interface Props {
    children: React.ReactNode | React.ReactNode[]
}

const ActiveLoanLogicWrap = (props: Props) => {
    return (
        <div className="wrapper">
            <div className="wrapper__child w-100">
                <PageHeading title={"Aktivní úvěr"}/>
                <Row className="">
                    {props.children}
                </Row>
            </div>
        </div>
    );
};

export default ActiveLoanLogicWrap;