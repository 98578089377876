import {Step} from "intro.js-react";
import React from "react";

export interface IntroJSStep extends Step {
    title?: string
}

export const STEPS: IntroJSStep[] = [
    {
        element: ".active-loan-card-tour",
        title: 'Aktivní úvěr',
        intro: (<span>V MENU “Aktivní úvěr” Vám budeme vždy zobrazovat aktuálně poskytnutý úvěr -
                uzavřenou smlouvu. Pokud žádný mít nebudete, můžete nás právě zde o něj požádat</span>),
        position: 'center',
        tooltipClass: 'myTooltipClass',
        highlightClass: 'myHighlightClass',
    },
    {
        element: ".SideNavDesktopTour .documents-tour",
        title: "Dokumenty",
        intro: (<span>V MENU “Dokumentu” jasně a přehledně zobrazujeme všechny dokumenty, které jsme společně uzavřeli.
            Pro větší přehlednost jsme je navíc rozdělili do 3 PodMENU.</span>),
        position: 'left',
        tooltipClass: 'myTooltipClass',
        highlightClass: 'myHighlightClass',
    },

    {
        element: ".main-content",
        title: "Sáhnutí PDF dokumentu",
        intro: (<span>Každý dokument si můžete nejen prohlédnout, ale i stáhnout ve formátu PDF</span>),
        position: 'center',
        tooltipClass: 'myTooltipClass',
        highlightClass: 'myHighlightClass',
    },
    {
        element: ".SideNavDesktopTour .bonuses-tour",
        title: "Bonusy",
        intro: (<span>Za splacený úvěr od nás získáváte body, které se Vám pěkně sčítají v MENU “Bonusy”. Nasbírané body pak můžete například směnit za Odložení splatnosti a tak čerpat úvěr delší dobu a ZDARMA !</span>),
        position: 'left',
        tooltipClass: 'myTooltipClass',
        highlightClass: 'myHighlightClass',
    },
];

export const manageTourSteps = (nextStepIndex, nextElement, history) => {
    switch (nextStepIndex) {
        case 1:
            history.push('/dokumenty/smlouvy');
            console.log(nextElement)

            break;
        case 2:
            console.log('preco?')
            console.log(nextElement)
            break;
        case 3:
            console.log(nextElement)
            break;
        case 4:
            console.log(nextElement)
            break;

        default:
            history.push("/aktivni-uver");
            break;
    }
}
