import React from 'react';
import clsx from "clsx";
import {getLoanStatusColor} from "helpers/utility";

interface Props {
    status: number,
    statusText: string,
    className?: string
}

const Status = (props: Props) => {
    return (
        <div className={clsx("Status", getLoanStatusColor(props.status), props.className)}>
            <p className={"mb-0"}>{props.statusText}</p>
        </div>
    );
};

export default Status;