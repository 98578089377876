import React, {useEffect, useRef, useState} from 'react';
import {useForm} from "react-hook-form";
import {useTypedSelector} from "helpers/reducers";
import Loader from "components/loader/Loader";
import PaymentForm from "pages/activeLoan/components/PaymentForm";
import {toast} from "react-toastify";
import PaymentMethodForm from "pages/activeLoan/components/PaymentMethodForm";
import {useDispatch} from "useDispatch";
import LoanAction from "stores/loan/LoanAction";
import {closeModal, openModal} from "stores/action";
import {toastInfo} from "helpers/toasts";
import {ROOT_URL} from "helpers/config";
import QRSepaGenerator from "components/qrGenerator/QRSepaGenerator";
import {useLocation} from "react-router-dom";

interface Props {}

const LoanPaymentModal = (props: Props) => {
    const dispatch = useDispatch();
    const activeLoan = useTypedSelector(state => state.loan.activeLoan);
    const {handleSubmit, register, setValue, watch} = useForm({
        defaultValues: {
            amount: activeLoan?.sum
        }
    });
    const [goToSelectPaymentMethod, setGoToSelectPaymentMethod] = useState<boolean>(false);
    const [amount, setAmount] = useState(activeLoan?.sum);
    const amountRef = useRef(0)
    const amountValue = watch('amount')


    useEffect(() => {
        amountRef.current = 0
    }, [amountValue]);

    const selectQuickAmount = (amount: number): void => {
        if (activeLoan) {
            const newAmount = (activeLoan.sum / 100) * amount
            setValue('amount', newAmount);
            setAmount(newAmount);
            amountRef.current = amount;
        }
    }

    const sendViaEmail = () => {}

    const payByQR = () => {
        dispatch(openModal(
            <QRSepaGenerator
                paymentData={
                    {
                        ACC: "CZ2803000000000276713618",
                        AM: amount && amount.toString() || "0",
                        CC: "CZK",
                        RF: "123456789",
                        "X-VS": activeLoan?.contract_number || "",
                        PT: "IP",
                        MSG: "Testovaci zprava"
                    }
                }/>, {}));
    }
    const payByTransfer = () => {}

    const payByCard = (data): void => {
        dispatch(LoanAction.payOrder({order_id: activeLoan?.order_id, amount: amount})).then(r => {
            console.log(r)
            window.location.href = r.redirect_url;
        })
        dispatch(closeModal())
    }

    const setGoToSelectPaymentMethodHandler = (value: boolean): void => {
        if (amountValue && amountValue < 500) {
            toastInfo("Minimálni částka pro splátku úvěru je 500 Kč!");
            return;
        }

        setGoToSelectPaymentMethod(value);
    }

    if (!activeLoan?.id) {
        return <Loader/>;
    }

    return (
        <div className="LoanPaymentModal ">
            <h3 className="page__section-title mb-3" style={{position: 'relative', top: '-20px'}}>Platba úvěru</h3>
            <div style={{marginTop: '-15px'}}>
                {goToSelectPaymentMethod
                    ? (
                        <PaymentMethodForm
                            payByCard={handleSubmit(payByCard)}
                            payByQR={payByQR}
                            payByTransfer={payByTransfer}
                            sendViaEmail={sendViaEmail}/>
                    ) : (
                       <PaymentForm
                           setGoToSelectPaymentMethod={setGoToSelectPaymentMethodHandler}
                           selectQuickAmount={selectQuickAmount}
                           amountRef={amountRef} 
                           register={register}
                           setValue={setValue}
                           setAmount={setAmount}
                           activeLoan={activeLoan}/>
                    )}
            </div>
        </div>
    );
};

export default LoanPaymentModal;