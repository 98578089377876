import React from 'react';
import clsx from "clsx";

interface Props {
    children: React.ReactNode | React.ReactNode[],
    onSubmit: () => void
    className?: string
}

const FormWrap = (props: Props) => {
    return (
        <div className="PersonalInfo-root">
            <form onSubmit={props.onSubmit}>
                <div className={clsx("PersonalInfo mb-3", props.className)}>
                    {props.children}
                </div>
            </form>
        </div>
    );
};

export default FormWrap;