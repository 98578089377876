import React, {useState} from 'react';
import PageHeading from "components/global/PageHeading";
import clsx from "clsx";
import Calculation from "pages/newLoan/Calculation";
import {useTypedSelector} from "helpers/reducers";

interface Props {}

const NewLoan = (props: Props) => {
    const [activeTab, setActiveTab] = useState<"fix" | "rent">("fix");
    const user = useTypedSelector(state => state.session.user);

    return (
        <div className="NewLoan">
            <PageHeading title={"Žádost o úvěr"}/>

            <div className="tabs">
               <div className="d-flex">
                   <input type="radio" name="tabs" id="tabone" defaultChecked={activeTab === "fix"}/>
                   <label
                       onClick={() => setActiveTab("fix")}
                       className={clsx("purple", activeTab === "fix" && "active-tab")}
                       htmlFor="tabone">
                       Vista FIX
                   </label>
                   {user.rent_active && (
                       <>
                           <input type="radio" name="tabs" id="tabtwo" defaultChecked={activeTab === "rent"}/>
                           <label
                               onClick={() => setActiveTab("rent")}
                               className={clsx("blue", activeTab === "rent" && "active-tab")}
                               htmlFor="tabtwo">
                               Vista RENT
                           </label>
                       </>
                   )}
               </div>
                    <div className={clsx("tab", activeTab === "fix" && "no-border", activeTab === "rent" && "no-border-top-right")}>

                        {activeTab === "fix" && (
                            <Calculation type={activeTab}/>
                        )}
                        {activeTab === "rent" && (
                            <Calculation type={activeTab}/>
                        )}

                    </div>
            </div>
        </div>
    );
};

export default NewLoan;