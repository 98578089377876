import React from 'react';

interface Props {
    children: React.ReactNode | React.ReactNode[]
}

const MobileNav = (props: Props) => {
    return (
        <div className="mobile-footer-nav">
            <div className={"d-flex justify-content-around w-100 pt-3"}>
                {props.children}
            </div>
        </div>
    );
};

export default MobileNav;