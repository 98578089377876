import React from 'react';
import {Col} from "react-bootstrap";
import {Link} from "react-router-dom";
import clsx from "clsx";
import {useTypedSelector} from "helpers/reducers";

interface Props {
    route: string,
    tabTitle: string,
    col: number
}

const NewLoanItem = (props: Props) => {
    const hasActiveLoan = useTypedSelector(state => state.loan.activeLoan);

    const handleRoute = (e) => {
        if (hasActiveLoan) {
            e.stopPropagation()
            e.preventDefault();
            return;
        }
    }

    return (
            <Col xs={props.col} className={clsx("d-flex flex-column justify-content-start align-items-center", hasActiveLoan && "event-pointers-none")}>
                <Link onClick={handleRoute} to={props.route} className={"d-flex flex-column justify-content-center align-items-center"}>
                    <div className="new-loan-icon">
                        <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect width="44" height="44" rx="22" fill={hasActiveLoan ? "#6c757d" : "url(#paint0_linear_2649:10940)"}/>
                            <path fillRule="evenodd" clipRule="evenodd" d="M23 21V14H21L21 21H14V23H21L21 30H23V23H30V21H23Z" fill="#FAFCFD"/>
                            <defs>
                                <linearGradient id="paint0_linear_2649:10940" x1="0" y1="22" x2="44" y2="22" gradientUnits="userSpaceOnUse">
                                    <stop stopColor="#B800F5"/>
                                    <stop offset="1" stopColor="#7D00A6"/>
                                </linearGradient>
                            </defs>
                        </svg>
                    </div>
                    <p className={clsx("tabTitle", hasActiveLoan && "text-gray")}>{props.tabTitle}</p>
            </Link>
        </Col>
    );
};

export default NewLoanItem;