import React from 'react';

interface Props {
    title: string
}

const PageHeading = (props: Props) => {
    return (
        <h1 className="page__title">{props.title}</h1>
    );
};

export default PageHeading;