import React, {useEffect} from 'react';
import WhiteButton from "components/buttons/WhiteButton";
import PurpleButton from "components/buttons/PurpleButton";
import {useDispatch} from "react-redux";
import {useTypedSelector} from "helpers/reducers";
import LoanAction from "stores/loan/LoanAction";
import {LoanInvokePaymentStatus} from "types/enums/LoanInvokePaymentStatus";
import {GetActiveLoanResponse} from "types/Responses/getActiveLoanResponse";
import {openModal} from "stores/action";
import LoanDeferredPaymentModal from "./LoanDeferredPaymentModal";
import LoanPaymentModal from "./LoanPaymentModal";
import clsx from "clsx";

interface Props {
    loan: GetActiveLoanResponse
}

const LoanCardFooter = (props: Props) => {
    const pendingDeferralOrder = useTypedSelector(state => state.loan.pendingDeferredOrder);
    const disabled = !!pendingDeferralOrder;
    const disableClose = false;
    const className = "ModalFull"
    const dispatch = useDispatch();

    const invokeDeferredPayment = () => {
        dispatch(openModal(<LoanDeferredPaymentModal loan={props.loan} pendingDeferralOrder={pendingDeferralOrder}/>, {className}))
    }

    const invokeLoanPayment = () => {
        dispatch(openModal(<LoanPaymentModal/>, {className, disableClose}))
    }

    return (
        <div className={clsx("card__footer mt-2 d-flex align-items-center align-items-sm-end ", pendingDeferralOrder ? "justify-content-end" : "justify-content-between")}> {/*"LoanCard__footer"*/}
            {!pendingDeferralOrder && <WhiteButton action={invokeDeferredPayment}>Požádat o odklad splatnosti</WhiteButton>}
            <PurpleButton
                disabled={disabled}
                action={invokeLoanPayment}>
                Splatit úvěr
            </PurpleButton>
        </div>
    );
};

export default LoanCardFooter;