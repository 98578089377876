import React, {useState} from 'react';
import PurpleButton from "components/buttons/PurpleButton";
import {useForm} from "react-hook-form";
import {useDispatch} from "useDispatch";
import SessionAction from "stores/user/SessionAction";
import {toast} from "react-toastify";
import Loader from "components/loader/Loader";
import LoginEnvelope from "assets/icons/loginEnvelope.svg";
import {toastError, toastSuccess} from "helpers/toasts";

type ForgotPasswordFormSchema = {
    email: string
}

interface Props {
    setActiveTab: React.Dispatch<React.SetStateAction<string>>
    onClick: () => void
}

const ForgotPasswordForm = (props: Props) => {
    const dispatch = useDispatch();
    const [isResettingPassword, setIsResettingPassword] = useState(false);
    const {register, handleSubmit, watch, setValue, reset, errors} = useForm<ForgotPasswordFormSchema>()

    const submitHandler = data => {
        setIsResettingPassword(true);
        dispatch(SessionAction.authResetPassword(data)).then(r => {
            toastSuccess("Reset hesla úspěšný!");
            props.setActiveTab('login')
        }).catch(err => {
            toastError("Heslo se nezdařilo resetovat. Kontaktujte náš servis!");
            setIsResettingPassword(false);
        });
    }

    if (isResettingPassword) {
        return <Loader/>;
    }

    return (
        <form onSubmit={handleSubmit(submitHandler)}>
            <div className="form-group customInput-group">
                <img src={LoginEnvelope} className={"customInput-icon"} alt=""/>
                <input type="email" name="email" className="form-control customInput" ref={register} placeholder="Přihlašovací e-mail:" id="email"/>
                {errors.email && <label>{errors.email.message}</label>}
            </div>
            <PurpleButton type="submit" class={"d-block mx-auto"}>Zaslat heslo</PurpleButton>
        </form>
    );
};

export default ForgotPasswordForm;