import React from 'react';
import {useTypedSelector} from "helpers/reducers";
import Button from "components/buttons/Button";
import clsx from "clsx";

interface Props {
    children: React.ReactNode | React.ReactNode[];
    disabled?: boolean;
    action?: any;
    type?: string;
    class?: string;
}

const RedButton = (props: Props) => {
    const session = useTypedSelector(state => state.session);
    const type = props.type !== undefined ? props.type : "button";

    return (
        <>
            <Button type={type} className={clsx("btn btn-red", !session.token && "top-0", props.class)} disabled={props.disabled} action={props.action}>{props.children}</Button>
        </>
    );
};

export default RedButton;