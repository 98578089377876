import React from 'react';
import clsx from "clsx";

interface Props {
    onClick: () => void
    openNav: boolean
}

const LoanDetailNavBurger = (props: Props) => {
    return (
        <div className={clsx("DrawerTogglerDetail ", props.openNav ? "open" : "")} onClick={props.onClick}>
            <div></div>
            <div></div>
            <div></div>
        </div>
    );
};

export default LoanDetailNavBurger;