import React from 'react';
import LoanCardContainer from "./LoanCardContainer";
import LoanCardHeader from "./LoanCardHeader";
import LoanCardBody from "./LoanCardBody";
import LoanCardFooter from "./LoanCardFooter";
import {useTypedSelector} from "helpers/reducers";
import Loader from "components/loader/Loader";
import useWindowDimensions from "helpers/hooks/useWindowDimensions";
import {MOBILE_BREAKPOINT} from "helpers/utility";
import LoanCardMobile from "pages/activeLoan/components/LoanCardMobile";
import {Loan} from "types/entities";
import {GetActiveLoanResponse} from "types/Responses";

interface Props {
    loan: Loan | GetActiveLoanResponse
}

const LoanCard = (props: Props) => {
    const {height, width} = useWindowDimensions();
    const mobile = width < MOBILE_BREAKPOINT;

    if (!props.loan) {
        return <Loader/>
    }

    return (
        <React.Fragment>
            {props.loan && (
                <LoanCardContainer>
                    <LoanCardHeader loan={props.loan}/>
                    {mobile
                        ? <LoanCardMobile loan={props.loan}/>
                        : <LoanCardBody loan={props.loan}/>}

                    {props.loan?.status && props.loan.status >= 5 && (
                        <LoanCardFooter loan={props.loan}/>
                    )}
                </LoanCardContainer>
            )}
        </React.Fragment>
    );
};

export default LoanCard;