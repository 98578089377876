import React from 'react';
import Phone from "assets/icons/phone.svg";
import Facebook from "assets/icons/facebook.svg";
import Mail from "assets/icons/mail.svg";

interface Props {}

const ContactServis = (props: Props) => {
    return (
        <div className="Servis">
            <div className="d-flex mb-4">
                <div className="Servis-icon">
                    <img src={Phone} alt="Phone icon"/>
                </div>
                <div className="Servis-number">
                    <a rel={"noreferrer"} href="tel:+420587407707" className={"top-reset-a text-black"}>+420 587 407 707</a>
                    <p className={"text-gray mb-0 mt-1"}>Pondělí - Pátek 9 - 17 hod.</p>
                </div>
            </div>
            <div className="d-flex mb-4">
                <div className="Servis-icon">
                    <img src={Mail} alt="Mail icon"/>
                </div>
                <div className="Servis-number">
                    <div className="Servis-title">
                        <a rel={"noreferrer"} href="mailto:uver@vistacredit.cz">
                            uver@vistacredit.cz
                        </a>
                    </div>
                </div>
            </div>
            <div className="d-flex">
                <div className="Servis-icon d-block">
                    <img src={Facebook} alt="Facebook icon"/>
                </div>
                <div className="Servis-number">
                    <div className="Servis-title">
                        <a rel={"noreferrer"} href={"https://www.facebook.com/VistaCredit.cz"} target={"_blank"}>facebook</a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ContactServis;