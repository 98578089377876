import React from 'react';
import QRCode from "react-qr-code";

// code example: "SPD*1.0ACC:CZ2803000000000276713618*AM:1220*CC:CZK*RF:123456789*X-VS:2200621*X-SS:*X-KS:*PT:IP*MSG:Testovaci zprava"
export interface SEPAPaymentData {
    ACC: string,        //iban
    AM: string,         //amount
    CC: string,         //currency
    RF: string,         //reference
    "X-VS": string,     //variable symbol
    "X-SS"?: string,    //specific symblo
    "X-KS"?: string,    //konstant symbol
    PT?: string,        //instant payment => value IP for instant
    MSG?: string        //message
}

interface Props {
    paymentData: SEPAPaymentData
}

const QRSepaGenerator = (props: Props) => {
    const generateSepaQrCode = () => {
        const prefix = "SPD*1.0*";

        return prefix + Object.keys(props.paymentData).map(item => item + ":" + props.paymentData[item]).join("*");
    }

    const payment = generateSepaQrCode();

    return (
        <div className="LoanPaymentModal ">
            <h3 className="page__section-title mb-3" style={{position: 'relative', top: '-20px'}}>QR Platba úvěru</h3>
            <QRCode value={payment}/>
            <div className="text-gray textg-center mt-2">
                <p className={"mb-0"}>IBAN</p>
                <p className={"mb-0"}>{props.paymentData.ACC}</p>
                <div className={"d-flex justify-content-center mt-1"}>
                    <p className={"mb-0 mr-3"}>Částka: {props.paymentData.AM} Kč</p>
                    <p className={"mb-0"}>VS: {props.paymentData["X-VS"]}</p>
                </div>
            </div>
        </div>
    );
};

export default QRSepaGenerator;