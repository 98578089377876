import React, {useMemo} from 'react';
import PurpleButton from "components/buttons/PurpleButton";
import {closeModal, openModal} from "stores/action";
import {useDispatch} from "useDispatch";
import LoanAction from "stores/loan/LoanAction";
import {toastError, toastSuccess} from "helpers/toasts";
import {RouteComponentProps, withRouter} from "react-router";
import {LoanSelection} from "types/entities/LoanSelection";
import {useTypedSelector} from "helpers/reducers";
import {Col, Row} from "react-bootstrap";
import {GetActiveLoanResponse} from "types/Responses";
import LogicAction from "stores/logic/LogicAction";
import IDPlaceholder from "assets/icons/idPlaceholder.svg";
import clsx from "clsx";

interface Params {}

interface Props extends RouteComponentProps<Params> {
    type: "fix" | "rent",
    loan: LoanSelection
}

const CalculationVerifyModal = (props: Props) => {
    const dispatch = useDispatch();
    const session = useTypedSelector(state => state.session);
    const userIDs = useMemo(() => session.customerPapers.filter(paper => paper.type === 0), [session]);
    const userAddress = session.user?.addresses ? session.user.address : session.user.business.address;

    const handleResponse = (response: {redirect: boolean, error: any} | GetActiveLoanResponse) => {
        if (response?.redirect && response?.redirect !== false) {
            props.history.push("/aktivni-uver")
            toastSuccess("Tak to se povedlo, děkujeme!");
            return;
        }

        toastError("Opps! Někde se stala chyba! Prosím kontaktujte podporu.")
    }

    const requestLoanHandler = () => {
        const loanType = props.type === "fix" ? 0 : 1;
        if (props.loan.selected_days > 35) {
            dispatch(LoanAction.createLoan(loanType, props.type === "fix" ? 35 : 14, props.loan.selected_price))
                .then((loanResponse: {redirect: boolean, data: GetActiveLoanResponse, err: null} | {redirect: boolean, data: null, error: any}) => {
                    // dispatch(LoanAction.createDeferralOrder(+props.loan.selected_days - 35));
                    dispatch(LogicAction.setPrePendingDeferralOrder(+props.loan.selected_days - (props.type === "fix" ? 35 : 14)))
                        handleResponse(loanResponse);
                        // console.log(loanResponse.data);
            });
        } else {
            dispatch(LoanAction.createLoan(loanType, props.loan.selected_days, props.loan.selected_price))
                .then((r :{redirect: boolean, error: any} | GetActiveLoanResponse) => {
                    handleResponse(r);
                });
        }

        dispatch(closeModal());
    }

    return (
        <div className="CalculationVerifyModal">
            <h3 className={"page__section-title"}>Kontrola osobních údajů</h3>
            <div className={"mt-3"}>
                <div>
                    <p>
                        Překontrolujte si prosím své osobní údaje, vložené dokumenty a ostatní údaje k Vaší osobě.
                        Pokud víte, <b>že je vše v pořádku</b>, klikněte na tlačítko <b>ODESLAT ŽÁDOST</b>
                    </p>
                </div>
                <Row className={"justify-content-between text-left"}>
                    <Col lg={6}>
                       <div className={"mb-2"}>
                           <b>Vaše občanka:</b>
                       </div>

                        {userIDs.map((id_card, index) => (
                            <div className={clsx("user_id_wrap", index < userIDs.length -1 && "mb-3")}>
                                <a rel={"no-referrer"} href={id_card.file} target={"_blank"}>
                                    <img src={IDPlaceholder} className={"imgHover"} alt=""/>
                                    <span className="showTextOnHover cursor-pointer">Zobrazit detail</span>
                                </a>
                            </div>
                        ))}
                    </Col>
                    <Col lg={6}>
                        <b>Vaše Adresa:</b>
                        <table className={"w-100 mt-3"}>
                            <tbody>
                            <tr>
                                <td className={"pb-3"}><b>Ulice:</b></td>
                                <td className={"pb-3"}>{userAddress.street}</td>
                            </tr>
                            <tr>
                                <td className={"pb-3"}><b>Město</b></td>
                                <td className={"pb-3"}>{userAddress.city}</td>
                            </tr>
                            <tr>
                                <td className={"pb-3"}><b>Číslo popisné</b></td>
                                <td className={"pb-3"}>{userAddress.house_number}</td>
                            </tr>
                            <tr>
                                <td className={"pb-3"}><b>PSČ</b></td>
                                <td className={"pb-3"}>{userAddress.postal_code}</td>
                            </tr>
                            </tbody>
                        </table>
                    </Col>
                </Row>
            </div>

            <div className={"d-flex justify-content-end mt-4"}>
                <PurpleButton class={"reset-button-top mx-auto mx-lg-0"} action={requestLoanHandler} loadingButton={true}>
                    ODESLAT ŽÁDOST
                </PurpleButton>
            </div>
        </div>
    );
};

export default withRouter(CalculationVerifyModal);