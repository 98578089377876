import React from 'react';
import {getDateTime} from "helpers/utility";
import {Bonus} from "types/entities";
import clsx from "clsx";

interface Props {
    bonus: Bonus
}

const MobileTableItemBonuses = (props: Props) => {
    const isPositive = props.bonus.amount > 0;
    return (
        <div className={"LoanHistoryTableItemMobile d-flex justify-content-between"}>
            <div className={"cursor-pointer"}>
                <div><span className={"cursor-link"}>ID: {props.bonus.order}</span></div>
                <div>{getDateTime(props.bonus.created)}</div>
            </div>
            <div className={"d-flex flex-column justify-content-center align-items-end"}>
                <div className={clsx(isPositive ? "green-text" : "red-text")}>{isPositive ? '+' + props.bonus.amount : props.bonus.amount}</div>
                <div className={clsx(isPositive ? "blue-text" : "orange-text")}>{props.bonus.manner_text}</div>
            </div>

        </div>
    );
};

export default MobileTableItemBonuses;