import React from 'react';
import {useDispatch} from "useDispatch";
import LoanAction from "stores/loan/LoanAction";
import {closeModal, openModal} from "stores/action";
import LoanDetail from "pages/loanHistory/components/LoanDetail";
import LoanHistoryTableItemMobile from "pages/loanHistory/components/LoanHistoryTableItemMobile";
import {Loan} from "types/entities";
import LoanCard from "pages/activeLoan/components/LoanCard";
import {toastInfo} from "helpers/toasts";

interface Props {
    loans: Loan[]
}

const LoanHistoryTableMobile = (props: Props) => {
    const dispatch = useDispatch();

    const openDetailHandler = (detail: Loan) => {
        const className = "ModalLoanDetail";
        dispatch(LoanAction.fetchLoanDetail(detail.id)).then(r => {
            if (!r) {
                dispatch(closeModal());
                toastInfo("Je nám líto, ale momentálně nelze načíst detail úvěru. Skuste to prosím později.");
                return;
            }

            dispatch(openModal(<LoanDetail loan={detail} />, {className}))
        });
    }
    return (
        <div className="LoanHistoryTableMobile scrollBar-hidden">

            <div className={"mobile-table"}>
                {props.loans?.map(loan => (
                    <LoanHistoryTableItemMobile key={loan.id} loan={loan} onClick={openDetailHandler}/>
                ))}
            </div>
        </div>
    );
};

export default LoanHistoryTableMobile;