import React from 'react';

interface Props {
    children: React.ReactNode | React.ReactNode[]
}

const FormColumn = (props: Props) => {
    return (
        <div className="input-column">
            {props.children}
        </div>
    );
};

export default FormColumn;