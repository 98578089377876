import React, {useEffect, useState} from 'react';
import LoanCardContainer from "pages/activeLoan/components/LoanCardContainer";
import LoanCardLoanId from "pages/activeLoan/components/LoanCardLoanId";
import {useTypedSelector} from "helpers/reducers";
import {formatCurrency, getDate, getDateTime} from "helpers/utility";
import {useDispatch} from "useDispatch";
import {openModal} from "stores/action";
import LoanPaymentModal from "pages/activeLoan/components/LoanPaymentModal";
import Loader from "components/loader/Loader";
import PurpleButton from "components/buttons/PurpleButton";
import LoanDeferredPaymentModal from "pages/activeLoan/components/LoanDeferredPaymentModal";
import DeferralOrderCardBody from "pages/activeLoan/components/DeferralOrderCardBody";
import WhiteButton from "components/buttons/WhiteButton";
import CancelDeferralOrderModal from "pages/activeLoan/components/CancelDeferralOrderModal";

interface Props {}

const DeferralOrderCard = (props: Props) => {
    const dispatch = useDispatch();
    const loanState = useTypedSelector(state => state.loan);
    const contractsToSign = useTypedSelector(state => state.loan.contractsToSign);
    const className = "ModalFull"
    const openPayment = () => dispatch(openModal(<LoanPaymentModal/> ,{className}))
    const [isDisabled, setIsDisabled] = useState(contractsToSign.length !== 0);

    useEffect(() => {
        setIsDisabled(false)
    }, [contractsToSign])

    const invokeDeferredPayment = () => {
        dispatch(openModal(<LoanDeferredPaymentModal loan={loanState.activeLoan} pendingDeferralOrder={loanState.pendingDeferredOrder}/>, {className}))
    }

    const invokeCancelDeferralOrder = (order_id?: number) => {
        order_id && dispatch(openModal(<CancelDeferralOrderModal order_id={order_id} />, {}))
    }

    if (!loanState.pendingDeferredOrder) {
        return <Loader/>;
    }

    return (
        <div className="DeferredConfirm-root mt-4 mb-4">
            <LoanCardContainer>
                <div className="card__header">
                    <h3 className="page__section-title">
                        Odklad splatnosti
                    </h3>
                    <LoanCardLoanId id={loanState.pendingDeferredOrder.contract_number}/>
                </div>

                <DeferralOrderCardBody pendingDeferredOrder={loanState.pendingDeferredOrder} openPayment={openPayment}/>
                <div className={"d-flex justify-content-between"}>
                    <WhiteButton action={() => invokeCancelDeferralOrder(loanState.pendingDeferredOrder?.order_id)} disabled={isDisabled}>Stornovat odklad</WhiteButton>
                    <PurpleButton
                        action={invokeDeferredPayment}
                    disabled={isDisabled}>
                        Zaplatit odklad
                    </PurpleButton>
                </div>
            </LoanCardContainer>
        </div>
    );
};

export default DeferralOrderCard;