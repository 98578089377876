import {useDispatch} from "useDispatch";
import {useHistory, useLocation} from "react-router-dom";
import {useEffect, useRef} from "react";

export const useLogoutOnInactivity = (excludedOn: string[], LOGOUT_TIMEOUT: number) => {
    const location = useLocation()
    const logoutTimerRef = useRef<any>(null);
    const history = useHistory();

    const onTimeOutLogout = (): void => {
        history.push("/odhlaseni");
    };

    const createInterval = () => {
        logoutTimerRef.current = setInterval(onTimeOutLogout, LOGOUT_TIMEOUT);

        window.addEventListener("click", (): void => {
            if (logoutTimerRef.current) {
                clearInterval(logoutTimerRef.current);

                logoutTimerRef.current = setInterval(onTimeOutLogout, LOGOUT_TIMEOUT);
            }
        });
    };

    useEffect(() => {
        const isExcluded = excludedOn.some(item => location.pathname.includes(item));
        if (!isExcluded) {
            createInterval();
        }

        return () => {
            clearInterval(logoutTimerRef.current);
            logoutTimerRef.current = null;
        };
    }, [location.pathname]);
};