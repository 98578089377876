import React from 'react';
import {Doughnut} from "react-chartjs-2";
import {chartColors} from "./LoanDetail";

const Chart = props => {

    const data = {
        labels: ["Jistina", "Bonus", "Poplatek"],
        datasets: [
            {
                data: [props.loan.amount, props.loan.points, props.loan.fee],
                backgroundColor: chartColors,
                hoverBackgroundColor: chartColors,
                hoverOffset: 2
            },

        ],
    };

    const options = {
        responsive: true,
        plugins: {
            legend: {
                display: true,
                position: "bottom",
            },
            tooltip: {
                enabled: true,
            }
        },
        elements: {
            arc: {
                borderWidth: 0
            }
        },
    };
    return (
        <div className="chartWrap">
            <div className="chartWrapLoanSum">{props.loan.amount + props.loan.fee} Kč</div>
            <Doughnut data={data}
                      width={100}
                      height={50}
                      options={options}/>
        </div>
    );
};

export default Chart;