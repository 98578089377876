import React from 'react';
import CustomSelect from 'react-select';
import clsx from "clsx";

interface Props {
    className?: string
}

const Select = (props: Props) => {
    const options = [
        { value: 'Změna adresy', label: 'Změna adresy' },
        { value: 'Neco jiného', label: 'Neco jiného' },
        { value: 'Bla bla bla', label: 'Bla bla bla' }
    ]

    return (
        <div className={clsx("Select", props.className)}>
            <CustomSelect options={options} />
        </div>
    );
};

export default Select;