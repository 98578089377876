import React from 'react';
import FileIcon from "assets/icons/fileIcon.svg";
import ScanStatus from "pages/PersonalData/components/ScanStatus";
import {CustomerPaper} from "types/entities/CustomerPapers";
import {excerpt} from "helpers/utility";

interface Props {
    item: CustomerPaper
}

const ScanItem = (props: Props) => {
    return (
        <li className="id-list-item">
            <div className="id-name-wrap d-flex">
                <img src={FileIcon} className="id-file-icon" alt=""/>
                    <p className="id-file-name">
                        <a rel={"no-referrer"} href={props.item.file} download={"scan"}>
                            {excerpt(props.item.name)}
                        </a>
                    </p>
            </div>
            <ScanStatus type={props.item.status}/>
        </li>
    );
};

export default ScanItem;