import React from 'react';
import {useDispatch} from "useDispatch";
import LoanAction from "stores/loan/LoanAction";
import PurpleButton from "components/buttons/PurpleButton";
import {useTypedSelector} from "helpers/reducers";
import {toast} from "react-toastify";
import {closeModal} from "stores/action";
import BulletPoint from "assets/icons/bulletPoint.svg";
import Piggy from "assets/icons/piggy.svg";
import {toastInfo} from "helpers/toasts";

interface Props {}

const VistaRent = (props: Props) => {
    const dispatch = useDispatch();
    const isRentActive = useTypedSelector(state => state.session.user.rent_active);
    const list = [{
        id: "ffrth",
        text: "až 8.000 Kč s poplatkem za poskytnutí 0 Kč"
    },{
        id: "jgfhy",
        text: "kredit na neomezeně dlouhou dobu"
    },{
        id: "sfdfn",
        text: "platíte pouze za dobu užívání"
    },{
        id: "sfadf",
        text: "vždy vrátíte pouze to co jste si půjčili"
    },]


    const activateVistaRent = () => {
        if (isRentActive) {
            return toastInfo("Služba VistaRENT je již aktivní.")
        }

        dispatch(LoanAction.activateRentService()).then(r => {
            dispatch(closeModal());
            if (!r) {
                return;
            }
            window.location.href = r?.url;
        });
    };

    return (
        <div className="VistaRent text-center">
            <h2 className={"page__section--title"}>Vista RENT</h2>
            <h3 className={"loginHeading blue-text heading-blue mb-3"}>Co aktivací získáte?</h3>
            <div className={"BulletWrap mx-auto my-4"}>
                {list.map(item => (
                    <div key={item.id} className="d-flex my-2">
                        <img src={BulletPoint} alt=""/>
                        <p className={"mb-1 ml-3"}>{item.text}</p>
                    </div>
                ))}
            </div>
            <h3 className={"loginHeading blue-text heading-blue mt-4"}>Jak službu aktivovat?</h3>
            <p>Uhraďte aktivační poplatek ve výši 1.000 Kč <br/> a služba se Vám aktivuje.</p>
            <PurpleButton
                action={activateVistaRent}
                class={"d-block mx-auto mt-5"}>Uhradit a aktivovat</PurpleButton>

            <p className={"mt-4 text-gray-and-underline"} onClick={() => dispatch(closeModal())}>Nemám zájem</p>
            <img src={Piggy} className={"rentImage"} alt=""/>
        </div>
    );
};

export default VistaRent;