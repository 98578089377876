import React, {Suspense} from 'react';
import {withRouter, Route} from 'react-router-dom';
import Loader from "components/loader/Loader";

const ClientRoute = (props: any) => {
    return (
        <Route {...props} path={props.path}>
            <Suspense fallback={<Loader/>}>
                {props.children}
            </Suspense>
        </Route>
    );
};

export default withRouter(ClientRoute);