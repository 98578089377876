import React, {useEffect, useRef, useState} from 'react';
import PageHeading from "components/global/PageHeading";
import {Col, Row} from "react-bootstrap";
import Contract from 'assets/icons/contract.svg';
import {useDispatch} from "useDispatch";
import SessionAction from "stores/user/SessionAction";
import {useTypedSelector} from "helpers/reducers";
import ScanItems from "pages/PersonalData/components/ScanItems";
import ScanItem from "pages/PersonalData/components/ScanItem";
import UploadButton from "pages/PersonalData/components/UploadButton";
import QueueFile from "pages/PersonalData/components/QueueFile";
import {useForm} from "react-hook-form";
import {ifHasActiveLoanPrevention, processNotification} from "helpers/utility";
import {LoanStatus} from "types/enums/LoanStatus";
import {toastInfo} from "helpers/toasts";
import LogicAction from "stores/logic/LogicAction";

const PersonalDataDocuments = (props: any) => {
    const dispatch = useDispatch();
    const user = useTypedSelector(state => state.session.user);
    const customerPapers = useTypedSelector(state => state.session.customerPapers);
    const showUserPaperNotification = useTypedSelector(state => state.logic.showUserPaperNotification)
    const activeLoan = useTypedSelector(state => state.loan.activeLoan);
    const hasActiveLoan = activeLoan !== null;

    const isButtonDisabled = hasActiveLoan &&
        (activeLoan?.status > LoanStatus.Processed || user.papers_checked);

    const form = useForm();
    const [fileOP, setFileOP] = useState(null);
    const [fileAcc, setFileAcc] = useState(null);
    const idRef = useRef(null);
    const accountRef = useRef(null);

    useEffect(() => {
        processNotification(showUserPaperNotification, hasActiveLoan, user, dispatch);
    }, []);

    useEffect(() => {
        if ((customerPapers.filter(paper => paper.type === 1).length === 3 || customerPapers.filter(paper => paper.type === 0).length === 3) && !hasActiveLoan) {
            toastInfo("Můžete nahrát maximálne 3 doklady občanky a 3 doklady vypisu účtu");
        }
    }, []);

    useEffect(() => {
        dispatch(SessionAction.fetchCustomerPapers()).then(r => {
            console.log(r)
        });

    }, []);

    const setInputRef = (ref, input): void => {
        input.current = ref;
        form.register(ref);
    }

    const handleFileUpload = (e, type) => {
        type === 0
            ? setFileOP(e.target.files[0])
            : setFileAcc(e.target.files[0]);
    }

    const fileUploadHandler = (ref): void => {
        if (!ref) {
            return;
        }
        ref.current.click();
    };

    const uploadFileToServer = (type, file, setFile) => {
        const formData = new FormData();
        formData.append('type', type);
        formData.append('file', file);

        dispatch(SessionAction.uploadCustomerPapers(formData, type)).then(r => {
            setFile(null);
            dispatch(SessionAction.getUser());
            dispatch(LogicAction.setUserPaperNotification(true));
        });
    }

    const discardFile = (setFile) => {
        setFile(null);
    }

    return (
        <div className="PersonalDataDocuments">
            <PageHeading title={"Doklady"}/>
            <div className="PersonalId-root">
                <Row style={{transition: "all .2s linear"}}>
                    <Col lg="6" className="ID__card mr-3">
                        <h2 className="page__section-title">Občanský průkaz</h2>
                        <ScanItems>
                            {customerPapers?.filter(paper => paper.type === 0).map(paper => (
                                <ScanItem key={paper.id} item={paper}/>
                            ))}
                        </ScanItems>
                        <div className="id-list-wrap">
                            {fileOP && <QueueFile file={fileOP} upload={() => uploadFileToServer(0, fileOP, setFileOP)} discard={() => discardFile(setFileOP)}/>}
                            {!fileOP && (
                                <UploadButton
                                    onClick={() => fileUploadHandler(idRef)}
                                    onChange={handleFileUpload}
                                    type={0}
                                    input={idRef}
                                    hasActiveLoan={isButtonDisabled || customerPapers.filter(paper => paper.type === 0).length === 3}
                                    registerRef={setInputRef}/>
                            )}
                        </div>
                    </Col>
                    <Col lg="5" className="BankStatement__card mr-3">
                        <h2 className="page__section-title">Výpis z účtu</h2>
                        <ScanItems>
                            {customerPapers?.filter(paper => paper.type === 1).map(paper => (
                                <ScanItem key={paper.file} item={paper}/>
                            ))}
                        </ScanItems>
                        {fileAcc && <QueueFile file={fileAcc} upload={() => uploadFileToServer(1, fileAcc, setFileAcc)} discard={() => discardFile(setFileAcc)}/>}
                        {!fileAcc && (<UploadButton
                            onClick={() => fileUploadHandler(accountRef)}
                            onChange={handleFileUpload}
                            type={1}
                            input={accountRef}
                            hasActiveLoan={isButtonDisabled || customerPapers.filter(paper => paper.type === 1).length === 3}
                            registerRef={setInputRef}/>)}
                        <img src={Contract} className="bankAccount__bg" alt=""/>
                    </Col>
                </Row>
            </div>
        </div>
    );
};

export default PersonalDataDocuments;