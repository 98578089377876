import React, {useEffect, useState} from 'react';
import PageHeading from "components/global/PageHeading";
import {useDispatch} from "useDispatch";
import LoanAction from "stores/loan/LoanAction";
import {useTypedSelector} from "helpers/reducers";
import BonusesChart from "pages/bonuses/components/BonusesChart";
import {BonusesTab} from "types/enums/BonusesTab";
import clsx from "clsx";
import useWindowDimensions from "helpers/hooks/useWindowDimensions";
import {getNumberOfItemsPerPage, MOBILE_BREAKPOINT} from "helpers/utility";
import MobileTableBonuses from "pages/bonuses/components/MobileTableBonuses";
import BonusesTable from "pages/bonuses/components/BonusesTable";
import Pagination from "components/pagination/Pagination";
import {Bonus} from "types/entities";

interface Props {}

const Bonuses = (props: Props) => {
    const dispatch = useDispatch();
    const bonuses = useTypedSelector(state => state.loan.bonuses);
    const {height, width} = useWindowDimensions();
    const mobile = width < MOBILE_BREAKPOINT;
    const perPage = mobile ? bonuses.length : getNumberOfItemsPerPage(width);
    const initList = bonuses.filter((item, index) => index < perPage);
    const [list, setList] = useState<Bonus[]>(initList);
    const [activeTab, setActiveTab] = useState(BonusesTab.Table);



    useEffect(() => {
        dispatch(LoanAction.fetchUserBonuses());
    }, []);

    return (
        <div className="Bonuses prepare-sticky-content mb-lg-2">
            <PageHeading title={"Bonusy"}/>
            <div className="d-flex justify-content-center justify-content-md-start">
                <div className={clsx("mr-3 cursor-pointer bonus-tab", activeTab === BonusesTab.Table && 'active-tab')} onClick={() => setActiveTab(BonusesTab.Table)}>Tabulka bonusů</div>
                <div className={clsx("cursor-pointer bonus-tab", activeTab === BonusesTab.Chart && 'active-tab')} onClick={() => setActiveTab(BonusesTab.Chart)}>Graf</div>
            </div>

            {(activeTab === BonusesTab.Table && mobile) && <MobileTableBonuses list={list}/>}
            {(activeTab === BonusesTab.Table && !mobile) && (
                    <>
                        <BonusesTable list={list}/>
                        {perPage > 4
                        && <Pagination
                            setNewState={setList}
                            perPage={7}
                            data={bonuses}
                            numberOfItems={bonuses.length}/>

                        }
                    </>
                )}

            {activeTab === BonusesTab.Chart && <BonusesChart list={bonuses}/>}
        </div>
    );
};

export default Bonuses;