import React from 'react';
import {useDispatch} from "useDispatch";
import LimitedBg from "assets/icons/limitedBg.svg";
import Timer from "assets/icons/timer.svg";
import PurpleButton from "components/buttons/PurpleButton";

interface Props {
    icon?: string,
    text: React.ReactNode,
    buttonText: string
    callback: () => any
}

const Banner = (props: Props) => {
    const dispatch = useDispatch();
    const className = "ModalFull";


    return (
        <div className="LimitedLoan-root my-2">
            <img src={LimitedBg} alt="" className="LimitedLoan__background"/>
            <div className="LimitedLoan__wrap">
                <div className="LimitedLoan__icon-wrap">
                    <img src={props.icon || Timer} alt=""/>
                    <p className="LimitedLoan__paragraph">{props.text}</p>
                </div>
                <PurpleButton action={props.callback}>{props.buttonText}</PurpleButton>
            </div>
        </div>
    );
};

export default Banner;