import React from 'react';
import TableHead from "components/Table/components/TableHead";
import TableRow from "components/Table/components/TableRow";
import TableHeadItem from "components/Table/components/TableHeadItem";
import TableBody from "components/Table/components/TableBody";
import TableItem from "components/Table/components/TableItem";
import Table from "components/Table/Table";
import {Loan} from "types/entities";
import Download from "assets/icons/download.svg";
import {dateParse} from "helpers/utility";
import {ROOT_API} from "helpers/config";
import clsx from "clsx";

interface Props {
    loanDetail: Loan
    isMobile: boolean
}

const LoanDetailToPayTab = (props: Props) => {
    return (
        <div className={"text-left mt-3"}>
            <h5>Přehled položek ke splacení:</h5>
            <div className={clsx((props.loanDetail.payments?.length > 6 && !props.isMobile) && "tableBodyRestriction")}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableHeadItem>ID ÚVĚRU</TableHeadItem>
                            <TableHeadItem className={"text-left"}>TYP</TableHeadItem>
                            <TableHeadItem className={"text-center"}>ČÁSTKA</TableHeadItem>
                        </TableRow>
                    </TableHead>
                    <TableBody className={"tableBodyRestriction"}>
                        {props.loanDetail.to_pay[0]?.map(payment => (
                            <TableRow>
                                <TableItem>{props.loanDetail.contract_number}</TableItem>
                                <TableItem className={"text-left"}>{payment.item}</TableItem>
                                <TableItem className={"text-center"}>{payment.amount} Kč</TableItem>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </div>
        </div>
    );
};

export default LoanDetailToPayTab;