import React from 'react';
import Phone from "assets/icons/phone.svg";

const Servis = (props: any) => {
    return (
        <div className="Servis d-flex DesktopOnly mt-0">
            <div className="Servis-icon">
                <img src={Phone} alt="Phone icon"/>
            </div>
            <div className="Servis-number">
                <div className="Servis-title">Zákaznický servis</div>
                <a href="tel:+420587407707">+420 587 407 707</a>
            </div>
        </div>
    );
};

export default Servis;