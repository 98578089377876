import React from 'react';

interface Props {
    imgBlue: string,
    imgWhite: string,
    title: React.ReactNode,
    onClick: () => void
}

const PaymentMethod = (props: Props) => {
    return (
        <div className="payment-card" onClick={props.onClick}>
            <img src={props.imgBlue} className="card-blue" alt=""/>
            <img src={props.imgWhite} className="card-white" alt=""/>
            <p className="payment-name">{props.title}</p>
        </div>
    );
};

export default PaymentMethod;