import React from 'react';
import {Link} from "react-router-dom";
import logo from "assets/icons/logo.svg";
import clsx from "clsx";

interface Props {
    className?: string
}

const Logo = (props: Props) => {
    return (
        <div className={clsx("Logo", props.className)} data-aos="zoom-in">
            <Link className="home-link" to="/">
                <img src={logo} alt="VistaCredit logo"/>
            </Link>
        </div>
    );
};

export default Logo;