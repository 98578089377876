import React from 'react';
import Loader from "components/loader/Loader";
import MobileTableItemDocuments from "pages/documents/components/MobileTableItemDocuments";
import {Document} from "types/entities";

interface Props {
    documents: Document[];
}

const MobileTableDocuments = (props: Props) => {
    if (!props.documents) {
        return <Loader/>
    }

    return (
        <div className="LoanHistoryTableMobile scrollBar-hidden">
            <div className={"mobile-table"}>
                {props.documents.map(document =>
                    <MobileTableItemDocuments key={document.id} document={document} />
                )}
            </div>
        </div>
    );
};

export default MobileTableDocuments;