import React from 'react';
import {getStatusObject} from "helpers/utility";
import clsx from "clsx";
import {User} from "types/entities";

interface Props {
    status: statusType,
    user: User
    className?: string
}

export enum statusType {
     AUTH_OK = 'AUTH_OK',
     AUTH_PROCESSING = 'AUTH_PROCESSING',
     AUTH_NOT_COMPLETED = 'AUTH_NOT_COMPLETED',
     AUTH_FAILED = 'AUTH_FAILED',
}
const ClientIdentityStatus = (props: Props) => {
    const status = getStatusObject(props.user);

    return (
        <div className={clsx("Status-root my-2", !props.user?.papers_uploaded ? "borderBlink cursor-pointer" : "statusBackground", props.className)}>
            <div className="status-icon">
                <img src={status.icon} alt={"Status - " + status.text}/>
            </div>
            <div className="status-text">
                {status.text}
            </div>
        </div>
    );
};

export default ClientIdentityStatus;