import React from 'react';

interface Props {
    loanType: string
}

const LoanCardLoanType = (props: Props) => {
    return (
        <div className="card__name mr-4 mt-2">
            <span className="card__label">Typ úvěru</span>
            <h2 className="card__loan-type">{props.loanType}</h2>
        </div>
    );
};

export default LoanCardLoanType;