import React from 'react';
import {formatCurrency, getDate, getDateTime} from "helpers/utility";
import Status from "components/status/Status";
import {Loan} from "types/entities";

interface Props {
    loan: Loan,
    onClick: (detail: Loan) => void
}

const LoanHistoryTableItem = (props: Props) => {
    return (
        <>
            <td className={"cursor-pointer cursor-link"} onClick={() => props.onClick(props.loan)}>{props.loan.contract_number}</td>
            <td>{props.loan.type_text}</td>
            <td>{getDateTime(props.loan.created)}</td>
            <td>{formatCurrency(props.loan.amount)} Kč</td>
            <td>{getDate(props.loan.due)}</td>
            <td className={"cursor-pointer cursor-link"} onClick={() => props.onClick(props.loan)}>Zobrazit detail</td>
            <td className={"text-right"}><Status status={props.loan.status} statusText={props.loan.status_text}/></td>
        </>
    );
};

export default LoanHistoryTableItem;