import React from 'react';
import LoanCardLoanType from "./LoanCardLoanType";
import LoanCardLoanProcessStatus from "./LoanCardLoanProcessStatus";
import LoanCardLoanId from "./LoanCardLoanId";
import {GetActiveLoanResponse} from "types/Responses/getActiveLoanResponse";
import Status from "components/status/Status";

interface Props {
    loan: GetActiveLoanResponse
}

const LoanCardHeader = (props: Props) => {
    return (
        <div className="card__header">
            <div className="card__header--wrap d-flex">
                <LoanCardLoanType loanType={props.loan.loan_type}/>
                <Status status={props.loan.status} statusText={props.loan.status_text}/>
            </div>
            <LoanCardLoanId id={+props.loan.contract_number !== 0 ? +props.loan.contract_number : +props.loan.id}/>
        </div>
    );
};

export default LoanCardHeader;