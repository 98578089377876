import React from 'react';
import User from 'assets/icons/mobileNav/userBlue.svg';
import {useTypedSelector} from "helpers/reducers";
import Loader from "components/loader/Loader";
import ClientIdentityStatus, {statusType} from "components/status/ClientIdentityStatus";
import {getStatusObject} from "helpers/utility";

interface Props {}

const ClientHeader = (props: Props) => {
    const user = useTypedSelector(state => state.session.user);
    const loan = useTypedSelector(state => state.loan);

    if (!loan.bonuses || !loan.contracts || !loan.documents) {
        return <Loader/>
    }

    if (!user) {
        return <Loader/>;
    }

    const bonuses = loan.bonuses;
    const bonusesArray = bonuses.map(bonus => bonus.amount);
    const bonusCount = bonusesArray.reduce((a, b) => a + b, 0);

    const status = getStatusObject(user);

    return (
        <div className="ClientHeader">
            <div className="d-flex pl-0 pt-3">
                    <ClientIdentityStatus status={statusType.AUTH_FAILED} user={user} className={"mt-0 mx-1"}/>
                    <div className="clientInfo ml-1" style={{position: "relative", top: "5px"}}>
                        <span className="name">{user.first_name + ' ' + user.last_name}</span> <br/>
                        <span className="blue fz-small m-0"  style={{fontSize: '12px', position: 'relative', top: '-5px'}}><b className="purple">Vista Plus - </b> <u>{bonusCount} bodů</u></span>
                    </div>
            </div>
            <span className={"d-block text-gray ml-2 mb-2"} style={{fontSize: "13px"}}>{status.text}</span>
        </div>
    );
};

export default ClientHeader;