import React, {useEffect, useState} from 'react';
import LoanCard from "pages/activeLoan/components/LoanCard";
import DeferralOrderCard from "pages/activeLoan/components/DeferralOrderCard";
import SignContractBanner from "pages/activeLoan/components/SignContractBanner";
import {useTypedSelector} from "helpers/reducers";
import ActiveLoanNone from "pages/activeLoan/components/ActiveLoanNone";
import {openModal} from "stores/action";
import SignContract from "components/contract/SignContract";
import {useDispatch} from "useDispatch";
import LoanAction from "stores/loan/LoanAction";
import {toastInfo} from "helpers/toasts";
import LogicAction from "stores/logic/LogicAction";
import PapersBanner from "pages/activeLoan/components/PapersBanner";
import useWindowDimensions from "helpers/hooks/useWindowDimensions";
import Loader from "components/loader/Loader";

interface Props {}

const ActiveLoanDisplay = (props: Props) => {
    const dispatch = useDispatch();
    const {height, width} = useWindowDimensions();
    const loanState = useTypedSelector(state => state.loan);
    const session = useTypedSelector(state => state.session);
    const logic = useTypedSelector(state => state.logic);
    const hasContractsToSign = (loanState?.contractsToSign?.length > 0
        && session.user?.papers_checked
        && session.user?.papers_uploaded
        && loanState.contractsToSign?.filter(contract => contract?.signed !== true).length > 0);
    const [showPapersBanner, setShowPapersBanner] = useState(!session.user?.papers_uploaded);
    const className = "ModalLoanDetail ModalContractDetail ModalLoanDetailShrink";
    const signContract = () => dispatch(openModal(<SignContract contracts={loanState?.contractsToSign}/>, {className, isSignContract: true, onCloseCallback: closeCallback}))

    useEffect(() => {
        if (loanState.activeLoan) {
            dispatch(LoanAction.fetchDeferralOrder(loanState.activeLoan.id)).then(r => {
                r && toastInfo("Máte nezaplacený odklad, o který jste si zažádali minule!");
            });
        }
    }, []);

    useEffect(() => {
        if (!logic.flags.signLater) {
            hasContractsToSign && signContract();
        }
    }, [session, loanState, logic]);

    useEffect(() => {
        (session.user && !session.user?.papers_uploaded) && setShowPapersBanner(width <= 585)
    }, [width, session.user]);

    const closeCallback = () => {
        dispatch(LogicAction.setSignLaterFlag(true));
    }

    if (!session.user) {
        return <Loader/>
    }

    return (
        <div>
            {(!session.user.papers_uploaded && showPapersBanner) && <PapersBanner/>}
            {loanState.activeLoan && <LoanCard loan={loanState.activeLoan}/> }
            {!loanState.activeLoan && <ActiveLoanNone/>}
            {loanState.pendingDeferredOrder && <DeferralOrderCard/>}

            {/*<LimitedOffer/>*/}

            {hasContractsToSign && <SignContractBanner contractsToSign={loanState.contractsToSign}/>}
        </div>
    );
};

export default ActiveLoanDisplay;