import React from 'react';
import UploadDirectory from "assets/icons/uploadDirectory.svg";
import clsx from "clsx";

interface Props {
    onClick: (e) => void,
    type: number,
    input: any,
    registerRef: (ref, input) => void
    onChange: (e, type: number) => void
    hasActiveLoan?: boolean
}

const UploadButton = (props: Props) => {
    return (
        <div className={clsx("position-relative upload-file mt-3", props.hasActiveLoan && "event-pointers-none opacity-50")} onClick={props.onClick}>
            <div className="d-flex">
                <img src={UploadDirectory} className="upload-icon-directory mr-3" alt=""/>
                <p className="upload-icon-text mb-0">Nahrát soubor</p>
            </div>
            <input type="file"
                   ref={(ref) => props.registerRef(ref, props.input)}
                   style={{zIndex: "-1"}}
                   onChange={(e) => props.onChange(e, props.type)}
                   className={"position-absolute invisible h-0"}
                   name={"file"}/>
        </div>
    );
};

export default UploadButton;