import React from 'react';
import {Switch, Redirect, Route} from "react-router-dom";
import ClientRoute from "helpers/Route";
import SignIn from "pages/user/Login";
import PrivateRoute from "helpers/PrivateRoute";
import {useTypedSelector} from "helpers/reducers";
import Contracts from "pages/documents/Contracts";
import ActiveLoan from "pages/activeLoan/ActiveLoan";
import Postponements from "pages/documents/Postponements";
import PersonalDocuments from "pages/documents/PersonalDocuments";
import Bonuses from "pages/bonuses/Bonuses";
import LoanHistory from "pages/loanHistory/LoanHistory";
import PersonalDataDocuments from "pages/PersonalData/PersonalDataDocuments";
import PersonalData from "pages/PersonalData/PersonalData";
import Activity from "pages/PersonalData/Activity";
import Residence from "pages/PersonalData/Residence";
import ErrorPage from "pages/404/ErrorPage";
import NewLoan from "pages/newLoan/NewLoan";
import Contact from "pages/contact/Contact";
import Logout from "pages/logout/Logout";


const Routes = (props: any) => {
    const isAuth = useTypedSelector(state => state.session.token);
    const activeLoan = useTypedSelector(state => state.loan.activeLoan);

    return (
        <Switch>
            <ClientRoute path="/prihlaseni" exact>
                {!isAuth
                    ? <SignIn/>
                    : <Redirect to="/aktivni-uver"/>}
            </ClientRoute>
            <ClientRoute path="/kontakt" exact>
                <Contact/>
            </ClientRoute>
            <PrivateRoute path="/zadost-o-uver">
                {activeLoan !== null
                    ? <Redirect to={"/aktivni-uver"}/>
                    : <NewLoan/>
                }
            </PrivateRoute>
            <PrivateRoute path="/aktivni-uver">
                <ActiveLoan/>
            </PrivateRoute>
            <PrivateRoute path="/dokumenty/smlouvy">
                <Contracts/>
            </PrivateRoute>
            <PrivateRoute path="/dokumenty/odklady">
                <Postponements/>
            </PrivateRoute>
            <PrivateRoute path="/dokumenty/osobni-dokumenty">
                <PersonalDocuments/>
            </PrivateRoute>
            <PrivateRoute path="/bonusy">
                <Bonuses/>
            </PrivateRoute>
            <PrivateRoute path="/historie-uveru">
                <LoanHistory/>
            </PrivateRoute>
            <PrivateRoute path="/osobni-udaje/doklady">
                <PersonalDataDocuments/>
            </PrivateRoute>
            <PrivateRoute path="/osobni-udaje/cinnost">
                <Activity/>
            </PrivateRoute>
            <PrivateRoute path="/osobni-udaje/sidlo">
                <Residence/>
            </PrivateRoute>
            <PrivateRoute path="/osobni-udaje">
                <PersonalData/>
            </PrivateRoute>
            <ClientRoute path="/odhlaseni">
                {/*<Redirect to="/prihlaseni"/>*/}
                {isAuth
                    ? <Logout />
                    : <Redirect to={"/"}/>}
            </ClientRoute>
            <ClientRoute path="/" exact>
                <Redirect to="/prihlaseni"/>
            </ClientRoute>
            <Route>
                <ErrorPage/>
            </Route>
        </Switch>
    );
};

export default Routes;