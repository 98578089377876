import React, {useEffect, useState} from 'react';
import {useDispatch} from "useDispatch";
import {useTypedSelector} from "helpers/reducers";
import LoanAction from "stores/loan/LoanAction";
import Loader from "components/loader/Loader";
import PageHeading from "components/global/PageHeading";
import {getNumberOfItemsPerPage, MOBILE_BREAKPOINT, sortItemsByDateTime} from "helpers/utility";
import {ContractsType} from "types/enums/ContractsType";
import useWindowDimensions from "helpers/hooks/useWindowDimensions";
import {Document} from "types/entities";
import Pagination from "components/pagination/Pagination";
import MobileTablePostponements from "pages/documents/components/MobileTablePostponements";
import PostponementsTable from "pages/documents/components/PostponementsTable";

const Postponements = () => {
    const dispatch = useDispatch();
    const documents = useTypedSelector(state => state.loan.deferrals);
    const {height, width} = useWindowDimensions();
    const mobile = width < MOBILE_BREAKPOINT;
    const perPage = mobile ? documents.length : getNumberOfItemsPerPage(width);
    const initList = documents.filter((item, index) => index < perPage);
    const [list, setList] = useState<Document[]>(initList);

    useEffect(() => {
        dispatch(LoanAction.fetchContractsListByType(ContractsType.Deferral));
    }, []);

    if (!documents) {
        return <Loader/>
    }

    return (
        <div className="PersonalDocuments prepare-sticky-content">
            <PageHeading title={"Odkaldy"}/>
            {mobile
                ? <MobileTablePostponements list={sortItemsByDateTime(list)}/>
                : (
                    <>
                        <PostponementsTable list={sortItemsByDateTime(documents)}/>
                        {(perPage > 4 && documents.length > 4)
                        && <Pagination
                            setNewState={setList}
                            perPage={7}
                            data={documents}
                            numberOfItems={documents.length}/>

                        }
                    </>
                )}
        </div>
    );
};

export default Postponements;