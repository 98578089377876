import {createAction} from "helpers/createAction";

class LogicAction {
    static readonly RESET_LOGIC_STATE = "LogicAction.ResetLogicState";
    static readonly SET_SIGN_LATER_FLAG = "LogicAction.SetSignLaterFlag";
    static readonly SET_PREPENDING_DEFERRAL_ORDER = "LogicAction.SetPrependingDeferralOrder";
    static readonly SET_USER_PAPER_NOTIFICATION = "LogicAction.SetUserPaperNotification";

    static setSignLaterFlag = (flag: boolean) => {
        return createAction(LogicAction.SET_SIGN_LATER_FLAG, flag);
    }

    static resetLogicState = () => {
        return createAction(LogicAction.RESET_LOGIC_STATE, null);
    }

    static setPrePendingDeferralOrder = (days: number | null) => {
        return createAction(LogicAction.SET_PREPENDING_DEFERRAL_ORDER, days);
    }

    static setUserPaperNotification = (flag: boolean) => {
        return createAction(LogicAction.SET_USER_PAPER_NOTIFICATION, flag);
    }
}

export default LogicAction;