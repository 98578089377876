import React from 'react';
import clsx from "clsx";

interface Props {
    activeTab: string,
    setActiveTab: (tab: string) => void,
    setShowNav: () => void,
    showNav: boolean
}

const LoanDetailNavigation = (props: Props) => {

    const setActiveTabHandler = (activeTab: string) => {
        props.setActiveTab(activeTab);
        props.setShowNav();
    }

    return (
            <div className={clsx("LoanDetailNavigation d-flex justify-content-between p-2 mt-2", props.showNav ? "showDetailNav" : "hideDetailNav")}>
                <div className={clsx("cursor-pointer mr-3", props.activeTab === "info" && "active-tab")} onClick={() => setActiveTabHandler("info")}>Základní informace</div>
                <div className={clsx("cursor-pointer mr-3", props.activeTab === "toPay" && "active-tab")} onClick={() => setActiveTabHandler("toPay")}>Ke splaceni</div>
                <div className={clsx("cursor-pointer mr-3", props.activeTab === "payments" && "active-tab")} onClick={() => setActiveTabHandler("payments")}>Platby k úvěru</div>
                <div className={clsx("cursor-pointer", props.activeTab === "deferred" && "active-tab")} onClick={() => setActiveTabHandler("deferred")}>Platby odkladů</div>
            </div>
    );
};

export default LoanDetailNavigation;