import React, {useEffect} from 'react';
import {Hints, Steps} from "intro.js-react";
import {useHistory} from "react-router-dom";
import {manageTourSteps} from "layouts/components/Preset";

interface Props {
    introJS: any
}

const ReactIntroJS = (props: Props) => {
    const history = useHistory();

    const handleOnChange = (nextStepIndex, nextElement) => {
        manageTourSteps(nextStepIndex, nextElement, history);
    }

    return (
        <React.Fragment>
            <Steps
                enabled={props.introJS.tour.stepsEnabled}
                steps={props.introJS.tour.steps}
                initialStep={props.introJS.tour.initialStep}
                onExit={props.introJS.onExit}
                onChange={handleOnChange}
                options={{
                    hideNext: false,
                    nextLabel: "Další",
                    prevLabel: "Zpět",
                    doneLabel: "Mám to!",
                }}
            />
            {/*<Hints enabled={props.introJS.tour.hintsEnabled} hints={props.introJS.tour.hints} />*/}
        </React.Fragment>
    );
};

export default ReactIntroJS;