import React from 'react';
import Status from "components/status/Status";
import LoanDetailNavBurger from "pages/loanHistory/components/LoanDetailNavBurger";
import LoanDetailNavigation from "pages/loanHistory/components/LoanDetailNavigation";
import {Loan} from "types/entities";
import Loader from "components/loader/Loader";
import useWindowDimensions from "helpers/hooks/useWindowDimensions";
import {MOBILE_BREAKPOINT} from "helpers/utility";

interface Props {
    loan: Loan,
    activeTab: string,
    setShowNav: () => void,
    setActiveTab: (tab: string) => void,
    showNav: boolean,
    isMobile: boolean
}

const LoanDetailHeader = (props: Props) => {


    const getActiveTab = () => {
        if (props.activeTab === "toPay") {
            return "KE SPLACENÍ"
        } else if (props.activeTab === "payments") {
            return "PLATBY"
        } else if (props.activeTab === "deferred"){
            return "PLATBY ODKLADŮ"
        } else {
            return "INFORMACE"
        }
    }

    if (!props.loan) {
        return <Loader/>
    }

    return (
        <div className="d-flex text-left justify-content-between ">
            <div style={{position: 'relative', top: '4px'}}>
                {/*classes: mb-2 d-block*/}
                <span className={"loanNumber"}>{props.loan.contract_number} - </span>
                <span className={"loanType-marketing"}>{props.loan.type_text}</span>
            </div>
            <div className={"d-flex detailNavWrapper navLoanDetail"}>
                {!props.isMobile && (
                    <LoanDetailNavigation
                        activeTab={props.activeTab}
                        setActiveTab={props.setActiveTab}
                        showNav={true}
                        setShowNav={props.setShowNav}/>
                )}
                {props.isMobile && (
                    <>
                        <LoanDetailNavBurger onClick={props.setShowNav} openNav={props.showNav}/>
                        <div>
                            <span className={"tabName"}>Menu - {getActiveTab()}</span>
                            <LoanDetailNavigation
                                activeTab={props.activeTab}
                                setActiveTab={props.setActiveTab}
                                showNav={props.showNav}
                                setShowNav={props.setShowNav}/>
                        </div>
                    </>
                )}
            </div>
            <div>
                <Status status={props.loan.status} statusText={props.loan.status_text}/>
            </div>
        </div>
    );
};

export default LoanDetailHeader;