import React from 'react';
import {useTypedSelector} from "helpers/reducers";
import Loader from "components/loader/Loader";

const ClientStatus = (props: any) => {
    const user = useTypedSelector(state => state.session.user);
    const loan = useTypedSelector(state => state.loan);

    if (!loan.bonuses || !user) {
        return <Loader/>
    }

    const bonuses = loan.bonuses;
    const bonusesArray = bonuses.map(bonus => bonus.amount);
    const bonusCount = bonusesArray.reduce((a, b) => a + b, 0);


    const getClientStatus = () => {
        const categories = [{category_id: 0, name: "Vista Start"}, {category_id: 1, name: "Vista Plus"}, {category_id: 2, name: "Vista Business"}]

        return categories.find(category => category.category_id === user.category)?.name;
    }


    //@todo do not forget made bonus program change based on points

    return (
        <div className="ClientStatus">
            <p className="fz-small m-0">Účet: <b>{user.first_name + " " + user.last_name}</b></p>
            <p className="blue fz-small m-0"><b className="purple">{getClientStatus()} - </b> <u>{bonusCount} bodů</u></p>
        </div>
    );
};

export default ClientStatus;