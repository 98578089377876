import LoanAction from "stores/loan/LoanAction";
import update from "immutability-helper";
import {GetActiveLoanResponse} from "types/Responses/getActiveLoanResponse";
import {Bonus, Contract, Document, Loan} from "types/entities";
import {LoanSelection} from "types/entities/LoanSelection";
import {PriceListItem} from "types/entities/PriceListItem";
import {PaymentToPay} from "types/Responses/PaymentsToPayResponse";
import {DeferralOrder} from "types/entities/DeferralOrder";
import {ContractSignObject} from "components/contract/SignContract";
import {sortContractsToSign} from "helpers/utility";

interface LoanReducerResetLoanState {
    payload: null,
    type: typeof LoanAction.RESET_LOAN_STATE
}

interface LoanReducerSetPriceList {
    payload: {type: string, priceList: PriceListItem[] },
    type: typeof LoanAction.SET_LOAN_PRICE_LIST
}

interface LoanReducerSetUserBonusesAction {
    payload: Bonus[],
    type: typeof LoanAction.SET_USER_BONUSES,
}

interface LoanReducerSetUserContractsAction {
    payload: Contract[],
    type: typeof LoanAction.SET_USER_CONTRACTS,
}

interface LoanReducerSetUserLoansAction {
    payload: Loan,
    type: typeof LoanAction.SET_USER_LOANS,
}

interface LoanReducerSetUserLoanAction {
    payload: Loan,
    type: typeof LoanAction.SET_USER_LOAN,
}

interface LoanReducerSetSystemDocumentsListAction {
    payload: Document[],
    type: typeof LoanAction.SET_SYSTEM_DOCUMENTS,
}

interface LoanReducerSetUserActiveLoanAction {
    payload: GetActiveLoanResponse | null,
    type: typeof LoanAction.SET_USER_ACTIVE_LOAN,
}

interface LoanReducerSetLoanSelectionAction {
    payload: LoanSelection,
    type: typeof LoanAction.SET_LOAN_SELECTION
}

// @todo add types
interface LoanReducerSetUserDeferralsAction {
    payload: any,
    type: typeof LoanAction.SET_USER_DEFERRALS,
}

// @todo add types
interface LoanReducerSetUserLoanPaymentsAction {
    payload: any,
    type: typeof LoanAction.SET_USER_LOAN_PAYMENTS,
}

// @todo add types
interface LoanReducerSetLoanPaymentsToPayAction {
    payload: PaymentToPay[],
    type: typeof LoanAction.SET_LOAN_PAYMENTS_TO_PAY,
}

// @todo add types
interface LoanReducerSetDeferredOrdersListAction {
    payload: any,
    type: typeof LoanAction.SET_DEFERRED_ORDERS_LIST,
}

interface LoanReducerSetPendingDeferredOrder {
    payload: DeferralOrder | null,
    type: typeof LoanAction.SET_PENDING_DEFERRED_ORDER,
}

interface LoanReducerSetSignContractFlag {
    payload: ContractSignObject,
    type: typeof LoanAction.SET_SIGN_CONTRACT_FLAG,
}

// @todo add types
interface LoanReducerSetContractsToSignOrder {
    payload: ContractSignObject[] | null,
    type: typeof LoanAction.SET_CONTRACTS_TO_SIGN,
}

type LoanReducerAction =
    | LoanReducerSetUserBonusesAction
    | LoanReducerSetUserContractsAction
    | LoanReducerSetUserLoansAction
    | LoanReducerSetUserLoanAction
    | LoanReducerSetSystemDocumentsListAction
    | LoanReducerSetUserActiveLoanAction
    | LoanReducerSetUserDeferralsAction
    | LoanReducerSetUserLoanPaymentsAction
    | LoanReducerSetPriceList
    | LoanReducerSetLoanSelectionAction
    | LoanReducerSetLoanPaymentsToPayAction
    | LoanReducerSetDeferredOrdersListAction
    | LoanReducerSetPendingDeferredOrder
    | LoanReducerResetLoanState
    | LoanReducerSetContractsToSignOrder
    | LoanReducerSetSignContractFlag;

export interface LoanState {
    priceList: {
        fix: PriceListItem[],
        rent: PriceListItem[]
    },
    bonuses: Bonus[],
    contracts: Contract[],
    contractsToSign: ContractSignObject[],
    deferredOrderList: any
    deferrals: any,
    loans: Loan[],
    loanDetail: Loan,
    loan: Loan,
    documents: Document[],
    activeLoan: GetActiveLoanResponse | null,
    pendingDeferredOrder: DeferralOrder | null,
}

const defaultState = {
    priceList: {
        fix: [],
        rent: []
    },
    bonuses: [],
    contracts: [],
    contractsToSign: [],
    deferredOrderList: [],
    deferrals: null,
    loan: {},
    loans: [],
    loanDetail: null,
    documents: [],
    activeLoan: null,
    pendingDeferredOrder: null
}

class LoanReducer {
    initialState: LoanState = defaultState;

    reducer = (state: LoanState = this.initialState, action: LoanReducerAction): LoanState => {
        switch (action.type) {
            case LoanAction.RESET_LOAN_STATE:
                return {
                    ...defaultState
                }
            case LoanAction.SET_LOAN_PRICE_LIST:
                console.log('reducer set priceList : ', action.payload)
                return update(state, {
                    priceList: {
                        [action.payload.type]: {
                            $set: action.payload.priceList
                        }
                    }
                })
            case LoanAction.SET_USER_BONUSES:
                return update(state, {
                    bonuses: {
                        $set: action.payload
                    }
                })
            case LoanAction.SET_USER_CONTRACTS:
                return update(state, {
                    contracts: {
                        $set: action.payload
                    }
                });
            case LoanAction.SET_USER_DEFERRALS:
                return update(state, {
                    deferrals: {
                        $set: action.payload
                    }
                })
            case LoanAction.SET_USER_LOANS:
                return update(state, {
                    loans: {
                        $set: action.payload
                    }
                });
            case LoanAction.SET_USER_LOAN:
                return update(state, {
                    loanDetail: {
                        $set: action.payload
                    }
                });
            case LoanAction.SET_USER_LOAN_PAYMENTS:
                return update(state, {
                    loanDetail: {
                        payments: {
                            $set: action.payload
                        }
                    }
                });
            case LoanAction.SET_LOAN_PAYMENTS_TO_PAY:
                return update(state, {
                    loanDetail: {
                        to_pay: {
                            $set: action.payload
                        }
                    }
                });
            case LoanAction.SET_SYSTEM_DOCUMENTS:
                return {
                    ...state,
                    documents: action.payload
                };
            case LoanAction.SET_USER_ACTIVE_LOAN:
                return {
                    ...state,
                    activeLoan: action.payload
                };
            case LoanAction.SET_LOAN_SELECTION:
                return update(state, {
                    loan: {
                        $set: action.payload
                    }
                })
            case LoanAction.SET_DEFERRED_ORDERS_LIST:
                return update(state, {
                    deferredOrderList: {
                        $set: action.payload
                    }
                })
            case LoanAction.SET_PENDING_DEFERRED_ORDER:
                return update(state, {
                    pendingDeferredOrder: {
                        $set: action.payload
                    }
                })
            case LoanAction.SET_CONTRACTS_TO_SIGN:
                console.log(sortContractsToSign(action.payload))

                const contracts = action.payload && action.payload?.length > 0
                    ? sortContractsToSign(action.payload)
                    : []

                return update(state, {
                    contractsToSign: {
                        $set: contracts
                    }
                })
            case LoanAction.SET_SIGN_CONTRACT_FLAG:
                const contractRowIndex = state.contractsToSign.findIndex(contract => contract.id === action.payload.id);

                if (contractRowIndex === -1) {
                    return state;
                }

                if (!action.payload) {
                    return update(state, {
                        contractsToSign: {
                            $set: []
                        }
                    })
                }


                return update(state, {
                    contractsToSign: {
                        [contractRowIndex]: {
                            signed: {
                                $set: true
                            }
                        }
                    }
                })

            default: return state;
        }
    }
}

export default LoanReducer;