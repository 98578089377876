import React from 'react';
import {Col} from "react-bootstrap";
import {Link} from "react-router-dom";

interface Props {
    route: string,
    image: string,
    tabTitle: string,
    col: number
}

const MobileNavItem = (props: Props) => {
    return (
        <Col xs={props.col} className={"d-flex flex-column justify-content-start align-items-center"}>
            <Link to={props.route}  className={"d-flex flex-column justify-content-center align-items-center"}>
                <img src={props.image} className={'tabIcon'} alt="tab-icon"/>
                <p className={"tabTitle"}>{props.tabTitle}</p>
            </Link>
        </Col>
    );
};

export default MobileNavItem;