import React, {useEffect, useState} from 'react';
import SideNavItem from "components/navbar/sidebarNav/components/SideNavItem";
import {Link, useLocation} from 'react-router-dom';
import SideNavItemContainer from "components/navbar/sidebarNav/components/SideNavItemContainer";
import {useTypedSelector} from "helpers/reducers";
import {RouteComponentProps, withRouter} from "react-router";
import clsx from "clsx";
import ContactServis from "components/ContactServis";

interface Params {}

interface SideNavProps extends RouteComponentProps<Params> {
    setOpenNav?: any;
    joyRideStart?: (e: React.MouseEvent<HTMLElement>) => void,
    className?: string
}

const SideNav = (props: SideNavProps) => {
    const location = useLocation();
    const [open, setOpen] = useState({
        action: false,
        id: ""
    });
    const loan = useTypedSelector(state => state.loan);

    useEffect(() => {
        if (location.pathname.includes("/dokumenty")) {
            setOpen({
                action: true,
                id: "documents"
            })
        } else if (location.pathname.includes("/osobni-udaje")) {
            setOpen({
                action: true,
                id: "personal-data"
            });
        }

        props.setOpenNav(false);
    }, [location.pathname])

    const activeLoan = loan.activeLoan;
    const contracts = loan.contracts.filter(contract => contract.signed !== null);
    const documentsCount = loan.documents ? loan.documents.length : 0;
    const deferralsCount = loan.deferrals ? loan.deferrals.length : 0;
    const contractsCount = contracts ? contracts.length : 0;
    const loans = loan.loans;
    const bonuses = loan.bonuses;

    // if (!bonuses || !contracts || !documents) {
    //     return <Loader/>
    // }

    const bonusesArray = bonuses.map(bonus => bonus.amount);
    const bonusCount = bonusesArray.reduce((a, b) => a + b, 0);


    const logOut = () => {
        props.setOpenNav(false);
        props.history.push('/odhlaseni');
    }


    return (
        <div className={clsx("SideNavigation-root", props.className)}>
            <nav className="SideNavigation">
                <ul className="SideNavigation__list">
                    <SideNavItemContainer open={open}>
                        <SideNavItem
                            className={"active-loan-tour"}
                            path={"/aktivni-uver"}
                            redirect
                            setOpenNav={props.setOpenNav}
                            setOpen={setOpen}
                            open={open}
                            loanType={activeLoan?.loan_type}
                            status={activeLoan !== null}>Aktivní úvěr</SideNavItem>
                    </SideNavItemContainer>
                   <SideNavItemContainer open={open} id={"documents"} className={"documents-tour"}>
                        <SideNavItem
                            className={clsx("SideNavigation__top-item", location.pathname.includes("/dokumenty") && "blue")}
                            img
                            id={"documents"}
                            setOpenNav={props.setOpenNav}
                            setOpen={setOpen}
                            open={open}>Dokumenty <span>({documentsCount})</span></SideNavItem>
                        <ul className="SideNavigation__sub-menu">
                            <li className={clsx(location.pathname === "/dokumenty/smlouvy" && "blue")}><Link to={"/dokumenty/smlouvy"}>Smlouvy <span>({contractsCount})</span></Link></li>
                            <li className={clsx(location.pathname === "/dokumenty/odklady" && "blue")}><Link to={"/dokumenty/odklady"}>Odklady <span>({deferralsCount})</span></Link></li>
                            <li className={clsx(location.pathname === "/dokumenty/osobni-dokumenty" && "blue")}><Link to={"/dokumenty/osobni-dokumenty"}>Osobní dokumenty <span>({documentsCount})</span></Link></li>
                        </ul>
                   </SideNavItemContainer>
                    <SideNavItemContainer open={open} className={"bonuses-tour"}>
                        <SideNavItem
                            path={"/bonusy"}
                            redirect
                            setOpenNav={props.setOpenNav}
                            setOpen={setOpen}
                            open={open}
                            className="SideNavigation__top-item ">Bonusy <span>({bonusCount} bodů)</span></SideNavItem>
                    </SideNavItemContainer>
                    <SideNavItemContainer open={open}>
                        <SideNavItem
                            path={"/historie-uveru"}
                            redirect
                            setOpen={setOpen}
                            open={open}
                            setOpenNav={props.setOpenNav}
                            className="SideNavigation__top-item">Historie úvěrů <span>({loans.filter(loanItem => {
                                if (loan.activeLoan) {
                                    return loanItem.id !== loan.activeLoan.id
                                }
                                return loanItem;
                            }).length
                            })</span></SideNavItem>
                    </SideNavItemContainer>
                    <SideNavItemContainer open={open} id={"personal-data"}>
                        <SideNavItem
                            className={clsx("SideNavigation__top-item", location.pathname.includes("/osobni-udaje") && "blue")}
                            open={open}
                            id={"personal-data"}
                            setOpen={setOpen}
                            setOpenNav={props.setOpenNav}
                            img>Osobní údaje</SideNavItem>
                        <ul className="SideNavigation__sub-menu">
                            <li className={clsx(location.pathname === "/osobni-udaje/doklady" && "blue")}><Link to={"/osobni-udaje/doklady"}>Doklady</Link></li>
                            <li className={clsx(location.pathname === "/osobni-udaje" && "blue")}><Link to={"/osobni-udaje"}>Osobní údaje</Link></li>
                            <li className={clsx(location.pathname === "/osobni-udaje/cinnost" && "blue")}><Link to={"/osobni-udaje/cinnost"}>Činnost</Link></li>
                            <li className={clsx(location.pathname === "/osobni-udaje/sidlo" && "blue")}><Link to={"/osobni-udaje/sidlo"}>Sídlo</Link></li>
                        </ul>
                    </SideNavItemContainer>
                    <li
                        className="SideNavigation__list-item grayBackground" onClick={props.joyRideStart}>
                        <div className="d-flex justify-content-between">
                            <div className="hoverItem">Spustit nápovědu</div>
                            <img src="" alt=""/>
                        </div>
                    </li>
                    <li
                        className="SideNavigation__list-item grayBackground"
                        onClick={logOut}>
                        <div className="d-flex justify-content-between">
                            <div className="hoverItem">Odhlásit se</div>
                            <img src={""} alt=""/>
                        </div>
                    </li>
                </ul>
            </nav>
            <ContactServis/>
        </div>
    );
};

export default withRouter(SideNav);