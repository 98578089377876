import React, {useEffect, useState} from 'react';
import LoanHistoryTable from "pages/loanHistory/components/LoanHistoryTable";
import {useDispatch} from "useDispatch";
import LoanAction from "stores/loan/LoanAction";
import PageHeading from "components/global/PageHeading";
import {useTypedSelector} from "helpers/reducers";
import {Loan} from "types/entities";
import Loader from "components/loader/Loader";
import Pagination from "components/pagination/Pagination";
import useWindowDimensions from "helpers/hooks/useWindowDimensions";
import {getNumberOfItemsPerPage, MOBILE_BREAKPOINT} from "helpers/utility";
import LoanHistoryTableMobile from "pages/loanHistory/components/LoanHistoryTableMobile";


const LoanHistory = (props: any) => {
    const dispatch = useDispatch();
    const activeLoan = useTypedSelector(state => state.loan.activeLoan);
    const loans = useTypedSelector(state => state.loan.loans);
    const filteredLoans = loans.filter(loan => loan.id !== activeLoan?.id);
    const {height, width} = useWindowDimensions();
    const mobile = width < MOBILE_BREAKPOINT;
    const perPage = mobile ? loans.length : getNumberOfItemsPerPage(width);
    const initLoanList = filteredLoans.filter((loan, index) => index < perPage);
    const [loanList, setLoanList] = useState<Loan[]>(initLoanList);


    useEffect(() => {
        dispatch(LoanAction.fetchLoanList());
    },  []);

    if (!loans) {
        return <Loader/>
    }

    return (
        <div className="LoanHistory prepare-sticky-content">
            <PageHeading title={"Historie úvěru"}/>
            {mobile
                ? <LoanHistoryTableMobile loans={loanList}/>
                : (
                    <>
                        <LoanHistoryTable loans={loanList}/>
                        {perPage > 4
                            && <Pagination
                                setNewState={setLoanList}
                                perPage={perPage}
                                data={loans}
                                numberOfItems={loans.length}/>
                        }
                    </>
                )}
        </div>
    );
};

export default LoanHistory;