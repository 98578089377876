import React, {useEffect} from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import 'App.scss';
import Routes from "layouts/Routes";
import ClientLayout from "layouts/ClientLayout";
import AOS from "aos";
import {useTypedSelector} from "helpers/reducers";
import {RouteComponentProps, withRouter} from "react-router";
import {useLogoutOnInactivity} from "helpers/hooks/useLogoutOnInactivity";
import {useFetchGeneralDataInterval} from "helpers/hooks/useFetchGeneralDataInterval";
import {useLocation} from "react-router-dom";

interface Params {}

interface Props extends RouteComponentProps<Params> {}

const App = (props: Props) => {
    const token = useTypedSelector(state => state.session.token);
    const location = useLocation();

    useLogoutOnInactivity(['/prihlaseni'], 30 * 60 * 1000);
    useFetchGeneralDataInterval(token, 60 * 1000);

    useEffect(() => {
        AOS.init({
            duration: 400
        });
        AOS.refresh();
    });

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location.pathname]);


    return (
        <div className="App">
            <ClientLayout>
                <Routes/>
            </ClientLayout>
        </div>
    );
}

export default withRouter(App);