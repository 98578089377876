import React from 'react';
import clsx from "clsx";

interface Props {
    title: string,
    register: any,
    type?: string,
    name: string,
    disabled?: boolean,
    className?: string,
    error?: any,
    onChange?: (e) => void
}

const FormInput = (props: Props) => {
    return (
        <div className="input-wrap">
            <span className="labelText">{props.title}</span>
            <input type={props.type || "text"}
                   name={props.name}
                   ref={props.register}
                   disabled={props.disabled}
                   placeholder={props.error?.message}
                   onChange={props.onChange}
                   className={clsx("inputElement", props.disabled && "input-disabled", props.className, props.error && props.error && 'error-input error-text')}/>
        </div>
    );
};

export default FormInput;