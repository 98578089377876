import React from 'react';

interface TableItemProps {
    children: React.ReactNode | React.ReactNode[],
    className?: string
}

const TableItem = (props: TableItemProps) => {
    return (
        <td className={props.className}>{props.children}</td>
    );
};

export default TableItem;