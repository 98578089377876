import React from 'react';
import {GetActiveLoanResponse} from "types/Responses/getActiveLoanResponse";
import {formatCurrency, getDate, getDateTime} from "helpers/utility";
import {useDispatch} from "useDispatch";
import {closeModal, openModal} from "stores/action";
import LoanDetail from "pages/loanHistory/components/LoanDetail";
import {useTypedSelector} from "helpers/reducers";
import LoanAction from "stores/loan/LoanAction";
import {toastInfo} from "helpers/toasts";

interface Props {
    loan: GetActiveLoanResponse
}

const LoanCardBody = (props: Props) => {
    const dispatch = useDispatch();
    const className = "ModalLoanDetail"
    const openLoanDetail = () => {
        dispatch(LoanAction.fetchLoanDetail(props.loan.id)).then(r => {
            if (!r) {
                dispatch(closeModal());
                toastInfo("Je nám líto, ale momentálně nelze načíst detail úvěru. Skuste to prosím později.");
                return;
            }

            dispatch(openModal(<LoanDetail/>, {className}));
        });
    }

    return (
        <div className="card__body">
            <table className="table">
                <tbody>
                    <tr>
                        <td className={"pl-0"}>
                            <span className="card__label">Jistina</span>
                            <span className="card__numbers">{formatCurrency(props.loan.amount)} Kč</span>
                        </td>
                        <td> <span className="card__label">Poplatek</span>
                            <span className="card__numbers">{formatCurrency(props.loan.fee)} Kč</span></td>
                        <td></td>
                        <td className={"text-right pr-0"}>
                            <span className="card__label">Ke splacení</span>
                            <span className="card__numbers text-underline blue-text cursor-pointer text-underline" onClick={openLoanDetail}><b>{formatCurrency(props.loan.sum)} Kč</b></span>
                        </td>
                    </tr>
                    <tr>
                        <td className={"pl-0"}>
                            <span className="card__label">Datum žádosti</span>
                            <span className="card__numbers">{getDateTime(props.loan.created)}</span>
                        </td>
                        <td>
                            <span className="card__label">Doba poskytnutí</span>
                            <span className="card__numbers">{props.loan.period} dnů</span>
                        </td>
                        <td>
                            <span className="card__label">Po splatnosti</span>
                            <span className="card__numbers card__paymentDate-status">{props.loan.overdue} dnů</span>
                        </td>
                        <td className={"text-right pr-0"}>
                            <span className="card__label">Splatnost úvěru</span>
                            <span className="card__numbers"><b>{getDate(props.loan.due)}</b></span>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
};

export default LoanCardBody;