import React from 'react';

interface TableHeadProps {
    children: React.ReactNode | React.ReactNode[]
}

const TableHead = (props: TableHeadProps) => {
    return (
        <thead>
            {props.children}
        </thead>
    );
};

export default TableHead;