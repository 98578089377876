import React from 'react';
import TableHead from "components/Table/components/TableHead";
import TableRow from "components/Table/components/TableRow";
import TableHeadItem from "components/Table/components/TableHeadItem";
import TableBody from "components/Table/components/TableBody";
import TableItem from "components/Table/components/TableItem";
import {dateParse} from "helpers/utility";
import Table from "components/Table/Table";
import Loader from "components/loader/Loader";
import {Bonus} from "types/entities";

interface Props {
    list: Bonus[]
}

const BonusesTable = (props: Props) => {
    if (!props.list) {
        return <Loader/>
    }

    return (
        <Table>
            <TableHead>
                <TableRow>
                    <TableHeadItem>SMLOUVA</TableHeadItem>
                    <TableHeadItem>DATUM AKCE</TableHeadItem>
                    <TableHeadItem>ZÍSKANÉ BODY</TableHeadItem>
                    <TableHeadItem>ÚKON</TableHeadItem>
                </TableRow>
            </TableHead>
            <TableBody>
                {props.list.map((result, index) => (
                    <TableRow key={index}>
                        <TableItem>{result.order}</TableItem>
                        <TableItem>{dateParse(result.created)}</TableItem>
                        <TableItem>{result.amount} bodů</TableItem>
                        <TableItem>{result.manner_text}</TableItem>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    );
};

export default BonusesTable;