import React from 'react';
import TableHead from "components/Table/components/TableHead";
import TableRow from "components/Table/components/TableRow";
import TableHeadItem from "components/Table/components/TableHeadItem";
import TableBody from "components/Table/components/TableBody";
import TableItem from "components/Table/components/TableItem";
import {dateParse} from "helpers/utility";
import Download from "assets/icons/download.svg";
import Table from "components/Table/Table";
import {Contract, Document} from "types/entities";

interface Props {
    documents: Contract[]
}

const DocumentsTable = (props: Props) => {
    return (
        <Table>
            <TableHead>
                <TableRow>
                    {/*<TableHeadItem>ID</TableHeadItem>*/}
                    <TableHeadItem>NÁZEV</TableHeadItem>
                    <TableHeadItem>DATUM AKCE</TableHeadItem>
                    <TableHeadItem>PDF</TableHeadItem>
                </TableRow>
            </TableHead>
            <TableBody>
                {props.documents.map(result => (
                    <TableRow key={result.id}>
                        {/*<TableItem>{result.id}</TableItem>*/}
                        <TableItem>{result.type_text}</TableItem>
                        <TableItem>{dateParse(result.created)}</TableItem>
                        <TableItem>
                            <div className="d-flex cursor-pointer">
                                <a rel={"noreferrer"} href={result.url} target={"_blank"} download>
                                    <img src={Download} alt="download icon"/>
                                    <span className={"url"}>PDF</span>
                                </a>
                            </div>
                        </TableItem>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    );
};

export default DocumentsTable;