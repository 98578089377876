import React from 'react';

interface Props {
    id: number
}

const LoanCardLoanId = (props: Props) => {
    return (
        <div>
            <span className="card__ID">ID: {props.id}</span>
        </div>
    );
};

export default LoanCardLoanId;