export enum LoanStatus {
    New = 0,
    PreApproved = 1,
    Approved = 2,
    Denied = 3,
    Processed = 4,
    SentToBankAccount = 5,
    Overdue = 7,
    WriteOff = 8,
    InkasoEOS = 9, // nejde sa prihlasit na ucet pri tomto stave
    Counsel = 10,
    SaleOfReceivable = 11, // nejde sa prihlasit na ucet pri tomto stave
    Police = 12, // nejde sa prihlasit na ucet pri tomto stave
    Storno = 13,
    PaidBack = 14,
    RejectedCounterproposal = 15,
    BeforeMaturity = 16,// nejde sa prihlasit na ucet pri tomto stave
    Insolvency = 17,// nejde sa prihlasit na ucet pri tomto stave
    BeforeComplaint = 18,
    DeniedByRobot = 19,
    Trial = 20,
    Arbitration = 21,
    Execution = 22,
    BeforeCollection = 23,
    BeforeInsolvency = 24,// nejde sa prihlasit na ucet pri tomto stave
    FinishedInsolvency = 125,
    InkasoMaxifine = 26 // nejde sa prihlasit na ucet pri tomto stave
}


