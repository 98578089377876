import React from 'react';
import Direction from "assets/icons/direction.svg";
import { Link } from 'react-router-dom';
import {useDispatch} from "useDispatch";
import {openModal} from "stores/action";
import VistaRent from "pages/activeLoan/components/VistaRent";

const LoanHintBox = (props: any) => {
    const dispatch = useDispatch();
    const className = "ModalFull";

    const openVistaRentModal = () => dispatch(openModal(<VistaRent/>, {className}));

    return (
        <div className="HintBox-root">
            <h2 className="HintBox__title">
                Víte, co znamená <br/> <span className="purple-text">Vista FIX</span> nebo <span className="purple-text">RENT</span>?
            </h2>
            <p className="HintBox__paragraph">
                Zorientujte se v našich skupinách, sbírejte body a získejte služby navíc.
            </p>
            <div className="HintBox__direction">
                <p
                    className="HintBox__link cursor-pointer"
                    onClick={openVistaRentModal}
                >Více informací</p>
                <img src={Direction} alt=""/>
            </div>
        </div>
    );
};

export default LoanHintBox;