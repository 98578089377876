import {toast} from "react-toastify";
import ThumbNotification from "../assets/icons/toasts/notification.svg";
import ThumbUp from "../assets/icons/toasts/thumbUp.svg";
import ThumbDown from "../assets/icons/toasts/thumDown.svg";

export const toastSuccess = (message) => {
    return toast.success(message, {
        icon: ({theme, type}) => <img src={ThumbUp}/>,
        autoClose: message.length > 18 ? 6 * 1000 : 3 * 1000
    });
}
export const toastError = (message) => {
    return toast.error(message, {
        icon: ({theme, type}) => <img src={ThumbDown}/>,
        autoClose: message.length > 18 ? 6 * 1000 : 3 * 1000
    });
}
export const toastInfo = (message) => {
    return toast.info(message, {
        icon: ({theme, type}) => <img src={ThumbNotification}/>,
        autoClose: message.length > 18 ? 6 * 1000 : 3 * 1000
    });
}