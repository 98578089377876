import React from 'react';

interface button {
    children: React.ReactNode | React.ReactNode[];
    disabled?: boolean;
    className: string;
    action?: any;
    type: any;
    style?: any
}

const Button = (props: button) => {
    return (
        <button type={props.type} className={props.className} disabled={props.disabled} onClick={props.action} style={props.style}>
            {props.children}
        </button>
    );
};

export default Button;