import React from 'react';
import LoanHistoryTableItem from "pages/loanHistory/components/LoanHistoryTableItem";
import Table from "components/Table/Table";
import TableHead from "components/Table/components/TableHead";
import TableHeadItem from "components/Table/components/TableHeadItem";
import TableRow from "components/Table/components/TableRow";
import TableBody from "components/Table/components/TableBody";
import {Loan} from "types/entities";
import {closeModal, openModal} from "stores/action";
import LoanDetail from "pages/loanHistory/components/LoanDetail";
import {useDispatch} from "useDispatch";
import LoanAction from "stores/loan/LoanAction";
import {toastInfo} from "helpers/toasts";

interface Props {
    loans: Loan[]
}

const LoanHistoryTable = (props: Props) => {
    const dispatch = useDispatch();

    const openDetailHandler = (detail: Loan) => {
        const className = "ModalLoanDetail ModalFull";
        dispatch(LoanAction.fetchLoanDetail(detail.id)).then(r => {
            if (!r) {
                dispatch(closeModal());
                toastInfo("Je nám líto, ale momentálně nelze načíst detail úvěru. Skuste to prosím později.");
                return;
            }

            dispatch(openModal(<LoanDetail loan={detail} />, {className}))
        });
    }

    return (
        <div className="LoanHistoryTable">
            <Table>
                <TableHead>
                    <TableRow>
                        <TableHeadItem>ID ÚVĚRU</TableHeadItem>
                        <TableHeadItem>TYP</TableHeadItem>
                        <TableHeadItem>DATUM ŽÁDOSTI</TableHeadItem>
                        <TableHeadItem>JISTINA</TableHeadItem>
                        <TableHeadItem>SPLATNOST ÚVĚRU</TableHeadItem>
                        <TableHeadItem>PLATBY</TableHeadItem>
                        <TableHeadItem className={"text-right"}>STAV ÚVĚRU</TableHeadItem>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {props.loans?.map(loan => (
                        <TableRow key={loan.id}>
                            <LoanHistoryTableItem loan={loan} onClick={openDetailHandler}/>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>

        </div>
    );
};

export default LoanHistoryTable;