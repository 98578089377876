import React from 'react';

interface Props {
    children: React.ReactNode | React.ReactNode[]
}

const ScanItems = (props: Props) => {
    return (
        <div className="id-list-wrap">
            <div className="id-list-header">
                <p>Nahrané soubory:</p>
            </div>
            <div className="id-list-body">
                <ul className="id-list">
                    {props.children}
                </ul>
            </div>
        </div>
    );
};

export default ScanItems;